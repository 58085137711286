import React from 'react';
import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import court from '../image_ug.png';
import logo2 from '../ulrc_logo.png';

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className='flex gap-7 justify-center items-center mt-10'>
                <Link to='/'><img src={logo2} alt="logo" className='w-40' /></Link>
                <img src={court} alt="logo" className='w-16' />
            </div>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="default" onClick={() => navigate('/')}>Back Home</Button>}
            />
        </div>
    )
}

export default NotFound;