import axios from "axios"
import { API_URL } from "../../envs"

export const accessType = [
    { label: 'Free', value: 'Free' },
    { label: 'For Subscription', value: 'For Subscription' },
]

export const accessType2 = [
    { label: 'Free', value: 'Free' },
    { label: 'For Subscription', value: 'For Susbscription' },
]

export const constitutionType = [
    { label: 'Translated', value: 'Translated' },
    { label: 'English', value: 'English' },
]

export const lawTypes = [
    { label: 'Principal Laws', value: 'Principal' },
    { label: 'Subsidiary Laws', value: 'Subsidiary' },
    { label: 'Consolidated Laws', value: 'Consolidated' },
]

export const otherPubTypes = [
    { label: 'Index of the laws of Uganda', value: 'Index of laws' },
    { label: 'Uganda Living Law Journal', value: 'Uganda Living Journal' },
    { label: 'Cumulative Suppliment', value: 'Cumulative Suppliment' },
    { label: 'Translated Laws', value: 'Translated Laws' }
]

export const reportsType = [
    { label: 'Annual Reports', value: 'Annual' },
    { label: 'Study Reports', value: 'Study' },
]

export const vols = async () => {
    const res = await axios.get(`${API_URL}/volumes`)
    const volumes = res?.data?.data
    return volumes?.map(vol => ({
        label: vol?.attributes?.volTitle,
        value: vol?.id
    }))
}

export const getPublicationTypes = async () => {

    const volumes = await vols()

    return {
        volumes: {
            name: "Volumes",
            coverName: 'thumbNailURL',
            fileName: "volURL",
            endpoint: 'volumes',
            fields: [
                { required: true, label: 'Volume Title', name: 'volTitle', placeholder: 'Enter Volume Title', inputType: 'input' },
                { required: false, label: 'Entry Date', name: 'entryDate', placeholder: 'Enter Entry Date', inputType: 'date' },
                { required: true, label: 'Volume Description', name: 'volDescription', placeholder: 'Enter Volume Description', inputType: 'textarea' },
                { required: true, label: 'Access Type', name: 'accessType', placeholder: 'Select Access Type', inputType: 'select', options: accessType2 },
                { required: true, label: 'Upload File', name: 'volURL', placeholder: 'Enter Volume URL', inputType: 'file', fileType: "doc" },
                { required: true, label: 'Upload Cover Image', name: 'thumbNailURL', placeholder: 'Enter Thumbnail URL', inputType: 'file', fileType: "cover" },
            ]
        },
        lawsOfUganda: {
            name: "Laws Of Uganda",
            fileName: 'actURL',
            endpoint: 'laws-of-ugandas',
            fields: [
                { required: true, label: 'Law Type', name: 'lawType', placeholder: 'Select law type', inputType: 'select', options: lawTypes },
                { required: true, label: 'Access Type', name: 'accessType', placeholder: 'Select access type', inputType: 'select', options: accessType },
                { required: true, label: 'Act Description', name: 'actDescription', placeholder: 'Enter act description', inputType: 'textarea' },
                { required: true, label: 'Act Name', name: 'actName', placeholder: 'Enter act name', inputType: 'input' },
                { required: true, label: 'Act Title', name: 'actTitle', placeholder: 'Enter act title', inputType: 'input' },
                { required: true, label: 'Act Title Number', name: 'actTitleNumber', placeholder: 'Enter act title number', inputType: 'input' },
                { required: true, label: 'Upload File', name: 'actURL', placeholder: 'Enter act URL', inputType: 'file', fileType: "doc" },
                { required: false, label: 'Cap', name: 'cap', placeholder: 'Enter cap', inputType: 'input' },
                { required: false, label: 'Commencement', name: 'Commencement', placeholder: 'Enter commencement date', inputType: 'date' },
                { required: true, label: 'Law Volume', name: 'lawVolume', placeholder: 'Enter law volume', inputType: 'select', options: volumes },
            ]
        },
        constitutions: {
            name: "Constitutions",
            coverName: 'constThumnailURL',
            fileName: 'constFileURL',
            endpoint: 'constitutions',
            fields: [
                { required: true, label: 'Constitution Type', name: 'constitutionType', placeholder: 'Enter constitution type', inputType: 'select', options: constitutionType },
                { required: true, label: 'Constitution Name', name: 'constName', placeholder: 'Enter constitution name', inputType: 'input' },
                { required: true, label: 'Access Type', name: 'accessType', placeholder: 'Select access type', inputType: 'select', options: accessType },
                { required: false, label: 'Commencement', name: 'commencement', placeholder: 'Enter commencement date', inputType: 'date' },
                { required: true, label: 'Constitution Description', name: 'constDescription', placeholder: 'Enter constitution description', inputType: 'textarea' },
                { required: true, label: 'Upload File', name: 'constFileURL', placeholder: 'Enter constitution file URL', inputType: 'file', fileType: "doc" },
                { required: true, label: 'Upload Cover Image', name: 'constThumnailURL', placeholder: 'Enter constitution thumbnail URL', inputType: 'file', fileType: "cover" },
            ]
        },
        simplifiedLaws: {
            name: "Simplified Laws",
            coverName: 'simpleLawThumbURL',
            fileName: 'simpleLawURL',
            endpoint: 'simplified-laws',
            fields: [
                { required: true, label: 'Simplified Law Name', name: 'simpleName', placeholder: 'Enter simplified law name', inputType: 'input' },
                { required: true, label: 'Simplified Description', name: 'simpleDescription', placeholder: 'Enter simplified description', inputType: 'textarea' },
                { required: true, label: 'Access Type', name: 'accessType', placeholder: 'Select access type', inputType: 'select', options: accessType },
                { required: false, label: 'Commencement Date', name: 'CommencementDate', placeholder: 'Enter commencement date', inputType: 'date' },
                { required: true, label: 'Upload File', name: 'simpleLawURL', placeholder: 'Enter simplified law URL', inputType: 'file', fileType: "doc" },
                { required: true, label: 'Upload Cover Image', name: 'simpleLawThumbURL', placeholder: 'Enter simplified law thumbnail URL', inputType: 'file', fileType: "cover" },
            ]
        },
        reports: {
            name: "Reports",
            coverName: 'reportThumnailURL',
            fileName: 'reportFileURL',
            endpoint: 'reports',
            fields: [
                { required: true, label: 'Report Type', name: 'reportType', placeholder: 'Select report type', inputType: 'select', options: reportsType },
                { required: true, label: 'Report Name', name: 'reportName', placeholder: 'Enter report name', inputType: 'input' },
                { required: true, label: 'Access Type', name: 'accessType', placeholder: 'Select access type', inputType: 'select', options: accessType },
                { required: false, label: 'Report Date', name: 'reportDate', placeholder: 'Enter report date', inputType: 'date' },
                { required: true, label: 'Report Description', name: 'reportDescription', placeholder: 'Enter report description', inputType: 'textarea' },
                { required: true, label: 'Upload File', name: 'reportFileURL', placeholder: 'Enter report file URL', inputType: 'file', fileType: "doc" },
                { required: true, label: 'Upload Cover Image', name: 'reportThumnailURL', placeholder: 'Enter report thumbnail URL', inputType: 'file', fileType: "cover" },
            ]
        },
        otherPublications: {
            name: "Other Publications",
            coverName: 'otherPubThumbnailURL',
            fileName: 'otherPubFileURL',
            endpoint: 'other-publications',
            fields: [
                { required: true, label: 'Other Publication Type', name: 'otherPublicationType', placeholder: 'Select other publication type', inputType: "select", options: otherPubTypes },
                { required: true, label: 'Other Publication Name', name: 'otherPubName', placeholder: 'Enter other publication name', inputType: "input" },
                { required: true, label: 'Access Type', name: 'accessType', placeholder: 'Select access type', inputType: "select", options: accessType },
                { required: false, label: 'Other Publication Date', name: 'otherPubDate', placeholder: 'Enter other publication date', inputType: "date" },
                { required: true, label: 'Description', name: 'otherPubDescription', placeholder: 'Enter publication Description', inputType: "textarea" },
                { required: true, label: 'Upload File', name: 'otherPubFileURL', placeholder: 'Enter other publication file URL', inputType: "file", fileType: "doc" },
                { required: true, label: 'Upload Cover Image', name: 'otherPubThumbnailURL', placeholder: 'Enter other publication thumbnail URL', inputType: "file", fileType: "cover" },
            ]
        }
    }
};