import React, { useState } from 'react'
import { BiCart } from "react-icons/bi";
import { AiOutlineHeart, AiOutlineWallet } from "react-icons/ai"
// import { PublicationsNav } from '.'
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import Footer from '../Footer';
import Pricing from '../Pricing';
import { PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import { _index, _volumes } from '../searchClient';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PdfPreview from '../Pdf';
import axios from 'axios';
import { ACCESS_TYPES, API_URL, imagePlaceholder } from '../../envs';
import { Image, Spin } from 'antd';
import { IoArrowBack } from 'react-icons/io5';
import { useAuth } from '../../Auth';

const PreviewVolume = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryText = queryParams.get('type');
    const titleText = queryParams.get('title');

    const [pricingModal, setPricingModal] = useState(false)
    const [publication, setPublication] = useState(null)
    const [publication1, setPublication1] = useState(null)
    const [loading, setLoading] = useState(false)
    const [suggestions, setSuggestions] = React.useState([])
    const [preview, setPreview] = React.useState(false);
    const { userData, subscriptionData } = useAuth()

    const navigate = useNavigate();
    const { id } = useParams();

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                let response
                if (queryText === 'Consolidated') {
                    response = await axios.get(`${API_URL}/publications/${id}?populate=*`);
                    const data = response.data.data;
                    setPublication({
                        ...data.id,
                        ...data.attributes,
                        volTitle: data.attributes.publicationName,
                        volURL: data.attributes.publicationFileURL,
                        thumbNailURL: data.attributes.pubThumbnailURL,
                        accessType: data.attributes.publlicationAccess,

                    });

                } else {
                    response = await axios.get(`${API_URL}/volumes?filters[volTitle][$eq]=${titleText}&populate=*`);
                    const data = response?.data?.data[0];
                    const arr = data?.attributes?.thumbNailURL?.split('/upload/') || []
                    const newUrl = arr.length > 1 ? arr[0] + "/upload/q_auto,f_auto/" + arr[1] : null
                    setPublication({ ...data.id, ...data.attributes, thumbNailURL: newUrl });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        })()
    }, [id, queryText])

    React.useEffect(() => {

        _volumes
            .search('', {
                limit: 6,
            })
            .then(({ hits }) => {
                const newHits = hits.filter((hit) => (hit.volTitle !== publication?.volTitle));
                setSuggestions(newHits);
            })
            .catch((err) => {
                console.error(err)
            })
        // }
    }, [publication])

    const continueReading = () => {
        if (publication?.accessType === ACCESS_TYPES.FREE || (subscriptionData && userData)) {
            setPreview(true)
        } else {
            setPricingModal(true)
            localStorage.setItem("publicationLink", `/laws-of-uganda/${id}`)
        }
    }

    const openPlansModal = () => {
        setPricingModal(true)
    }

    return (
        <div className='bg-bgGrey relative'>
            <div className='pt-7 pb-20 container mx-auto'>
                <UnAuthHeader3 openPlansModal={openPlansModal} />
                <Spin spinning={loading}>
                    <div className='flex mt-9 space-x-10 justify-center'>
                        <div>
                            <img src={publication?.thumbNailURL || imagePlaceholder} className='w-56' alt={'Publication'} />
                            {/* <Image
                            src={publication?.thumbNailURL || imagePlaceholder}  alt={'Publication'}
                            width={224}                            
                        /> */}

                        </div>
                        <div className='w-3/4'>
                            <div className='w-3/4'>
                                <div className='mb-2 flex justify-between items-center'>
                                    {/* <div className='text-4xl'>{publication?.volTitle}</div> */}
                                    <div className='text-4xl flex'>
                                        <IoArrowBack
                                            className='inline-block mr-2 cursor-pointer text-2xl mt-2'
                                            onClick={() => navigate('/laws-of-uganda')}
                                        />
                                        <div className='w-[99%]'>{publication?.volTitle}</div>
                                    </div>
                                </div>
                                <div className="items-stretch border border-[color:var(--lighter-grey-strokes,#ECEDED)] flex flex-col rounded-xl border-solid mt-5">
                                    <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                        <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                            <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                                Access Type
                                            </div>
                                            <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                                {publication?.accessType || 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="justify-center items-stretch bg-slate-100 flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                            Commencement
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.entryDate || 'N/A'}
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="justify-center items-stretch bg-slate-100 flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                        <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                            <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                                Publication Type
                                            </div>
                                            <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                                {publication?.publicationType || 'Laws of Uganda'}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='mt-4 mb-8'>
                                    <ul className='list-disc list-inside'>
                                        {
                                            publication?.laws_of_ugandas?.data?.map(({ attributes: { actName, cap }, id: _id }, index) => (
                                                <li key={index} className='text-sm'>
                                                    <Link to={`/laws-of-uganda/${id}/acts/${_id}`} className='text-blue-600 hover:text-blue-800'>
                                                        {/* {index + 1}.  */}
                                                        {`${actName}`}
                                                        {cap.length > 0 && <b>{` - (CAP. ${cap})`}</b>}
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                {publication?.volURL && <div
                                    className="text-white bg-bgBlue text-center text-base leading-6 whitespace-nowrap justify-center items-center border border-[color:var(--Grey-Mid,#999)] w-[707px] max-w-full mr-3 mt-8 px-5 py-4 rounded-2xl border-solid self-end max-md:mr-2.5 cursor-pointer"
                                    onClick={continueReading}
                                >
                                    Read Volume
                                </div>}
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
            <div className="bg-white self-stretch flex w-full flex-col items-center mt-14 px-5 py-12 max-md:max-w-full max-md:mt-10">
                <div className="flex w-full max-w-[1128px] flex-col items-center mt-16 mb-8 max-md:max-w-full max-md:mt-10">
                    <div className="overflow-hidden text-neutral-800 text-ellipsis text-5xl leading-[54px] w-[508px] max-w-full ml-12 max-md:text-4xl max-md:leading-[50px]">
                        Similar Publications
                    </div>
                    <div className="self-stretch flex items-stretch justify-between gap-5 mt-14 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mt-10">
                        {
                            suggestions.map((suggestion) => (
                                <a href={`/laws-of-uganda/${suggestion?.id}?title=${suggestion?.volTitle}`} className="items-center flex grow basis-[0%] flex-col">
                                    <img
                                        loading="lazy"
                                        src={suggestion?.thumbNailURL}
                                        className="object-contain overflow-hidden"
                                    />
                                    <div className="text-neutral-800 text-ellipsis text-center text-base mt-1 w-44">
                                        {suggestion?.volTitle}
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {pricingModal && <Pricing closeModal={() => setPricingModal(false)} />}
            {publication?.volURL && <PdfPreview closePreview={() => setPreview(false)} preview={preview} docUrl={publication?.volURL} access={publication?.accessType} filename={publication?.volTitle} />}
        </div>
    )
}

export default PreviewVolume