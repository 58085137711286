import { toast } from "react-toastify";
import { PRN_CODE } from "../containers/CartPayment";
import { BASE_URL } from "../envs";
import axios from "axios";

const MAX_ATTEMPTS = 50;
const POLLING_INTERVAL = 2000;
const PROCESSING_TIME = 1000;
const BUFFER_TIME = 30000;
const TIMEOUT_DURATION = (MAX_ATTEMPTS * (POLLING_INTERVAL + PROCESSING_TIME)) + BUFFER_TIME;

export const checkingPRN = async (prn) => {
    return new Promise(async (resolve, reject) => {
        let timeout = setTimeout(() => {
            reject(new Error('Request timed out'));
        }, TIMEOUT_DURATION);

        for (let i = 0; i < MAX_ATTEMPTS; i++) {
            try {
                const response = await axios.post(`${BASE_URL}/prn_status?time=${Date.now()}`, {
                    prn: `${prn}`
                });
                const statusCode = response.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult?.StatusCode
                if (statusCode === 'T') {
                    clearTimeout(timeout);
                    resolve(response.data);
                    toast.success("Your PRN was received and credited")
                    return;
                } else if (statusCode === 'X' || statusCode === 'C') {
                    clearTimeout(timeout);
                    reject(new Error(PRN_CODE[statusCode].desc));
                    toast.error(PRN_CODE[statusCode].desc)
                    return;
                }
            } catch (error) {
                console.error(error);
            }
            await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
        }

        clearTimeout(timeout);
        reject(new Error('PRN status not updated to "credited"'));
    });
};

export function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}