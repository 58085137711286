import React from 'react';
import { FaSearch, FaUserAlt, FaGlobeAfrica, FaFileArchive, FaFileAlt } from 'react-icons/fa'
import { BiBell, BiCart } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import logo2 from '../../ulrc_logo.png';
import { renderPublications } from '../Home';
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import Footer from '../Footer';
import { _index, _simplifiedLaws } from '../searchClient';
import { MdBalance, MdGTranslate } from 'react-icons/md';
import { FiChevronDown, FiChevronUp, FiFileText } from 'react-icons/fi';
import { GrLanguage } from "react-icons/gr";
import { IoArrowBack } from 'react-icons/io5';
import Pricing from '../Pricing';
import { API_URL } from '../../envs';
import axios from 'axios';

const pubicationOptions = [
    'Copyright infringement',
    'Contract Law',
    'Revenue Law'
]

const SimplifiedLawsUganda = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryText = queryParams.get('query');
    const navigate = useNavigate()

    const [search, setSearch] = React.useState(queryText || '')
    const [results, setResults] = React.useState([])
    const [list, setList] = React.useState([])
    const [openPlans, setOpenPlans] = React.useState(false)
    const [fullList, setFullList] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {

        setLoading(true)
        _simplifiedLaws
            .search(search, {
                limit: 50,
                // filter: searchQuery
            })
            .then(({ hits }) => {
                console.log("HITS ---- :", hits)
                // Reorder list in Ascending order by id
                setResults(hits.sort((a, b) => a.id - b.id))

            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [
        // search, searchQuery
    ])

    React.useEffect(() => {
        (async () => {
            const resp = await axios.get(`${API_URL}/simplified-laws?populate=*`)
            const data = resp.data?.data || []
            if (data.length > 0) {
                const items = []
                data.forEach(item => {
                    const itemlist = item.attributes?.publications?.data || []
                    if (itemlist.length > 0) return;
                    items.push(item)  
                })
                setList(items)
                setFullList(items)
            }
        })()
    }, [])

    React.useEffect(() => {
        if (search.length === 0) {
            setList(fullList)
        } else {
            const _list = fullList.filter(item => {
                return item.attributes.simpleName.toLowerCase().includes(search.toLowerCase())
            })
            setList(_list)
        }
    }, [search])

    const openPlansModal = () => {
        setOpenPlans(true)
    }

    console.log("List: ", list)

    return (
        <div className='bg-bgGrey min-h-full flex flex-col justify-between'>
            <div className='pt-7 pb-20 container mx-auto'>
                {/* <PublicationsNav search={search} setSearch={setSearch} /> */}
                <UnAuthHeader3 openPlansModal={openPlansModal} />

                <div className='flex items-start'>
                    <div className='pt-8 pr-2 w-[245px]'>
                        <div className='font-bold mb-3 text-lg'>Filter</div>
                        {/* <SideMenu /> */}
                    </div>
                    <div className='border-l-4 pt-8 pl-8 w-full'>
                        <div className='flex justify-between items-center mb-6 w-full'>
                            <div className='font-bold text-lg flex items-center'>
                                <IoArrowBack
                                    onClick={() => navigate('/')}
                                    className='inline-block mr-2 text-xl cursor-pointer' />
                                <span>Simplified Laws of Uganda</span>
                            </div>
                            <div className='flex gap-2'>
                                <input
                                    className='h-9 rounded-lg border outline-1 border-gray-200 px-2 text-sm'
                                    placeholder='Search by Act or Cap...'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className='bg-bgBlue text-white h-9 px-4 rounded-lg'>Search</button>
                            </div>
                        </div>
                        <div className='grid grid-flow-row grid-cols-5 gap-4'>
                            {
                                list.map((law) => {
                                    const { id, attributes: {simpleLawThumbURL, simpleName} } = law
                                    const imgs = [Publish1, Publish2]
                                    let randomNumber = Math.random() < 0.5 ? 0 : 1;
                                    return (
                                        <Link to={{ pathname: `/simplified-laws-of-uganda/${id}` }}>
                                            <div className='w-40'>
                                                <img src={simpleLawThumbURL || imgs[randomNumber]} alt={"Publication"} className={'w-40 h-60'} />
                                                <div className='flex justify-between items-center mt-2 pl-1'>
                                                    <span className='text-sm w-36'>{simpleName}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        {
                            !loading && results.length === 0 &&
                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                No Simplified Laws of Uganda Found
                            </div>
                        }
                        {
                            loading &&
                            <div className="text-neutral-800 text-xl font-light mt-2.5 max-md:max-w-full text-center">
                                Loading Simplified Laws of Uganda...
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {openPlans && <Pricing closeModal={() => setOpenPlans(false)} />}
        </div>
    )
}

export default SimplifiedLawsUganda
