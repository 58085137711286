import React, { useEffect, useState } from 'react'
import { useAuth } from '../Auth'
import { API_URL } from '../envs'
import axios from 'axios'
import { Spin, Table } from 'antd'

const columns = [
    {
        title: 'ID',
        dataIndex: 'id'
    },
    {
        title: 'Name',
        dataIndex: 'username'
    },
    {
        title: 'Email',
        dataIndex: 'email'
    },
    {
        title: 'Role',
        dataIndex: 'serviceRole'
    },
    {
        title: 'Subscribed To',
        dataIndex: 'subscribedTo'
    },
    {
        title: 'Confirmed',
        dataIndex: 'confirmed',        
    },
]

function PeopleManagement() {
    const [loading, setLoading] = useState([]);
    const [users, setUsers] = useState([]);
    const { userData } = useAuth();

    useEffect(() => {
        fetchUsers()        
    }, [])

    const fetchUsers = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${API_URL}/users`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });

            const formattedUsers = response?.data?.map(user => {
                return {
                    ...user,
                    confirmed: `${user.confirmed}`,
                    serviceRole: user.serviceRole || "user"
                }
            }).filter(user => user.serviceRole !== "superadmin")
            setUsers(formattedUsers)
        } catch (error) {
            console.log(Error)
        } finally {
            setLoading(false)
        }
    }

  return (
    <div className="mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <div className="flex justify-between mb-3">
                <h1 className="text-neutral-800 text-lg font-semibold -mr-5 max-md:max-w-full">
                    User Management
                </h1>
                {/* <button
                    disabled={loading}
                    className="py-1 px-2 rounded flex items-center gap-2 border border-slate-300"
                    onClick={() => fetchPrns()}
                >
                    <FiRefreshCcw />
                    <span className='text-base'>Refresh</span>
                </button> */}
            </div>

            <Spin spinning={loading} tip="Loading Users...">
                <Table
                    columns={columns}
                    dataSource={users}
                />
            </Spin>
        </div>
  )
}

export default PeopleManagement