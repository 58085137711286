import React, { useCallback, useEffect, useState } from 'react'
import { getPublicationTypes } from '../../constants/forms';
import { Select } from 'antd';

function AddPubForm({ onSubmit, loader, handleFileUpload, handleCoverUpload, publicationType, setPublicationType, handleFormChange, handleSelectChange, formData }) {
    const [publicationTypes, setPublicationTypes] = useState([]);    
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const obj = await getPublicationTypes()
                setPublicationTypes(obj)
            } catch(err) {

            } finally {
                setLoading(false)
            }
        })()
    }, [])


    const handlePublicationTypeChange = (value) => {
        setPublicationType(value);
    };

    const renderInputFields = (field) => {
        switch (field.inputType) {
            case 'textarea': {
                return (
                    <textarea
                        name={field.name}
                        required={field?.required || true}
                        className='border-slate-300 border w-full h-24 pl-2 rounded'
                        placeholder={field.placeholder}
                        onChange={handleFormChange}
                        defaultValue={''}
                        value={formData[field.name]}
                    />
                )
            }
            case 'file': {
                return (
                    <div className='border-dotted border-2 border-slate-400 p-2 rounded-md'>
                        <input
                            type='file'
                            accept={field.fileType === "doc" ? ".pdf" : "image/*"}
                            required={field?.required || true}                            
                            onChange={field.fileType === "doc" ? handleFileUpload : handleCoverUpload}
                        />
                    </div>
                )
            }
            case 'date': {
                return (
                    <input
                        className='border-slate-300 border w-full h-8 pl-2 rounded'
                        placeholder={field.placeholder}
                        required={field?.required || true}
                        type='date'
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleFormChange}
                    />
                )
            }
            case 'select': {
                return (
                    <Select
                        options={field.options}
                        required={field?.required || true}
                        className='w-full h-8 rounded'
                        onChange={(e) => handleSelectChange(field.name, e)}
                        placeholder={field.placeholder}
                        value={formData[field.name]}
                    />
                )
            }
            default: {
                return (
                    <input
                        className='border-slate-300 border w-full h-8 pl-2 rounded'
                        placeholder={field.placeholder}
                        required={field?.required || true}
                        name={field.name}
                        onChange={handleFormChange}
                        defaultValue={''}
                        value={formData[field.name]}
                    />
                )
            }
        }
    }


    const renderFormFields = () => {
        if (!publicationType) return null;

        const fields = publicationTypes[publicationType]?.fields;
        return fields.map((field, index) => (
            <div key={index} className='mb-3'>
                <div>{field.label}</div>
                {renderInputFields(field)}
            </div>
        ));
    }

    const options = Object.keys(publicationTypes).map((type) => ({
        value: type,
        label: publicationTypes[type].name
    }));

    const placeholder = {
        value: "publicationType",
        label: "Publication Type",
    }
    

    return (
        <div>
            <div className='text-sm font-bold text-slate-500'>Select Publication Type</div>
            <Select
                placeholder="Publication Type"
                onChange={handlePublicationTypeChange}
                options={[
                    {label: "Publication Type", value: null},
                    ...options
                ]}
                loading={loading}
                value={publicationType}
                className='mb-3 '
            />
            <form className='text-sm' onSubmit={onSubmit}>
                {renderFormFields()}
                {!!publicationType && <div className='mt-5 flex justify-end gap-2'>                    
                    <button disabled={loader} type='submit' className=' bg-brightRed text-white px-4 py-[6px] rounded-[6px] text-md'>{loader ? "Wait..." : "Submit"}</button>
                </div>}
            </form>
        </div>
    );
}

export default AddPubForm