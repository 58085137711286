import { ToastContainer } from 'react-toastify';
import Router from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './Auth';
import { BrowserRouter } from 'react-router-dom';
import DocumentViewerComponent from './containers/DocumentViewerComponent';
import { useEffect } from 'react';
import "./print.css";

const Notice = () => {
  return (
    <div className="bg-yellow-200 p-1 text-center fixed top-0 left-0 w-full z-10">
      <p className="text-yellow-800">This website will be launched on 27th June 2024.</p>
    </div>
  );
}

function App() {

  // useEffect(() => {
  //   document.oncontextmenu = (event) => {
  //     event.preventDefault();
  //   };
  // }, []);
  

  return (
    <AuthProvider>
      <BrowserRouter>
        {/* <Notice /> */}
        <Router />
        {/* <DocumentViewerComponent /> */}
        <ToastContainer />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
