import React from 'react';
import { FaApple } from "react-icons/fa";
import { BsAndroid2 } from "react-icons/bs";
// import { useHistory } from 'react-router-dom';
// import 'tailwindcss/tailwind.css';

const DownloadApp = ({ os={os} }) => {
    //   const history = useHistory();

    const handleDownloadAndroid = () => {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.ulrc.ulrcmobile';
    };

    const handleDownloadIos = () => {
        window.location.href = 'https://apps.apple.com/ug/app/laws-of-uganda/id6474242361'; // Replace with your iOS app link
    };

    const handleContinue = () => {
        // history.push('/');
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md">
                <h1 className="text-xl font-bold mb-4">Download the mobile app</h1>
                <p className="mb-6">
                    To continue using our service, please download The <b>Laws of Uganda</b> mobile from the Google Play Store.
                </p>
                {(os === 'Android' || os !== 'iOS') && <button
                    className=" px-4 py-2 rounded-md mr-2 border border-gray-300 mt-2 flex align-middle items-center"
                    onClick={handleDownloadAndroid}
                >
                    <span>
                        Download for Android
                    </span>
                    <BsAndroid2 color="#444" className="inline-block w-6 h-6 ml-2" />
                </button>}
                {(os === 'iOS' || os !== 'Android') && <button
                    className=" px-4 py-2 rounded-md mr-2 border border-gray-300 mt-2 flex items-center"
                    onClick={handleDownloadIos}
                >
                    <span>
                        Download for iOS
                    </span>
                    <FaApple color="#444" className="inline-block w-6 h-6 ml-2" />
                </button>}
            </div>
        </div>
    );
};

export default DownloadApp;