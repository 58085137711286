import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useAuth } from '../Auth';
import { API_URL } from '../envs';
import logo2 from '../ulrc_logo.png';
import { AuthHeader2 } from './UnAuthHeader';
import Footer from './Footer';
import { encodeQueryData } from '../utils/methods';

const initialUserData = {
    email: "",
    password: ""
}

function Signin() {

    const [user, setUser] = useState(initialUserData)
    const [loading, setLoading] = useState(false)
    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const navigate = useNavigate()
    const { state } = useLocation();
    const { to } = state || { to: { pathname: "/" } }
    const { userData, saveUser } = useAuth()

    useEffect(() => {
        if (userData) navigate("/")
    }, [userData])

    const validation = () => {
        for (const property in user) {
            if (user[property].length === 0) {
                toast.error(`${property} field cannot be empty`, {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                return false;
            }
        }

        return true
    }

    const saveSession = async (email) => {
        const sessionID = Date.now()

        try {
            localStorage.setItem('sessionId', sessionID)
            
            const filters = {
                'filters[email][$eq]': email
            }
            const query = encodeQueryData(filters)
            const sessionUrl = `${API_URL}/sessions?${query}`;
            
            const response = await axios.get(sessionUrl)
            if (response.data.data.length > 0) {
                const session = response.data.data[0]                
                if (session) {
                    const resp = await axios.put(`${API_URL}/sessions/${session.id}`, { data: { sessionID } })
                    const collId = resp?.data?.data?.id
                    localStorage.setItem('sessionCollId', collId)                    
                }
            } else {
                const resp = await axios.post(`${API_URL}/sessions`, { data: { sessionID, email } })
                const collId = resp?.data?.data?.id
                localStorage.setItem('sessionCollId', collId)
            }        
        } catch (error) {
            console.log(error)
        }
    }

    const login = async () => {
        const url = `${API_URL}/auth/local`
        const loginData = { identifier: user.email, password: user.password }

        try {
            setLoading(true)
            const response = await axios.post(url, loginData)
            saveSession(user.email)
            saveUser(response.data)
            navigate(to, { replace: true })
        } catch (error) {
            const errorMsg = error?.response?.data?.error?.message || error.message
            toast.error(errorMsg, {
                position: toast.POSITION.BOTTOM_CENTER
            })
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validation()) return;
        await login()
    }

    return (
        <div className='h-screen bg-bgGrey flex flex-col justify-between pt-2'>
            <div className='container mx-auto'>
                <AuthHeader2 />
            </div>
            <div className='flex justify-center items-center flex-col mt-20 mb-36'>
                <div className='text-5xl font-serif'>Welcome Back</div>
                <form className='mt-10' onSubmit={handleSubmit}>
                    <div className='mb-4'>
                        <label className='block ml-1 mb-1'>Email</label>
                        <input
                            value={user.email}
                            type='email'
                            onChange={e => setUser({ ...user, email: e.target.value })}
                            className='h-10 pl-2 w-96 rounded-lg outline-none' placeholder='example@mail.com' />
                    </div>
                    <div className='mb-8'>
                        <label className='block ml-1 mb-1'>Password</label>
                        <div className='w-96 flex items-center'>
                            <input
                                value={user.password}
                                type={isPasswordVisible ? 'text' : 'password'}
                                onChange={e => setUser({ ...user, password: e.target.value })}
                                className='h-10 pl-2 w-80 rounded-l-lg outline-none' placeholder='xxxxxxxx' />
                            <div
                                className='flex items-center justify-center w-16 h-10 rounded-r-lg cursor-pointer bg-white'
                                onClick={() => setPasswordVisible(!isPasswordVisible)}
                            >
                                {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                            </div>
                        </div>
                    </div>
                    <button
                        type='submit'
                        className='h-10 w-96 rounded-lg bg-brightRed text-white block'
                        disabled={loading}
                    >{loading ? "Loading" : "Sign in"}</button>
                    {/* <div className='text-center my-2'>or</div> */}
                    {/* <div className="relative my-4">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-[80%] border-t border-gray-300 ml-[10%]" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-gray-100 text-gray-500">Or continue with</span>
                        </div>
                    </div>
                    <button
                        className="h-10 w-96 rounded-lg bg-white flex items-center justify-center gap-2 border border-blue-600"
                        onClick={(e) => {
                            e.preventDefault()
                            window.location.href = `${API_URL}/connect/google`
                        }}
                    >
                        <FcGoogle size={20} />
                        <span className="text-blue-800">Signin with Google</span>
                    </button> */}
                    <div className='text-center mt-4'>
                        <a href='/forgot-password' className='text-brightRed cursor-pointer'>Forgot Password? </a>
                    </div>
                    <div className='text-center mt-4'>
                        Don't have an account? <Link to='/signup' className='text-brightRed cursor-pointer'>Sign up</Link>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default Signin
