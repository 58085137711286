import { useEffect, useRef, useState } from "react";
import WebViewer, { } from '@pdftron/pdfjs-express-viewer';
import DocumentPreview from "./DocumentViewer3";
import { s3Config } from './constants/configs';
import { useAuth } from "../Auth";

const PdfPreview = ({ closePreview, preview, docUrl, access, filename }) => {
    const viewerRef = useRef(null);
    const [signedUrl, setSignedUrl] = useState(null)
    const { userData, subscriptionData } = useAuth()

    useEffect(() => {
        // const button = document.querySelector('[data-element="downloadButton"]');

        // if (button) {
        //   button.parentNode.removeChild(button);
        // }

        const menu = window.document.querySelector('[data-element="menuOverlay"]');
        // color menu border to red
        if (menu) {
            menu.style.border = '1px solid #fff';
        }
    }, []);

    useEffect(() => {
        if (!access.toLowerCase().includes('free')) {
            if (!userData || !subscriptionData) {
                return;
            }
        }

        if (!!docUrl) {
            const urlObj = new URL(docUrl);
            const path = urlObj.pathname.slice(1);
            const key = decodeURIComponent(path).replace(/\+/g, ' ')

            s3Config.getSignedUrl('getObject', {
                Bucket: 'ulrc-publications',
                Key: key
            }, (err, url) => {
                if (err) {
                    console.log("Error:", err);
                } else {
                    setSignedUrl(url);
                }
            });
        }
    }, [docUrl])

    useEffect(() => {
        if (viewerRef.current) {
            WebViewer(
                {
                    path: '/',
                    initialDoc: docUrl,
                    licenseKey: 'X0poG6hwQ3hy6Wvb2ImB',
                    disableDownload: true,
                },
                viewerRef.current,
            )
        }
    }, [viewerRef]);

    return (
        <div
            className="fixed z-10 inset-x-0 top-0 overflow-y-auto flex items-center justify-center user-select-none"
            style={{
                background: 'rgba(0,0,0,0.7)',
                visibility: preview ? 'visible' : 'hidden'
            }}
        >
            <div
                className="absolute top-0 right-0 m-5 cursor-pointer"
                onClick={closePreview}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </div>
            <div
                className="relative bg-white w-[80vw] min-h-[95vh] max-w-[1191px] border border-solid border-white overflow-auto"
                style={{
                    // background: '#272969',
                }}
            >
                {signedUrl && <DocumentPreview file={signedUrl} access={access} filename={filename} />}
            </div>

        </div>
    );
};

export default PdfPreview;
