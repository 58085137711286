import React, { useEffect, useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';

const FileInput = ({ initialValue, accept, onChange }) => {
    const [fileList, setFileList] = useState([]);

    useEffect(() => {        
        if (initialValue) {
            setFileList([{ url: initialValue }])
        }
    }, [initialValue])

    const handleUpload = (file) => {
        setFileList([file]);
        onChange(file);
    };

    const handleRemove = () => {
        setFileList([]);
        onChange(null);
    };

    return (
        <>
            {fileList.length === 0 &&
                <Upload
                    //   fileList={fileList}
                    accept={accept}
                    beforeUpload={() => false}
                    onChange={({ file }) => handleUpload(file)}
                >
                    <Button icon={<UploadOutlined />}>Upload File</Button>
                </Upload>
            }
            {fileList.length > 0 && (
                <Button icon={<DeleteOutlined />} onClick={handleRemove}>
                    Remove File
                </Button>
            )}
        </>
    );
};

export default FileInput;
