import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";
import qs from 'qs';
import { API_URL, BASE_URL } from "./envs";
import { subscriptions } from "./containers/Pricing";
import { encodeQueryData } from "./utils/methods";
import { getSubscription } from "./containers/Signup";
import { toast } from "react-toastify";
const AuthContext = createContext();

function findSubscription(subscriptions, subscriptionData) {
  return subscriptions.flat().find(sub => {
    return (
      sub.package === subscriptionData.package &&
      sub.duration === subscriptionData.duration &&
      sub.noOfUsers === subscriptionData.noOfUsers
    );
  });
}

const subscriptionEnd = (startDate, duration) => {
  const multiplier = duration === "Annually" ? 365 : duration === "Monthly" ? 30 : 1
  const newDate = new Date(startDate)
  newDate.setDate(newDate.getDate() + multiplier)
  return newDate
}

export const AuthProvider = ({ children }) => {
  const [userData, setUser] = useLocalStorage("user", null);
  const [subscriptionData, setSubscription] = useLocalStorage("subscriptionInfo", null);
  const [subscription, _setSubscription] = useLocalStorage("subscription", null);
  const [publicationLink, setPublicationLink] = useLocalStorage("publicationLink", null);
  const [paymentsuccess, setPaymentsuccess] = useLocalStorage("paymentsuccess", null);

  const handleUserInvited = async () => {
    const filters = {
      'filters[InviteeEmail][$eq]': userData?.user?.email
    }

    const query = encodeQueryData(filters)
    const invitationsUrl = `${API_URL}/invitations?${query}`;

    try {
      const invitationResp = await axios.get(invitationsUrl);
      const invitation = invitationResp?.data?.data[0]?.attributes;
      if (invitation) {
        const { InviterID } = invitation;
        const inviter = await axios.get(`${API_URL}/users/${InviterID}`);
        if (inviter?.data) {
          const res2 = await axios.put(`${API_URL}/users/${userData?.user?.id}`, { subscribedTo: `${InviterID}` });
          const subData2 = await getSubscription(inviter?.data, userData?.user?.email);
          if (subData2) {
            setSubscription(subData2)
            return;
          } else {
            setSubscription(null)
          }
        }
      }

    } catch (error) {
      console.log("ERROR:", error)
    }

    try {
      const subscriberId = userData?.user?.subscribedTo

      if (!subscriberId) {
        setSubscription(null)
        return;
      }

      const response = await axios.get(`${API_URL}/users/${subscriberId}`);
      const subscriber = response?.data;
      const subData = await getSubscription(subscriber, userData?.user?.email)
      if (subData) {
        setSubscription(subData)
        return;
      } else {
        setSubscription(null)
      }

    } catch (error) {
      console.log("ERROR:", error)
    }
  }

  const checkPrnAndSubscribe = async () => {
    const userEmail = userData?.user?.email;
    const filters = {
      sort: "createdAt:desc",
      'filters[emailID][$eq]': userEmail
    };
    const query = encodeQueryData(filters);

    try {
      const userPrnsResponse = await axios.get(`${API_URL}/prns?${query}`);
      const userPrns = userPrnsResponse?.data?.data || [];

      if (userPrns.length === 0) {
        await handleUserInvited()
        return;
      }

      // Check for existing subscriptions
      const subscriptionChecks = userPrns.map(prnData =>
        axios.get(`${API_URL}/subscriptions?filters[PRN][$eq]=${prnData.attributes?.prn}`)
      );

      // Wait for all subscription checks to complete
      const subscriptionResults = await Promise.all(subscriptionChecks);
      const prnsWithoutSubscriptions = userPrns.filter((_, index) => {
        const subscriptions = subscriptionResults[index]?.data?.data || [];
        return subscriptions.length === 0;
      });

      if (prnsWithoutSubscriptions.length === 0) {
        await handleUserInvited()
        return;
      }

      // Check PRN status for PRNs without subscriptions
      const statusChecks = prnsWithoutSubscriptions.map(prnData =>
        axios.post(`${BASE_URL}/prn_status`, { prn: prnData.attributes?.prn })
      );

      // Wait for all PRN status checks to complete
      const statusResults = await Promise.all(statusChecks);
      const validPrns = prnsWithoutSubscriptions.filter((_, index) => {
        const prnStatus = statusResults[index].data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult;
        return prnStatus?.StatusCode === "T";
      });

      if (validPrns.length > 0) {
        const prnData = validPrns[0]; // Take the first valid PRN
        console.log(`Processing PRN: ${JSON.stringify(prnData)}`);

        const prnSub = {
          package: prnData?.attributes?.package,
          duration: prnData?.attributes?.packageDuration,
          noOfUsers: prnData?.attributes?.numberOfUsers
        }
        const mySubscription = findSubscription(subscriptions, prnSub)

        const subscriptionData = {
          subscriptionName: mySubscription.package,
          subscriptionType: mySubscription.duration,
          amount: `${mySubscription.price}`,
          numberOfUsers: mySubscription.noOfUsers,
          subscriptionStart: mySubscription.startDate,
          subscriptionEnd: subscriptionEnd(mySubscription.startDate, mySubscription.duration),
          paymentRef: '',
          userEmail,
          PRN: prnData.attributes?.prn,
          userPhone: prnData?.attributes?.phoneNumber
        };

        try {
          const subscriptionResponse = await axios.post(`${API_URL}/subscriptions`, { data: subscriptionData });
          console.log('Subscription created successfully', subscriptionResponse.data);

          setSubscription(subscriptionData);

        } catch (subscriptionError) {
          console.error("Failed to create subscription", subscriptionError);
        }

        // Since we only want to create one subscription, we exit the function after processing the first valid PRN
        return;
      } else {
        await handleUserInvited()
      }
    } catch (error) {
      console.error("ERROR:", error);
    }
  };

  useEffect(() => {
    if (!userData?.user?.email) {
      setUser(null);
      setSubscription(null);
      return;
    }
    const fetchData = async () => {
      try {
        const query = qs.stringify({
          filters: {
            userEmail: {
              '$eq': userData?.user?.email
            }
          }
        }, {
          encodeValuesOnly: true,
        });
        const response = await axios.get(`${API_URL}/subscriptions?${query}`);
        const subscrptions = response?.data?.data || [];
        const _mySubscription = subscrptions
          ?.filter(sub => {
            const startDate = new Date(sub.attributes.subscriptionStart);
            const endDate = new Date(sub.attributes.subscriptionEnd);
            const now = new Date();
            return startDate <= now && endDate > now;
          })
        const mySubscription = _mySubscription.length === 0 ? null : _mySubscription.reduce((earliest, current) => {
          const earliestStartDate = new Date(earliest.attributes.subscriptionStart);
          const currentStartDate = new Date(current.attributes.subscriptionStart);
          return earliestStartDate > currentStartDate ? current : earliest;
        });

        if (mySubscription) {
          setSubscription(mySubscription?.attributes);
        } else {
          await checkPrnAndSubscribe()
          // setSubscription(null);
        }

      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [userData])

  useEffect(() => {
    if (!userData?.user?.email) {
      setUser(null);
      setSubscription(null);
      return;
    }

    if (!subscriptionData) {
      (async () => {
        const userEmail = userData?.user?.email;
        const query = qs.stringify({
          // add sorting
          sort: "createdAt:desc",
          filters: {
            emailID: {
              '$eq': userEmail
            }
          }
        }, {
          encodeValuesOnly: true,
        });
        const userPrn = await axios.get(`${API_URL}/prns?${query}`);
        const prnData = userPrn?.data?.data?.[0]?.attributes;
        const prnValue = prnData?.prn

        const prnSub = {
          package: prnData.package,
          duration: prnData.packageDuration,
          noOfUsers: prnData.numberOfUsers
        }

        const mySubscription = findSubscription(subscriptions, prnSub)

        if (prnValue) {
          const statusResp = await axios.post(`${BASE_URL}/prn_status`, {
            prn: prnValue
          })

          const prnStatus = statusResp.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult

          // Check if prn exists in subscriptions
          const query = qs.stringify({
            filters: {
              PRN: {
                '$eq': prnValue
              }
            }
          }, {
            encodeValuesOnly: true,
          });
          const response = await axios.get(`${API_URL}/subscriptions?${query}`);
          const subscriptions = response?.data?.data || [];

          if ((prnStatus?.StatusCode === "T") && (subscriptions.length === 0)) {
            const subscriberData = {
              subscriptionName: mySubscription.package,
              subscriptionType: mySubscription.duration,
              amount: `${mySubscription.price}`,
              numberOfUsers: mySubscription.noOfUsers,
              subscriptionStart: mySubscription.startDate,
              subscriptionEnd: subscriptionEnd(mySubscription.startDate, mySubscription.duration),
              paymentRef: '',
              userEmail,
              PRN: prnValue,
              userPhone: prnData.phoneNumber
            }

            setSubscription(subscriberData)

            await axios.post(`${API_URL}/subscriptions`, {
              data: subscriberData
            })
          }
        }
      })()
    }


  }, [subscriptionData, userData])

  useEffect(() => {
    const email = userData?.user?.email
    if (email) {
      const filters = {
        'filters[email][$eq]': email
      }
      const query = encodeQueryData(filters)
      const sessionUrl = `${API_URL}/sessions?${query}`;

      (async () => {
        const response = await axios.get(sessionUrl)
        if (response.data.data.length > 0) {
          const sessionId = response.data.data[0]?.attributes?.sessionID
          const localSessionId = localStorage.getItem('sessionId')          
          if (localSessionId && (sessionId.toString() !== localSessionId)) {
            toast.error("There is an ongoing session from another client or device, Logging you out...", {
              position: toast.POSITION.BOTTOM_CENTER
            })
            await removeUser()
            return;
          }
        } else {
          await removeUser()
          return;
        }
      })()
    }
  }, [])

  const saveUser = async (data) => {
    setUser(data);
  };

  const removeUser = async () => {
    try {
      // const sessionCollId = localStorage.getItem('sessionCollId')

      // if (sessionCollId) {
      //   await axios.delete(`${API_URL}/sessions/${sessionCollId}`);
      // }

      localStorage.removeItem('sessionCollId')
      localStorage.removeItem('sessionId')

      setUser(null);
      setSubscription(null)
      _setSubscription(null)
      setPublicationLink(null)
      setPaymentsuccess(null)
    } catch (error) {
      console.log("Error", error);
    }
  };

  const value = {
    userData,
    subscriptionData,
    setSubscription,
    _setSubscription,
    saveUser,
    removeUser,
    subscription,
    publicationLink,
    setPublicationLink
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};