import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Document, Page, Outline, pdfjs } from 'react-pdf';
import { IoSearch } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import fileDownload from 'js-file-download';
import axios from 'axios';
import Highlighter from "react-highlight-words";
import Mark from "mark.js";
import _ from "lodash";
import { ACCESS_TYPES } from '../envs';

export const usePdfTextSearch = (file, searchString) => {
  const [pages, setPages] = useState([]);
  const [resultsList, setResultsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    setIsLoading(true);
    pdfjs.getDocument(file).promise.then((docData) => {
      const pageCount = docData._pdfInfo.numPages;

      const pagePromises = Array.from(
        { length: pageCount },
        (_, pageNumber) => {
          return docData.getPage(pageNumber + 1).then((pageData) => {
            return pageData.getTextContent().then((textContent) => {
              return textContent.items.map(({ str }) => str).join(" ");
            });
          });
        }
      );

      return Promise.all(pagePromises).then((pages) => {
        setPages(pages);
        setIsLoading(false);
      });
    });
  }, [file]);

  useEffect(() => {
    if (!searchString || !searchString.length) {
      setResultsList([]);
      return;
    }

    const regex = new RegExp(`${searchString}*`, "i");
    const updatedResults = [];

    pages.forEach((text, index) => {
      if (regex.test(text)) {
        updatedResults.push(index + 1);
      }
    });

    setResultsList(updatedResults);
  }, [pages, searchString]);

  return [resultsList, pages, isLoading];
};

// const file = 'https://arxiv.org/pdf/quant-ph/0410100.pdf';
// const file2 = 'https://ulrc-publications.s3.amazonaws.com/Volume+i-xiii/Vol+3.pdf'

function DocumentPreview({ file, access }) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [searchResultPages, setSearchResultPages] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isDivVisible, setIsDivVisible] = useState(false);

  const [resultsList, pages, isLoading] = usePdfTextSearch(file, searchString);
  const pageRefs = useRef([]);
  const debouncedSearch = useRef(_.debounce((nextValue) => setSearchString(nextValue), 500)).current;

  let os;
  if (navigator.platform.indexOf('Mac') !== -1) {
    os = ['⌘', 'F'];
  } else {
    os = ['Ctrl', 'F'];
  }

  // useEffect(() => {
  //   if(!searchString) return;
  //   var elements = document.querySelectorAll('[role="presentation"]');    
  //   elements.forEach((context) => {
  //     var instance = new Mark(context);
  //     // instance.mark(searchString);
  //     instance.unmark({ done: () => {
  //       instance.mark(searchString);
  //     }});
  //   });
  // }, [searchString])
  

  useEffect(() => {
    setSearchResultPages(
      resultsList.map((index) => {
        return pages[index - 1]
      })
    )
  }, [resultsList, pages]);

  // useEffect(() => {
  //   setSearchResults(searchResultPages.map((page, index) => {
  //     let wordPosition = page.toLowerCase().indexOf(searchString.toLowerCase());
  //     let startIndex = Math.max(0, wordPosition - 10);
  //     let endIndex = Math.min(page.length, wordPosition + 80);
  //     let result = page.slice(startIndex, endIndex);
  //     return { text: result, pageNumber: resultsList[index - 1] }
  //   }))
  // }, [searchResultPages, searchString, resultsList])

  const memoizedSearchResults = useMemo(() => {
    return searchResultPages.map((page, index) => {
      let wordPosition = page.toLowerCase().indexOf(searchString.toLowerCase());
      let startIndex = Math.max(0, wordPosition - 10);
      let endIndex = Math.min(page.length, wordPosition + 80);
      let result = page.slice(startIndex, endIndex);
      return { text: result, pageNumber: resultsList[index - 1] }
    })
   }, [searchResultPages, searchString, resultsList]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };

  const handleButtonClick = () => {
    setIsDivVisible(true);

    setTimeout(() => {
      setIsDivVisible(false);
    }, 5000);
  };

  return (
    <div className="w-[100%] mx-auto relative">
      <div className='flex items-center justify-center h-10 border border-slate-200'>
        <div className='flex items-center'>
          {/* <p>
            Page {pageNumber} of {numPages}
          </p> */}
          {/* <input
            type='text'
            onChange={(e) => debouncedSearch(e.target.value)}
            className='border-2 border-blue-600 w-[60%] h-8 pl-2 mr-2'
          /> */}
          <button
            className='flex items-center gap-1 px-4 py-1 border border-blue-600 rounded-md text-blue-600'
            onClick={() => handleButtonClick()}
          >
            <IoSearch size={18} />
            Search
          </button>
        </div>
        {/* <div className='flex ml-5'>
          <GoDownload />
        </div> */}
       {ACCESS_TYPES.FREE === access && <button
          onClick={() => handleDownload(file, 'Vol 3.pdf')}
          className='flex items-center gap-1 px-4 py-1 ml-5 bg-blue-600 text-white rounded-md'>
          Download
          <GoDownload />
        </button>}
      </div>
      {searchString.length > 0 && <div className='absolute top-10 right-5 w-96 max-h-[250px] bg-white border border-red z-[100] overflow-auto shadow-md'>
        {
          memoizedSearchResults.map((result, index) => (
            <div
              key={index}
              className='px-2 py-1 border-b border-gray-100 text-sm cursor-pointer'
              onClick={() => {
                pageRefs.current[result.pageNumber]?.scrollIntoView()
              }}
            >
              <Highlighter
                highlightClassName="highlightText"
                searchWords={[searchString]}
                autoEscape={true}
                textToHighlight={result.text}
              />
            </div>
          ))
        }
      </div>}
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <div className='flex flex-row'>
          <div className='w-[25%] '>
            <div className='py-2 bg-slate-100 pl-5 shadow border-r border-slate-200'>
              OUTLINE
            </div>
            <div className='h-[calc(100vh-80px)] overflow-auto'>
              <Outline className='py-2' />
            </div>
          </div>
          <div className=' h-[calc(100vh-40px)] overflow-auto bg-slate-50 w-full'>
            <div className='w-[70%] mx-auto'>
              {Array.from(new Array(numPages), (el, index) => (
                <div ref={(el) => (pageRefs.current[index] = el)} style={{ height: '100%' }}>
                  <Page
                    key={`page_${index + 1}`}
                    // ref={(el) => (pageRefs.current[index] = el)}            
                    pageNumber={index + 1}
                    scale={1.4}
                    className='my-5 shadow-lg'
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

      </Document>
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
      {isDivVisible && <div className='absolute z-[200] w-52 h-48 bottom-12 rounded-xl flex justify-center items-center fadeInOut' style={{
        backgroundColor: 'rgba(0,0,0,0.7)',
        left: '50%',
        transform: 'translate(-50%, 0)'
      }}>
        <div>
          <p className='text-white text-center text-lg'>Press the keys</p>
          <div className='flex items-center justify-center text-white text-sm my-2'>
            <span className='text-6xl'>{os[0]}</span>
            <span className='mx-2 text-lg'>+</span>
            <span className='text-6xl'>{os[1]}</span>
          </div>
          <p className='text-white text-center text-lg'>to search Doc </p>
        </div>
      </div>}

    </div>
  );
}

export default DocumentPreview;
