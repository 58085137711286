import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Select, Spin } from 'antd';
import { API_URL } from '../../../envs';
import { getPublicationTypes } from '../../constants/forms';
import FileInput from './FileUpload';
import axios from 'axios';
import { s3Config } from '../../constants/configs';
import { toast } from 'react-toastify';
import { useAuth } from '../../../Auth';

const EditPublicationModal = ({ publicationId, publicationKey, publicationUrl, visible, onCancel, fetchAllData }) => {
    const [publication, setPublication] = useState(null);
    const [form] = Form.useForm();
    const [fields, setFields] = useState([])
    const [loader, setLoader] = useState(false)
    const [file, setFile] = useState(null)
    const [cover, setCover] = useState(null)
    const { userData } = useAuth()

    useEffect(() => {
        (async () => {
            try {
                setLoader(true);
                const obj = await getPublicationTypes()
                const _fields = obj?.[publicationKey]
                setFields(_fields)
            } catch (err) {
                console.error(err)
            } finally {
                setLoader(false)
            }
        })()
    }, [publicationKey])

    useEffect(() => {
        setLoader(true);
        fetch(`${API_URL + publicationUrl}/${publicationId}`)
            .then(response => response.json())
            .then(data => {
                setPublication({ id: data?.data?.id, ...data?.data?.attributes })
            })
            .catch(error => console.error(error))
            .finally(() => setLoader(false))
    }, [publicationId, visible]);

    const handleFileUpload = (file, name) => {
        if (!file) {
            setPublication(publication => ({ ...publication, [name]: '' }))
        }
        setFile(file)
    }
    const handleCoverUpload = (file, name) => {
        if (!file) {
            setPublication(publication => ({ ...publication, [name]: '' }))
        }
        setCover(file)
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        try {
            let coverUploadResponse = null;
            let fileUploadResponse = null;

            if (cover) {
                const formData = new FormData();
                formData.append('file', cover);
                formData.append('upload_preset', 'g8fw4v8y');

                coverUploadResponse = await axios.post(
                    `https://api.cloudinary.com/v1_1/dtk0zusjw/image/upload`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )

                console.log("coverUploadResponse INSIDE:", coverUploadResponse);
            };

            if (file) {
                fileUploadResponse = await s3Config.upload({
                    Bucket: 'ulrc-publications',
                    Key: file.name,
                    Body: file,
                    ContentType: file.type,
                }).promise();

                console.log("fileUploadResponse INSIDE:", fileUploadResponse)
            }

            let data            

            if (publicationKey === 'lawsOfUganda') {
                data = {
                    ...publication,
                    [fields?.fileName]: fileUploadResponse?.Location || publication[fields?.fileName]
                };
            } else {
                data = {
                    ...publication,
                    [fields?.coverName]: coverUploadResponse?.data?.secure_url || publication[fields?.coverName],
                    [fields?.fileName]: fileUploadResponse?.Location || publication[fields?.fileName]
                };
            }            

            const response = await axios.put(`${API_URL + publicationUrl}/${publicationId}`, { data }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });

            if (response) {
                onCancel();
                toast.success('Publication has been updated successfully', {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                fetchAllData()
            }

        } catch (error) {
            toast.error('There was a problem updating this publication, please try again', {
                position: toast.POSITION.BOTTOM_CENTER
            })
        } finally {
            setLoader(false);
        }
    }

    const renderInputFields = (field) => {
        switch (field.inputType) {
            case 'textarea': {
                return (
                    <textarea
                        name={field.name}
                        required={field?.required || true}
                        className='border-slate-300 border w-full h-24 pl-2 rounded'
                        placeholder={field.placeholder}
                        value={publication?.[field.name]}
                        onChange={e => setPublication(publication => ({ ...publication, [field.name]: e.target.value }))}
                    />
                )
            }
            case 'file': {
                return (
                    <FileInput
                        initialValue={publication?.[field.name]}
                        accept={field.fileType === "doc" ? ".pdf" : "image/*"}
                        onChange={(e) => {
                            field.fileType === "doc" ? handleFileUpload(e, field.name) : handleCoverUpload(e, field.name)
                        }}
                    />
                )
            }
            case 'date': {
                return (
                    <input
                        className='border-slate-300 border w-full h-8 pl-2 rounded'
                        placeholder={field.placeholder}
                        required={field?.required || true}
                        type='date'
                        name={field.name}
                        value={publication?.[field.name]}
                        onChange={e => setPublication(publication => ({ ...publication, [field.name]: e.target.value }))}
                    />
                )
            }
            case 'select': {
                return (
                    <Select
                        options={field.options}
                        required={field?.required || true}
                        className='w-full h-8 rounded'
                        name={field.name}
                        value={publication?.[field.name]}
                        placeholder={field.placeholder}
                        onChange={(e) => setPublication(publication => ({ ...publication, [field.name]: e }))}
                    />
                )
            }
            default: {
                return (
                    <input
                        className='border-slate-300 border w-full h-8 pl-2 rounded'
                        placeholder={field.placeholder}
                        required={field?.required || true}
                        name={field.name}
                        value={publication?.[field.name]}
                        onChange={e => setPublication(publication => ({ ...publication, [field.name]: e.target.value }))}
                    />
                )
            }
        }
    }

    const renderFormFields = () => {
        return fields?.fields?.map((field, index) => (
            <div key={index} className='mb-3'>
                <div>{field.label}</div>
                {renderInputFields(field)}
            </div>
        ));
    }    

    return (
        <Modal
            open={visible}
            title="Edit Publication"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" disabled={loader} onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="save" disabled={loader} className='bg-blue-600 text-white' onClick={handleSubmit}>
                    Save
                </Button>
            ]}
        >
            <Spin spinning={loader}>
                <>
                    {renderFormFields()}
                </>
            </Spin>
        </Modal >
    );
};

export default EditPublicationModal;
