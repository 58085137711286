import React, { useEffect } from 'react'
import logo2 from '../ulrc_logo.png';
import envelope from '../envelope.png';
import { useAuth } from '../Auth';
import { Link, useNavigate } from 'react-router-dom';

function ResetLinkSent() {

    const navigate = useNavigate()
    const { userData } = useAuth()

    useEffect(() => {
        /// Temp work around for route protection
        if (userData) navigate("/")
    }, [userData])

    return (
        <div className='h-screen bg-bgGrey pt-7'>
            <div className='py-5 pl-20'>                
                <Link to={'/'}><img src={logo2} alt="logo" className='w-32' /></Link>
            </div>
            <div className='flex justify-center items-center flex-col mt-10'>
                <div className='bg-white pb-20 pt-10 px-12 rounded-3xl'>
                    <img src={envelope} alt="envelope" className='w-32 m-auto' />
                    <div className='text-5xl text-center font-serif'>Reset Link Sent</div>
                    <div className='text-md text-center w-96 text-slate-600 mt-8'>A reset link has been sent to </div>
                    <div className='text-md text-center w-96 text-slate-600 '>example@mail.com</div>
                    <form className='mt-10'>
                        <button className='h-10 w-96 rounded-lg bg-brightRed text-white'>Go to email</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetLinkSent
