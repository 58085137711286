import React from 'react';
import { FaSearch, FaUserAlt, FaGlobeAfrica, FaFileArchive, FaFileAlt } from 'react-icons/fa'
import { BiBell, BiCart } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import logo2 from '../../ulrc_logo.png';
import { renderPublications } from '../Home';
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import court from '../../image_ug.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PublicationsNav } from '../UnAuthHeader';
import Footer from '../Footer';
import { _index } from '../searchClient';
import { MdBalance, MdGTranslate } from 'react-icons/md';
import { FiChevronDown, FiChevronUp, FiFileText } from 'react-icons/fi';
import { GrLanguage } from "react-icons/gr";
import axios from 'axios';
import { API_URL } from '../../envs';
import { IoArrowBack } from 'react-icons/io5';

const pubicationOptions = [
    'Copyright infringement',
    'Contract Law',
    'Revenue Law'
]

const lawsOfUg = [
    {
        label: 'Principal Laws',
        value: 'Principal Laws'
    },
    {
        label: 'Subsidiary Laws',
        value: 'Subsidiary Laws'
    },
    {
        label: 'Consolidated Laws',
        value: 'Consolidated Laws'
    }
]


const translatedVersions = [
    {
        label: 'English',
        value: 'english'
    },
    {
        label: 'Translated Versions',
        value: 'translated'
    },
    {
        label: 'Braille',
        value: 'braille'
    },
    {
        label: 'Previous Constitutions',
        value: 'previousConstitutions'
    }
]
// [
//     {
//         label: "Acholi",
//         value: "Acholi"
//     },
//     {
//         label: "Alur",
//         value: "Alur"
//     },
//     {
//         label: "Ateso",
//         value: "Ateso"
//     },
//     {
//         label: "Dhopadhola",
//         value: "Dhopadhola"
//     },
//     {
//         label: "English",
//         value: "English"
//     },
//     {
//         label: "Kishwahili",
//         value: "Kishwahili"
//     },
//     {
//         label: "Lango",
//         value: "Lango"
//     },
//     {
//         label: "Leb-lango",
//         value: "Leb-lango"
//     },
//     {
//         label: "Luganda",
//         value: "Luganda"
//     },
//     {
//         label: "Lugbara",
//         value: "Lugbara"
//     },
//     {
//         label: "Lugwere",
//         value: "Lugwere"
//     },
//     {
//         label: "Lukhonzo",
//         value: "Lukhonzo"
//     },
//     {
//         label: "Lumasaba",
//         value: "Lumasaba"
//     },
//     {
//         label: "Lusoga",
//         value: "Lusoga"
//     },
//     {
//         label: "Madi-tii",
//         value: "Madi-tii"
//     },
//     {
//         label: "Nga'karimojong",
//         value: "Nga'karimojong"
//     },
//     {
//         label: "Rufumbira",
//         value: "Rufumbira"
//     },
//     {
//         label: "Runyankole-rukiga",
//         value: "Runyankole-rukiga"
//     },
//     {
//         label: "Runyoro-Rutoro",
//         value: "Runyoro-Rutoro"
//     }
// ]

const otherPublications = [
    {
        label: "Index of the laws of Uganda",
        value: "Index of the laws of Uganda"
    },
    {
        label: "Uganda Living Law Journal",
        value: "Uganda Living Law Journal"
    },
    {
        label: "Translated Laws",
        value: "Translated Laws"
    },
    {
        label: "Cummulative Supplement",
        value: "Cumulative Suppliment"
    }
]

const _reports = [
    {
        label: "Annual reports",
        value: "Annual reports"
    },
    {
        label: "Study reports",
        value: "Study reports"
    },
]


const SideMenu = () => {
    const [translationCat, setTranslationCat] = React.useState(false)
    const [laws, setLaws] = React.useState(true)
    const [reports, setReports] = React.useState(false)
    const [other, setOther] = React.useState(false)

    return (
        <div className="items-start self-stretch flex grow flex-col w-full mt-2 max-h-[620px] llg:max-h-full llg:h-auto overflow-auto">

            <div className="content-center flex-wrap self-stretch flex w-full flex-col px-3.5 py-2.5">
                <div
                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                    onClick={() => setTranslationCat(!translationCat)}
                >
                    <div className="flex items-center gap-3.5">
                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                        <img src={court} alt="logo" className="w-6 h-6" />
                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Constitution</div>
                    </div>
                    {translationCat ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                </div>
                {translationCat &&
                    <div className="flex flex-col gap-2.5 mt-2 ml-10">
                        {
                            translatedVersions.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        {/* <input type="checkbox" name={item.value} id={item.value} />
                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label> */}
                                        <Link to={`/constitution?select=${item.value}`} className="text-slate-500 text-sm font-medium hover:underline hover:text-blue-800 ">{item.label}</Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>

            <div className="content-center flex-wrap self-stretch flex w-full flex-col px-3.5 py-2.5">
                <div
                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                    onClick={() => setLaws(!laws)}
                >
                    <div className="flex items-center gap-3.5">
                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                        <MdBalance size={24} className="text-slate-500" />
                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Laws of Uganda</div>
                    </div>
                    {laws ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                </div>
                {laws &&
                    <div className="flex flex-col gap-2.5 mt-2 ml-10">
                        {
                            lawsOfUg.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        {/* <input type="checkbox" name={item.value} id={item.value} />
                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label> */}
                                        <Link to={`/laws-of-uganda?select=${item.value}`} className="text-slate-500 text-sm font-medium hover:underline hover:text-blue-800 ">{item.label}</Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>

            <div className="content-center flex-wrap self-stretch flex w-full flex-col px-3.5 py-2.5">
                <div
                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                    onClick={() => setReports(!reports)}
                >
                    <div className="flex items-center gap-3.5">
                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                        <FaFileArchive size={24} className="text-slate-500" />
                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Reports</div>
                    </div>
                    {reports ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                </div>
                {reports &&
                    <div className="flex flex-col gap-2.5 mt-2 ml-10">
                        {
                            _reports.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        {/* <input type="checkbox" name={item.value} id={item.value} />
                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label> */}
                                        <Link to={`/report?select=${item.value}`} className="text-slate-500 text-sm font-medium hover:underline hover:text-blue-800 ">{item.label}</Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>

            <div className="content-center flex-wrap self-stretch flex w-full flex-col px-3.5 py-2.5">
                <div
                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                    onClick={() => setOther(!other)}
                >
                    <div className="flex items-center gap-3.5">
                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                        <FaFileAlt size={24} className="text-slate-500" />
                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Other Publications</div>
                    </div>
                    {other ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                </div>
                {other &&
                    <div className="flex flex-col gap-2.5 mt-2 ml-10">
                        {
                            otherPublications.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        {/* <input type="checkbox" name={item.value} id={item.value} />
                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label> */}
                                        <Link to={`/other-publication?select=${item.value}`} className="text-slate-500 text-sm font-medium hover:underline hover:text-blue-800 ">{item.label}</Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const AllPubications = ({  }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryText = queryParams.get('query');
    const navigate = useNavigate()

    const [search, setSearch] = React.useState(queryText || '')
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [list, setList] = React.useState([])

    React.useEffect(() => {

        setLoading(true)
        _index
            .search(search, {
                limit: 70,
            })
            .then(({ hits }) => {
                setResults(hits)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [search])

    // React.useEffect(() => {
    //     (async () => {
    //         const resp = await axios.get(`${API_URL}/publications?populate=*&pagination[start]=0&pagination[limit]=70`)
    //         const data = resp.data?.data || []
    //         if(data.length > 0) {
    //             // const items = []
    //             // data.forEach(item => {
    //             //     const itemlist = item.attributes?.publications?.data || []
    //             //     items.push(...itemlist)
    //             // })
    //             setList(data)
    //         }
    //     })()
    // }, [])

    return (
        <div className='bg-bgGrey min-h-full flex flex-col justify-between'>
            <div className='pt-7 pb-20 md:container mx-auto'>
                <PublicationsNav search={search} setSearch={setSearch} />

                <div className='flex items-start'>
                    <div className='pt-8 pr-2 w-[245px]'>
                        <div className='font-bold mb-3 text-lg'>Filter</div>
                        <SideMenu />
                    </div>
                    <div className='border-l-4 pt-8 pl-8'>
                        <div className='font-bold text-lg mb-6 flex items-center'>    
                            <IoArrowBack
                                onClick={() => navigate('/')}
                                className='inline-block mr-2 text-xl cursor-pointer' />                        
                            All Publications
                        </div>
                        <div className='grid grid-flow-row grid-cols-5 gap-4 max-llg:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1'>
                        {/* <div className="items-stretch flex gap-5 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5 max-md:mt-10"> */}

                            {
                                results.map(({ pubThumbnailURL, publicationName, id, publicationType }) => {
                                    const link = publicationType === "Laws of Uganda" ? `/laws-of-uganda/${id}` : `/all-publications/${id}`
                                    return (
                                        <Link to={link} title={publicationName}>
                                            {/* <div className='w-40'> */}
                                                <img src={pubThumbnailURL} alt={"Publication"} className={'h-60'} />
                                                <div className='flex justify-between items-center mt-2 pl-1'>
                                                    <span className='text-sm w-36 text-center' title={publicationName}>{publicationName}</span>
                                                </div>
                                            {/* </div> */}
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        {
                            !loading && results.length === 0 &&
                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                No Publications Found
                            </div>
                        }
                        {
                            loading &&
                            <div className="text-neutral-800 text-xl font-light mt-2.5 max-md:max-w-full text-center">
                                Loading Publications...
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AllPubications