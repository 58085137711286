import React, { useState } from 'react'
import { BiCart } from "react-icons/bi";
import { AiOutlineHeart, AiOutlineWallet } from "react-icons/ai"
// import { PublicationsNav } from '.'
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import Footer from '../Footer';
import Pricing from '../Pricing';
import { PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import { _index, _report } from '../searchClient';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PdfPreview from '../Pdf';
import axios from 'axios';
import qs from 'qs';
import { ACCESS_TYPES, API_URL, imagePlaceholder } from '../../envs';
import { IoArrowBack } from 'react-icons/io5';
import { useAuth } from '../../Auth';
import { Spin } from 'antd';


const PreviewReport = () => {

    const [pricingModal, setPricingModal] = useState(false)
    const [publication, setPublication] = useState(null)
    const [suggestions, setSuggestions] = React.useState([])
    const [preview, setPreview] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const { userData, subscriptionData } = useAuth()
    // const [openPlans, setOpenPlans] = React.useState(false)
    const navigate = useNavigate()
    const { id } = useParams();


    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
            const response = await axios.get(`${API_URL}/reports/${id}`);
            const data = response.data.data;
            console.log("data:", data)
            setPublication({ id: data.id, ...data.attributes });
            } catch(err) {

            } finally {
                setLoading(false)
            }
        })()
    }, [id])

    React.useEffect(() => {

        if (publication?.reportType) {
            // _report
            //     .search(publication?.reportType || '', {
            //         limit: 6,
            //         // filter: `treatySubject = "${treaty?.treatySubject}" OR docType = "${treaty?.docType}" OR region = "${treaty?.region}" OR partiesInvoved = "${treaty?.partiesInvoved}" OR placeOfAdoption = "${treaty?.placeOfAdoption}" OR placeOfAdoption = "${treaty?.placeOfAdoption}"`
            //     })
            //     .then(({ hits }) => {
            //         const newHits = hits.filter((hit) => (hit.id !== publication?.id) && (!!hit?.publicationName));
            //         setSuggestions(newHits);
            //     })
            //     .catch((err) => {
            //         console.error(err)
            //     })

            (async () => {
                const query = qs.stringify({
                    filters: {
                        reportType: {
                            '$eq': publication?.reportType
                        }
                    }
                }, {
                    encodeValuesOnly: true,
                });

                const response = await axios.get(`${API_URL}/reports?populate=*&${query}`);
                const data = response.data.data;
                console.log("fetched suggestions:", data)
                // const filtered = data.filter(hit => (hit.id !== publication?.id) || (hit?.attributes?.publications?.data?.length === 0) || (!!hit?.attributes?.reportName) );
                const filtered = data.filter(hit => hit.id !== publication?.id).filter(hit => hit?.attributes?.publications?.data?.length === 0).filter(hit =>!!hit?.attributes?.reportName);
                console.log("fetched filtered suggestions:", filtered)
                setSuggestions(getFirstFive(filtered));
            })()
        }
    }, [publication])

    const getFirstFive = (arr) => {
        return arr.length <= 5 ? arr : arr.slice(0, 5);
    }

    const continueReading = () => {
        // const paymentsuccess = localStorage.getItem("paymentsuccess")
        // const userData = JSON.parse(localStorage.getItem("user"))
        // console.log("paymentsuccess, userData:", paymentsuccess, userData)
        if (publication?.accessType === ACCESS_TYPES.FREE || publication?.accessType === ACCESS_TYPES.FREE_VIEW || (subscriptionData && userData)) {
            setPreview(true)
        } else {
            setPricingModal(true)
            localStorage.setItem("publicationLink", `/report/${id}`)
        }
    }

    const openPlansModal = () => {
        setPricingModal(true)
    }

    console.log("SUGGESTIONS:", suggestions)

    return (
        <div className='bg-bgGrey relative'>
            <div className='pt-7 pb-20 container mx-auto'>
                {/* <PublicationsNav /> */}
                <UnAuthHeader3 openPlansModal={openPlansModal} />
                <Spin spinning={loading}>
                    <div className='flex mt-9 space-x-10 justify-center'>
                        <div>
                            <img src={publication?.reportThumnailURL || imagePlaceholder} className='w-56' alt={'Publication'} />
                        </div>
                        <div className='w-3/4'>
                            <div className='w-3/4'>
                                <div className='mb-2 flex justify-between items-center'>
                                    <div className='text-4xl flex'>
                                        <IoArrowBack
                                            className='inline-block mr-2 cursor-pointer text-2xl mt-2'
                                            onClick={() => navigate('/report')}
                                        />
                                        <div className='w-[99%]'>{publication?.reportName}</div>
                                    </div>
                                </div>
                                <div className="items-stretch border border-[color:var(--lighter-grey-strokes,#ECEDED)] flex flex-col rounded-xl border-solid mt-5">
                                    <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                        <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                            <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                                Access Type
                                            </div>
                                            <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                                {publication?.accessType || 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="justify-center items-stretch bg-slate-100 flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                        <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                            <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                                Commencement
                                            </div>
                                            <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                                {publication?.reportDate || 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                        <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                            <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                                Report Type
                                            </div>
                                            <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                                {publication?.reportType || 'N/A'}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='mt-4 mb-8'>
                                    {publication?.reportDescription || 'N/A'}
                                </div>
                                {publication?.reportFileURL && <div
                                    className="text-white bg-bgBlue text-center text-base leading-6 whitespace-nowrap justify-center items-center border border-[color:var(--Grey-Mid,#999)] w-[707px] max-w-full mr-3 mt-8 px-5 py-4 rounded-2xl border-solid self-end max-md:mr-2.5 cursor-pointer"
                                    onClick={continueReading}
                                >
                                    Access
                                </div>}

                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
            <div className="bg-white self-stretch flex w-full flex-col items-center mt-14 px-5 py-12 max-md:max-w-full max-md:mt-10">
                <div className="flex w-full max-w-[1128px] flex-col items-center mt-16 mb-8 max-md:max-w-full max-md:mt-10">
                    <div className="overflow-hidden text-neutral-800 text-ellipsis text-5xl leading-[54px] w-[508px] max-w-full ml-12 max-md:text-4xl max-md:leading-[50px]">
                        Similar Publications
                    </div>
                    <div className="self-stretch flex items-stretch justify-between gap-5 mt-14 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mt-10">
                        {
                            suggestions.map(({ attributes: { reportThumnailURL, reportName }, id }) => {
                                const link = `/report/${id}`
                                return (
                                    <a href={link} className="items-center flex grow basis-[0%] flex-col">
                                        <img
                                            loading="lazy"
                                            src={reportThumnailURL || Publish2}
                                            className="aspect-[0.65] object-contain w-[180px] border border-blue-900"
                                        />
                                        <div className="text-neutral-800 text-ellipsis text-center text-base mt-1 w-44">
                                            {reportName}
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {pricingModal && <Pricing closeModal={() => setPricingModal(false)} />}
            {preview && publication?.reportFileURL && <PdfPreview closePreview={() => setPreview(false)} preview={preview} docUrl={publication?.reportFileURL} access={publication?.accessType} filename={publication?.reportName} />}
        </div>
    )
}

export default PreviewReport;
