import React, { useEffect } from 'react'
import { Steps, Modal, Button } from 'antd';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { MdPayment } from "react-icons/md";
import axios from 'axios';
import { VscError } from "react-icons/vsc";
import { useAuth } from '../Auth';
import { API_PAY_URL, API_URL } from '../envs';
import { useNavigate } from 'react-router-dom';

const icons = [
    <UserOutlined />,
    <SolutionOutlined />,
    <CheckCircleOutlined />,
    <SmileOutlined />
]

function generateTenDigitNumber() {
    var number = '';
    while (number.length < 10) {
        number += Math.floor(Math.random() * 10);
    }
    return parseInt(number);
}

const doneDescriptions = [
    // "Your TIN has been verified",
    // "Your Payment Reference Number has been generated",
    "Your payment has been confirmed",
    "Your payment is successful. Redirecting..."
]

const _items = [
    // {
    //     title: 'TIN Verification',
    //     status: 'process',
    //     icon: <UserOutlined />,
    //     description: 'We are verifying your National Identification Number. Please wait...'
    // },
    // {
    //     title: 'Creating PRN',
    //     status: 'wait',
    //     icon: <SolutionOutlined />,
    //     description: 'We are generating your Payment Reference Number. Please wait...'
    // },
    {
        title: 'Confirm Payment',
        status: 'wait',
        icon: <CheckCircleOutlined />,
        description: 'You will receive a prompt to confirm payment. enter your pin and submit'
    },
    {
        title: 'Done',
        status: 'wait',
        icon: <SmileOutlined />,
        description: 'Your payment is successful. Redirecting...'
    },
]

const handleTINVerification = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, 5000)
    })
}

// const handlePRNGeneration = (requestData) => {
//     return new Promise((resolve, reject) => {
//         const resp = axios.post("http://165.232.131.154:8001/get_prn", {
//             "TaxPayerName": requestData.TaxPayerName,
//             "ContactNo": requestData.ContactNo,
//             "AdditionalFees": 0,
//             "Amount": requestData?.price,
//             "Email": "test@ulrc.go.ug",
//             "ExpiryDays": 21,
//             "PaymentBankCode": "STN",
//             "PaymentMode": "ONLINE",
//             "PaymentType": "DT",
//             "ReferenceNo": "string",
//             "SRCSystem": "ULRC",
//             "TIN": "1000943176",
//             "TaxHead": "URSB75"
//         })
//             .then((response) => {
//                 console.log("response:", response)
//                 resolve()
//             })
//             .catch((error) => {
//                 console.log("error:", error)
//                 reject()
//             })
//     })
// }


function PaymentModal({ open, requestData, setOpen, prnVal }) {
    const [step, setStep] = React.useState(0)
    const [items, setItems] = React.useState([..._items])
    const [prn, setPrn] = React.useState('')
    const [isError, setIsError] = React.useState(false)
    const navigate = useNavigate()
    const { userData, setSubscription } = useAuth()

    useEffect(() => {
        if (open) {

            (async() => {
                await axios.post(API_PAY_URL, {
                    "merchant_reference": "auto",
                    "transaction_method": "MOBILE_MONEY",
                    "currency": "UGX",
                    // "amount": requestData.price,
                    "amount": 50000,
                    "provider_code": requestData.code,
                    "msisdn": requestData.ContactNo,
                    "customer_name": requestData.TaxPayerName,
                    // prn: prnVal,
                    prn: '2240013417179',
                    "description": "proxy request"
                })
            })()

            // const processPayment = async () => {
            //     try {
            //         // const prnVal = await handlePRNGeneration(requestData)
            //         await handlePaymentConfirmation(prnVal)
            //         // setStep(1)
            //         // await handlePaymentSuccess(prnVal, payData)
            //         // setStep(2)
            //         // setTimeout(() => {
            //         //     navigate("/cart/payment-success", { state: { ...requestData, prn: prnVal } })
            //         // }, 3000)
            //     } catch (error) {
            //         console.log("error:", error)
            //     }
            // }

            // processPayment()
        }
    }, [open])

    useEffect(() => {
        setItems(items.map((item, index) => {
            if (index < step) {
                return {
                    ...item,
                    status: 'finish',
                    icon: icons[index],
                    description: doneDescriptions[index]
                }
            }

            if (index === step) {
                return {
                    ...item,
                    status: 'process',
                    icon: <LoadingOutlined />
                }
            }

            return item
        }))
    }, [step])

    const handlePaymentConfirmation = (prnVal) => {
        // setItems(items.map((item, index) => {
        //     if (index === 1) {
        //         return {
        //             ...item,
        //             status: 'process',
        //             icon: <LoadingOutlined />,
        //             description: 'We are confirming your payment. Please wait...'
        //         }
        //     }
        //     return item
        // }))

        // console.log("handlePaymentConfirmation PRN:", prn)

        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         setIsError(false)
        //         setItems(items.map((item, index) => {
        //             if (index === 1) {
        //                 return {
        //                     ...item,
        //                     status: 'finish',
        //                     icon: <SolutionOutlined />,
        //                     description: 'Your payment has been confirmed'
        //                 }
        //             }
        //             return item
        //         }))
        //         resolve()
        //     }, 5000)
        // })
        return new Promise((resolve, reject) => {
            axios.post(API_PAY_URL, {
                "merchant_reference": "auto",
                "transaction_method": "MOBILE_MONEY",
                "currency": "UGX",
                // "amount": requestData.price,
                "amount": 30000,
                "provider_code": requestData.code,
                "msisdn": requestData.ContactNo,
                "customer_name": requestData.TaxPayerName,
                // prn: prnVal,
                prn: '2240013075502',
                "description": "proxy request"
            })
                .then((response) => {
                    // console.log("response:", response?.data)
                    // setIsError(false)
                    // setItems(items.map((item, index) => {
                    //     if (index === 1) {
                    //         return {
                    //             ...item,
                    //             status: 'finish',
                    //             icon: <SolutionOutlined />,
                    //             description: 'Your payment has been confirmed'
                    //         }
                    //     }
                    //     return item
                    // }))
                    // resolve(response?.data)
                })
                .catch((error) => {
                    console.log("error:", error)
                    setIsError(true)
                    setItems(items.map((item, index) => {
                        if (index === 1) {
                            return {
                                ...item,
                                status: 'error',
                                icon: <CloseCircleOutlined />,
                                description: 'An error occurred while confirming your payment'
                            }
                        }
                        return item
                    }))
                    reject()
                })
        })
    }

    // const handlePRNGeneration = (requestData) => {
    //     setItems(items.map((item, index) => {
    //         if (index === 0) {
    //             return {
    //                 ...item,
    //                 status: 'process',
    //                 icon: <LoadingOutlined />,
    //                 description: 'We are generating your Payment Reference Number. Please wait...'
    //             }
    //         }
    //         return item
    //     }))

    //     return new Promise((resolve, reject) => {
    //         const tempPRN = "2240000761449"
    //         setTimeout(() => {
    //             setIsError(false)
    //             setPrn(tempPRN)
    //             setItems(items.map((item, index) => {
    //                 if (index === 0) {
    //                     return {
    //                         ...item,
    //                         status: 'finish',
    //                         icon: <SolutionOutlined />,
    //                         description: 'Your Payment Reference Number has been generated'
    //                     }
    //                 }
    //                 return item
    //             }))
    //             resolve(tempPRN)
    //         }, 5000)
    //     })

        // return new Promise((resolve, reject) => {
        //     axios.post(`https://laws.go.ug/get_prn?time=${Date.now()}`, {
        //         "TaxPayerName": requestData.TaxPayerName,
        //         "ContactNo": requestData.ContactNo,
        //         "AdditionalFees": 0,
        //         "Amount": requestData?.price,
        //         "Email": userData?.email,
        //         "ExpiryDays": 21,
        //         "PaymentBankCode": "STN",
        //         "PaymentMode": "ONLINE",
        //         "PaymentType": "DT",
        //         "ReferenceNo": "string",
        //         "SRCSystem": "ULRC",
        //         "TIN": "1000943176",
        //         "TaxHead": "URSB75"
        //     })
        //         .then((response) => {
        //             // console.log("PRN response:", response?.data?.result)
        //             // console.log("PRN NUMBER response:", response?.data?.result?.__values__?.PRN)
        //             const prnVal = response?.data?.result?.__values__?.PRN
        //             setPrn(prnVal)
        //             setIsError(false)
        //             setItems(items.map((item, index) => {
        //                 if (index === 0) {
        //                     return {
        //                         ...item,
        //                         status: 'finish',
        //                         icon: <SolutionOutlined />,
        //                         description: 'Your Payment Reference Number has been generated'
        //                     }
        //                 }
        //                 return item
        //             }))
        //             resolve(prnVal)
        //         })
        //         .catch((error) => {
        //             // console.log("PRN error:", error)
        //             setIsError(true)
        //             setItems(items.map((item, index) => {
        //                 if (index === 0) {
        //                     return {
        //                         ...item,
        //                         status: 'error',
        //                         icon: <CloseCircleOutlined />,
        //                         description: 'An error occurred while generating your Payment Reference Number'
        //                     }
        //                 }
        //                 return item
        //             }))
        //             reject()
        //         })
        // })
    // }

    const subscriptionEnd = (startDate, duration) => {
        const multiplier = duration === "Annually" ? 365 : duration === "Monthly" ? 30 : 1
        const newDate = new Date(startDate)
        newDate.setDate(newDate.getDate() + multiplier)
        return newDate
    }

    const handlePaymentSuccess = (prnVal, payData) => {
        setItems(items.map((item, index) => {
            if (index === 2) {
                return {
                    ...item,
                    status: 'finish',
                    icon: <SmileOutlined />,
                    description: 'Your payment is successful. Redirecting...'
                }
            }
            return item
        }))

        const subscriberData = {
            subscriptionName: requestData.package,
            subscriptionType: requestData.duration,
            amount: `${requestData.price}`,
            numberOfUsers: requestData.noOfUsers,
            subscriptionStart: requestData.startDate,
            subscriptionEnd: subscriptionEnd(requestData.startDate, requestData.duration),
            paymentRef: payData?.internal_reference,
            userEmail: userData?.user?.email,
            PRN: prnVal,
            userPhone: requestData.ContactNo
        }

        return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/subscriptions`, {
                data: subscriberData
            })
                .then((response) => {
                    console.log("response:", response)                    
                    setIsError(false)
                    setItems(items.map((item, index) => {
                        if (index === 2) {
                            return {
                                ...item,
                                status: 'finish',
                                icon: <SmileOutlined />,
                                description: 'Your payment is successful. Redirecting...'
                            }
                        }
                        return item
                    }))
                    setSubscription(subscriberData)
                    resolve()
                })
                .catch((error) => {
                    console.log("error:", error)
                    setIsError(true)
                    setItems(items.map((item, index) => {
                        if (index === 2) {
                            return {
                                ...item,
                                status: 'error',
                                icon: <CloseCircleOutlined />,
                                description: 'An error occurred while processing your payment'
                            }
                        }
                        return item
                    }))
                    reject()
                })
        })

    }

    console.log("requestData:", requestData)
    console.log("PRN:", prn)

    return (
        <Modal
            title="Please wait as we process your payment..."
            centered
            footer={null}
            open={open}
            closable={false}
            width={1000}
        >
            <Steps
                current={step}
                items={items}
            />
            {/* <div>
                {prn && <>
                    <div className='text-center mt-5'>Your Payment Reference Number is:</div>
                    <div className='text-center text-4xl font-bold'>
                        {prn}
                    </div>
                </>}

            </div> */}
            {
                isError && <div className='text-center'>
                    <Button
                        className='mt-5'
                        onClick={() => setOpen(false)}>Close and try again</Button>
                </div>
            }
        </Modal>
    )
}

export default PaymentModal