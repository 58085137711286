import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import logo2 from '../ulrc_logo.png';
import { AuthHeader2 } from './UnAuthHeader';
import Footer from './Footer';
import { Button, Popconfirm } from 'antd';
import { FaQuestionCircle } from 'react-icons/fa';
import { API_URL } from '../envs';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialUserData = {
    email: "",
    password: ""
}

function DeleteAccount() {
    const [user, setUser] = useState(initialUserData);
    const [loading, setLoading] = useState(false);
    const { userData, removeUser } = useAuth()
    const navigate = useNavigate()

    const handleDelete = async () => {
        // Perform the account deletion logic here
        const url = `${API_URL}/auth/local`
        const loginData = { identifier: user.email, password: user.password }

        try {
            setLoading(true)
            const response = await axios.post(url, loginData)
            const data = response?.data
            const token = data?.jwt
            if(!token) return;
            const deleteUrl = `${API_URL}/users/${data?.user?.id}`
            const deleteResponse = await axios.delete(deleteUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success('Account deleted successfully', {
                position: toast.POSITION.BOTTOM_CENTER
            })
            localStorage.removeItem('user');
            removeUser()
            navigate('/signin');
        } catch (error) {
            const errorMsg = error?.response?.data?.error?.message || error.message
            toast.error(`Account cannot be deleted: ${errorMsg}`, {
                position: toast.POSITION.BOTTOM_CENTER
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='h-screen bg-bgGrey pt-2'>
            <div className='container mx-auto'>
                <AuthHeader2 />
            </div>
            <div className='flex justify-center items-center flex-col mt-10 mb-28'>
                <div className='bg-white pt-20 pb-16 px-12 rounded-3xl flex flex-col items-center'>
                    <div className='text-5xl font-serif'>Delete Your Account</div>
                    <div className='text-md w-96 text-slate-600 text-center mt-8'>Please enter your email address and password to confirm ownership before we remove your account.</div>
                    <form className='mt-10'>
                        <div className='mb-6'>
                            <input
                                className='h-10 pl-2 w-96 rounded-lg outline-none border border-brightRedlite block'
                                placeholder='Email Address'
                                value={user.email}
                                onChange={e => setUser({ ...user, email: e.target.value })}
                            />
                            <input
                                className='h-10 pl-2 w-96 rounded-lg outline-none border border-brightRedlite mt-4 block'
                                type='password'
                                placeholder='Password'
                                value={user.password}
                                onChange={e => setUser({ ...user, password: e.target.value })}
                            />
                        </div>
                        <Popconfirm
                            title="Delete my account"
                            description="This action is irreversible, Are you sure ?"
                            // icon={<FaQuestionCircle color="red"/>}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={handleDelete}
                            okButtonProps={{ className: 'bg-brightRed text-white deleteAccOk', danger: true }}
                        >
                            <Button
                                disabled={user.email === "" || user.password === "" || loading}
                                className='h-10 w-96 rounded-lg hover:text-white'
                                style={{
                                    background: "rgb(203, 56, 51)",
                                    color: "white"
                                }} danger>{loading ? 'Please wait' : 'Delete Account'}</Button>
                        </Popconfirm>
                    </form>
                    {/* Go back to login link */}
                    <div className='mt-4 text-center'>
                        Go to <Link to='/signin' className='text-brightRed'>login</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DeleteAccount