import MeiliSearch from "meilisearch";

export const client = new MeiliSearch({
    // host: 'https://ms-db9703268844-7296.fra.meilisearch.io',
    // host: 'https://ms-a714e8a54c35-8310.fra.meilisearch.io/',
    host: 'https://index.laws.go.ug/',
    apiKey: '1eab1bddfb8e426ec607ac771384b38420f53530'
});

export const _index = client.index('publication');
export const _laws = client.index('laws-of-uganda');
export const _constitutions = client.index('constitution');
export const _report = client.index('report');
export const _otherPublications = client.index('other-publication');
export const _volumes = client.index('volume');
export const _simplifiedLaws = client.index('simplified-law');
export const multi = client.multiSearch([
    
])
