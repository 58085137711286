import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import logo2 from '../ulrc_logo.png';
import court from '../image_ug.png';

function SignupSuccess() {

  const navigate = useNavigate()
    const { userData } = useAuth()

    useEffect(() => {
        /// Temp work around for route protection
        if (userData) navigate("/")
    }, [userData])

  return (
    <div className='h-screen bg-bgGrey pt-7'>
        <div className='bg-white py-5 pl-20 flex items-center gap-7'>
            <Link to={'/'}><img src={logo2} alt="logo" className='w-40' /></Link>
            <img src={court} alt="logo" className='w-16' />
        </div>
        <div className='flex justify-center items-center flex-col mt-28'>
            <div className='text-[40px] font-serif mb-2'>Account Created Successfully</div>
            <div className='text-xl text-slate-400'>A verification link has been sent to your email address.</div>
            <div className='text-xl text-slate-400'>Please verify your account.</div>
            <img src='images/email.svg' alt='success' className='mt-10 w-24' />
            <form className='mt-10'>
                <a href='/signin'><button type='button' className='h-10 w-96 rounded-lg bg-brightRed text-white'>Login</button></a>
            </form>
        </div>
    </div>
  )
}

export default SignupSuccess