import React from 'react'
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="bg-bgBlue flex w-full flex-col items-center pb-7 px-5 max-md:max-w-full">
            <div className="flex w-full max-w-[1147px] flex-col items-stretch max-md:max-w-full max-md:mt-15">
                {/* <div className="max-md:max-w-full">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[44%] max-md:w-full max-md:ml-0">
                            <div className="flex flex-col items-stretch max-md:mt-10">
                                <div className="text-white text-lg">
                                    Contact Us
                                </div>
                                <div className="flex items-center gap-5">
                                    <a href="https://www.facebook.com" target="_blank" className="flex items-center justify-center mt-4 border border-white bg-white w-10 h-10 rounded-[50%]">
                                        <FaFacebookF size={16} color="rgb(29,35,79)" />
                                    </a>
                                    <a href="https://www.instagram.com" target="_blank" className="flex items-center justify-center mt-4 border border-white bg-white w-10 h-10 rounded-[50%]">
                                        <FaInstagram size={18} color="rgb(29,35,79)" />
                                    </a>
                                    <a href="https://www.twitter.com" target="_blank" className="flex items-center justify-center mt-4 border border-white bg-white w-10 h-10 rounded-[50%]">
                                        <FaTwitter size={16} color="rgb(29,35,79)" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[21%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col items-stretch max-md:mt-10">
                                <Link to="/constitution" className="text-white text-lg leading-4 whitespace-nowrap">
                                    Constitution
                                </Link>
                                <Link to="/laws-of-uganda" className="text-white text-lg leading-4 whitespace-nowrap mt-7">
                                    Laws of Uganda
                                </Link>
                                <Link to="/report" className="text-white text-lg leading-4 whitespace-nowrap mt-7">
                                    Reports
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[35%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex items-stretch justify-between gap-5 max-md:mt-10">
                                <div className="flex grow basis-[0%] flex-col items-stretch">

                                    <Link to="/simplified-laws-of-uganda" className="text-white text-lg leading-4 whitespace-nowrap">
                                        Simplified Laws of Uganda
                                    </Link>
                                    <Link to="/other-publication" className="text-white text-lg leading-4 whitespace-nowrap mt-7">
                                        Other Publications
                                    </Link>
                                </div>
                                <div className="flex grow basis-[0%] flex-col items-stretch self-start">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="bg-zinc-400 bg-opacity-60 shrink-0 h-px mt-10 max-md:max-w-full max-md:mt-10" /> */}
                <div className="flex items-stretch justify-between gap-5 mt-8 max-md:max-w-full max-md:flex-wrap">
                    <div className="text-white text-lg leading-4 opacity-80 grow shrink basis-auto">
                        Copyright &copy; {new Date().getFullYear()} <a href="https://www.ulrc.go.ug/" target='_blank'>Uganda Law Reform Commission.</a>
                    </div>
                    <div className='gap-4 flex items-center justify-center'>
                        <a href="/privacy-policy" target='_blank' className="text-white text-right text-lg leading-4 opacity-80 whitespace-nowrap self-start">
                            Privacy Policy
                        </a>
                        <a href="/terms-and-conditions" target='_blank' className="text-white text-right text-lg leading-4 opacity-80 whitespace-nowrap self-start">
                            Terms & Conditions
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer