import * as React from "react";
import { MdClose, MdEmail, MdPhone } from "react-icons/md";
import { IoCheckmark } from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth";
import { notification } from "antd";

export const subscriptions = [
    [
        {
            package: "Bronze",
            user: "Up to",
            price: 5000,
            duration: "Daily",
            label: "5,000/Daily",
            noOfUsers: "10 Users",
            startDate: new Date(),
            features: [
                // "Lawyers",
                // "Researchers",
                // "Students"
            ]
        }
    ],
    [
        {
            package: "Bronze",
            user: "Up to",
            price: 50000,
            duration: "Monthly",
            label: "50,000/Monthly",
            noOfUsers: "10 Users",
            startDate: new Date(),
            features: [
                // "Lawyers",
                // "Researchers",
                // "Students"
            ]
        },
        {
            package: "Platinum",
            user: "Up to",
            price: 850000,
            duration: "Monthly",
            label: "850,000/Monthly",
            noOfUsers: "50 Users",
            startDate: new Date(),
            features: [
                // "Lawyers",
                // "Researchers",
                // "Students"
            ]
        },
        {
            package: "Diamond",
            user: "Up to",
            price: 1300000,
            duration: "Monthly",
            label: "1,300,000/Monthly",
            noOfUsers: "100 Users",
            startDate: new Date(),
            features: [
                // "Lawyers",
                // "Researchers",
                // "Students"
            ]
        }
    ],
    [
        {
            package: "Bronze",
            user: "Up to",
            price: 500000,
            duration: "Annually",
            label: "500,000/Annually",
            noOfUsers: "10 Users",
            startDate: new Date(),
            features: [
                // "Lawyers",
                // "Researchers",
                // "Students"
            ]
        },
        {
            package: "Platinum",
            user: "Up to",
            price: 10000000,
            duration: "Annually",
            label: "10,000,000/Annually",
            noOfUsers: "50 Users",
            startDate: new Date(),
            features: [
                // "University Libraries",
                // "Research Instituties",
                // "Corporations"
            ]
        },
        {
            package: "Diamond",
            user: "Up to",
            price: 15000000,
            duration: "Annually",
            label: "15,000,000/Annually",
            noOfUsers: "100 Users",
            startDate: new Date(),
            features: [
                // "Law Firms",
                // "Government Institutions",
                // "Universities"
            ]
        },
        {
            package: "Enterprise",
            user: "More than",
            notice: "Contact For Pricing",
            label: "15,000,000/Annually",
            noOfUsers: "100 Users",
            startDate: new Date(),
            email: "ulrc@ulrc.go.ug",
            phone: "0414-346200/341138",
            features: [
                // "Law Firms",
                // "Government Institutions",
                // "Universities"
            ]
        }
    ]
]

function Pricing({ closeModal }) {

    const navigate = useNavigate()
    const { userData, subscriptionData, _setSubscription } = useAuth()

    const [currentTab, setCurrentTab] = React.useState(2);
    const duration = {
        0: "Daily",
        1: "Monthly",
        2: "Annually"
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Already subscribed',
            description: `You are already subscribed to the ${subscriptionData?.subscriptionName} plan, please wait for it to expire or contact support for assistance`,
        });
    };

    const segueTo = (subscription) => {
        if (userData && subscriptionData) {
            openNotificationWithIcon("warning");
            return;
            // setTimeout(() => {
            //     closeModal()
            //     // localStorage.setItem("subscription", JSON.stringify(subscription))
            //     // navigate(userData ? "/cart/payment" : "/signin", { state: { to: "/cart/payment" } })
            // }, 2000)
        }
        // localStorage.setItem("subscription", JSON.stringify(subscription))
        _setSubscription(subscription)
        navigate(userData ? "/cart/payment" : "/signin", { state: { to: "/cart/payment" } })
    }

    console.log("subscriptionData ---- :", subscriptionData)

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-slate-900/75 flex justify-center items-center overflow-auto">
            <div className="bg-white relative flex flex-col pl-20 pr-16 pt-7 rounded-[30px] max-md:px-5 w-[1100px]">
                <div className="text-neutral-800 text-2xl font-medium text-center mt-7">
                    Choose The Right Plan Just For You
                </div>
                <div
                    className="absolute top-7 right-7 bg-slate-300/40 rounded cursor-pointer"
                    onClick={closeModal}
                >
                    <MdClose size={25} className="text-slate-500" />
                </div>

                <div className="items-center bg-zinc-500 bg-opacity-10 self-center flex w-[434px] max-w-full justify-between mt-5 py-0.5 rounded-lg max-md:flex-wrap max-md:justify-center">
                    <div
                        className={`flex-1 text-neutral-800 text-center cursor-pointer text-sm ${currentTab === 0 && "shadow bg-white mr-1 py-2 rounded-md border-[0.5px] border-solid border-black border-opacity-0"}`}
                        onClick={() => setCurrentTab(0)}
                    >
                        Daily
                    </div>
                    <div className="bg-zinc-700 bg-opacity-40 w-px h-4" />
                    <div
                        className={`flex-1 text-neutral-800 text-center text-sm cursor-pointer ${currentTab === 1 && "shadow bg-white mx-1 py-2 rounded-md border-[0.5px] border-solid border-black border-opacity-0"}`}
                        onClick={() => setCurrentTab(1)}
                    >
                        Monthly
                    </div>
                    <div className="bg-zinc-700 bg-opacity-40 w-px h-4" />
                    <div
                        className={`flex-1 text-center text-neutral-800 text-sm cursor-pointer ${currentTab === 2 && "shadow bg-white ml-1 py-2 rounded-md border-[0.5px] border-solid border-black border-opacity-0"}`}
                        onClick={() => setCurrentTab(2)}
                    >
                        Annually
                    </div>
                </div>
                <div className="self-center z-[1] w-full max-w-[1062px] mb-20 mt-8 max-md:max-w-full max-md:mb-10">
                    <div className="gap-5 flex justify-center max-md:flex-col max-md:items-stretch max-md:gap-0">
                        {
                            subscriptions[currentTab].map((subscription, index) => (
                                <div key={index} className="relative flex flex-col items-stretch w-[200px] ml-5 max-md:w-full max-md:ml-0">
                                    <div className="absolute bg-red-600 flex w-full shrink-0 h-[205px] flex-col mx-auto rounded-3xl max-md:mt-10" style={{ zIndex: '-10', top: '-7px' }} />
                                    <div className="items-center border bg-neutral-100 flex w-full grow flex-col mx-auto px-5 py-8 rounded-3xl border-solid border-red-600 border-opacity-20 max-md:mt-10">
                                        <div className="text-neutral-800 text-center text-base leading-6 justify-center items-stretch bg-slate-200 self-center w-[117px] max-w-full px-5 py-2 rounded-xl">
                                            <span className="font-semibold text-violet-950">
                                                {subscription.package}
                                                <br />
                                            </span>
                                            <span className=" text-neutral-800 text-xs whitespace-nowrap">{subscription.user}</span>
                                            <br />
                                            <span className=" text-neutral-800 text-xs whitespace-nowrap">{subscription.noOfUsers}</span>
                                        </div>
                                        {subscription?.price && <div className="justify-center items-stretch self-center flex w-[166px] max-w-full gap-1 mt-4 max-md:justify-center flex-col">
                                            <div className="text-neutral-800 text-center text-xs leading-5 opacity-50 self-center my-auto">
                                                UGX.
                                            </div>
                                            <div className="text-neutral-800 text-center text-xl font-semibold leading-8">
                                                {subscription?.price?.toLocaleString()}
                                            </div>
                                            <div className="text-neutral-800 text-center text-xs leading-5 opacity-50 self-center whitespace-nowrap my-auto">
                                                / {duration[currentTab]}
                                            </div>
                                        </div>}
                                        {
                                            subscription?.notice &&
                                            <div>
                                                <div className="text-lg font-bold text-center mt-2">{subscription?.notice}</div>
                                                {/* Add email and phone */}
                                                <div className="flex items-center mt-2 flex-col">
                                                    <div className="flex items-center gap-2">
                                                        <MdEmail size={16} className="text-slate-500" />
                                                        <a
                                                            href={`mailto:${subscription.email}`}
                                                            className="text-blue-600 text-sm">{subscription.email}</a>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <MdPhone size={16} className="text-slate-500" />
                                                        <a
                                                            href={`tel:${subscription.phone}`}
                                                            className="text-neutral-800 text-[12px]">{subscription.phone}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* <div className="justify-center items-stretch bg-white self-stretch flex w-full flex-col mt-5 pl-5 py-5 rounded-xl max-md:pr-5">
                                            {
                                                subscription.features.map((feature, index) => (
                                                    <div key={index} className="items-center flex gap-3">
                                                        <IoCheckmark size={13} className="text-slate-500" />
                                                        <div className="text-neutral-800 text-sm">
                                                            {feature}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div> */}
                                        {
                                            subscription?.price && <button onClick={() => segueTo(subscription)} className="justify-center items-center bg-bgBlue self-center flex gap-2 mt-8 px-8 py-2 rounded-xl max-md:px-5 text-white">
                                                <div className="text-center text-sm">
                                                    Subscribe
                                                </div>
                                                <GoArrowRight />
                                            </button>
                                        }
                                        {
                                            subscription?.notice && <a href={`mailto:${subscription.email}`} className="justify-center items-center border border-slate-300 self-center flex gap-2 mt-8 px-8 py-2 rounded-xl max-md:px-5 text-white">
                                            <div className="text-center text-sm text-slate-900 font-medium">
                                                Contact Sales
                                            </div>
                                        </a>
                                        }
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            {contextHolder}
        </div>
    );
}

export default Pricing;
