import { useEffect, useRef } from "react";

function PdfViewerComponent(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let PSPDFKit, instance;
    
    (async function () {
      PSPDFKit = await import("pspdfkit")

		PSPDFKit.unload(container) // Ensure that there's only one PSPDFKit instance.

      instance = await PSPDFKit.load({
        container,
        document: "https://ulrc-publications.s3.amazonaws.com/Volume+i-xiii/Vol+3.pdf", 
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        apiKey: "pdf_live_D2Fz6ipisDU6AtZWwWxDtWt4taUel8CSejqJL1jQtI8",

      });
    })();
    
    return () => PSPDFKit && PSPDFKit.unload(container)
  }, []);
  
  // This div element will render the document to the DOM.
  return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />
}

function DocumentViewerComponent() {
	return (
		<div className="PDF-viewer">
			<PdfViewerComponent
				document={"document.pdf"}
			/>
		</div>
	);
}

export default DocumentViewerComponent;