import React from 'react';
import court from '../../image_ug.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import Footer from '../Footer';
import { _index, _constitutions } from '../searchClient';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import axios from 'axios';
import { API_URL } from '../../envs';
import { IoArrowBack } from 'react-icons/io5';
import Pricing from '../Pricing';

const pubicationOptions = [
    'Copyright infringement',
    'Contract Law',
    'Revenue Law'
]

const lawsOfUg = [
    {
        label: 'Principal Laws',
        value: 'Principal Laws'
    },
    {
        label: 'Subsidiary Laws',
        value: 'Subsidiary Laws'
    },
    {
        label: 'Consolidated Laws',
        value: 'Consolidated Laws'
    }
]


const translatedVersions = [
    {
        label: 'English',
        value: 'english'
    },
    {
        label: 'Translated Versions',
        value: 'translated'
    },
    {
        label: 'Braille',
        value: 'braille'
    },
    {
        label: 'Previous Constitutions',
        value: 'previousConstitutions'
    }
]





const SideMenu = ({ langs }) => {
    const [translationCat, setTranslationCat] = React.useState(true)
    const [laws, setLaws] = React.useState(true)
    const [reports, setReports] = React.useState(false)
    const [other, setOther] = React.useState(false)



    function capitalizeFirstLowerRest(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const formatStr = (str) => {
        let name = str.includes('ENGLISH') ? 'English' : str.split(":")[0];
        return capitalizeFirstLowerRest(name);
    }

    return (
        <div className="items-start self-stretch flex grow flex-col w-full mt-2 max-h-[620px] llg:max-h-full llg:h-auto overflow-auto">

            <div className="content-center flex-wrap self-stretch flex w-full flex-col px-3.5 py-2.5">
                <div
                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                    onClick={() => setTranslationCat(!translationCat)}
                >
                    <div className="flex items-center gap-3.5">
                        <img src={court} alt="logo" className="w-6 h-6" />
                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Constitution</div>
                    </div>
                    {translationCat ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                </div>
                {/* {translationCat &&
                    <div className="flex flex-col gap-2.5 mt-2 ml-10">
                        {
                            translatedVersions.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        <input type="checkbox" name={item.value} id={item.value} onChange={handleCheck} />
                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                } */}
                {translationCat &&
                    <div className="flex flex-col gap-1.5 mt-2 ml-10">
                        {
                            langs.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        <a href={`/constitution/${item.id}`} className=" text-slate-500 text-sm font-medium leading-[142.857%] hover:underline">{formatStr(item?.attributes?.constName)}</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

            </div>
        </div>
    )
}

const Constitution = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryText = queryParams.get('query');
    const navigate = useNavigate()

    const [search, setSearch] = React.useState('')
    const [openPlans, setOpenPlans] = React.useState(false)
    const [results, setResults] = React.useState([])
    const [list, setList] = React.useState([])
    const [list2, setList2] = React.useState([])
    const [fullList, setFullList] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [queries, setQueries] = React.useState([])


    // React.useEffect(() => {
    //     if (queryText) {
    //         setTimeout(() => {
    //             setQueries([queryText])
    //         }, 2000)
    //     }
    // }, [queryText])

    // React.useEffect(() => {
    //     // if (search.length === 0) {
    //     //     setResults([])
    //     //     return
    //     // }

    //     setLoading(true)
    //     _constitutions
    //         .search('', {
    //             limit: 50,
    //         })
    //         .then(({ hits }) => {
    //             setResults(hits)
    //         })
    //         .catch((err) => {
    //             console.error(err)
    //         })
    //         .finally(() => {
    //             setLoading(false)
    //         })
    // }, [])

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const resp = await axios.get(`${API_URL}/constitutions?populate=*&sort=id:asc`)
                const data = resp.data?.data || []
                if (data.length > 0) {
                    const items = []
                    data.forEach(item => {
                        const itemlist = item.attributes?.publications?.data
                        if (itemlist.length > 0) return;
                        items.push(item)
                    })
                    setFullList(items)
                    setList(items)
                }
            } catch {

            } finally {
                setLoading(false)
            }
        })()
    }, [])


    React.useEffect(() => {
        if (queries.length > 0) {
            const _list = fullList.filter(item => {
                return queries?.includes(item.attributes.constitutionType)
            })
            setList(_list)
        } else {
            setList(fullList)
        }

    }, [queries, fullList])

    React.useEffect(() => {
        if (search.length === 0) {
            setList(fullList)
            return
        }

        const _list = fullList.filter(item => item.attributes?.constName?.toLowerCase().includes(search.toLowerCase()))
        setList(_list)
    }, [search])

    React.useEffect(() => {
        if (queries.length > 0) {
            const _list = fullList.filter(item => {
                return queries.includes(item.attributes.constitutionType.toLowerCase())
            })
            setList(_list)
        } else {
            setList(fullList)
        }

    }, [queries, fullList])

    React.useEffect(() => {
        if (queryText) {
            const _queryText = queryText.split(' ')[0]
            setQueries([...queries, _queryText.toLowerCase()])
        }
    }, [queryText])

    const openPlansModal = () => {
        setOpenPlans(true)
    }

    console.log("List:", list);

    return (
        <div className='bg-bgGrey min-h-full flex flex-col justify-between'>
            <div className='pt-7 pb-20 container mx-auto'>
                {/* <PublicationsNav search={search} setSearch={setSearch} /> */}
                <UnAuthHeader3 openPlansModal={openPlansModal} />

                <div className='flex items-start w-full'>
                    <div className='pt-8 pr-2 w-[245px]'>
                        <div className='font-bold mb-3 text-lg'>Filter</div>
                        <SideMenu {...{ langs: list }} />
                    </div>
                    <div className='border-l-4 pt-8 pl-8 w-full'>
                        <div className='flex items-center justify-between mb-6 w-full'>
                            <div className='font-bold text-lg flex items-center'>
                                <IoArrowBack
                                    onClick={() => navigate('/')}
                                    className='inline-block mr-2 text-xl cursor-pointer' />
                                <span>Constitutions</span>
                            </div>
                            <div className='flex gap-2'>
                                <input
                                    className='h-9 rounded-lg border outline-1 border-gray-200 px-2 text-sm'
                                    placeholder='Search by Act or Cap...'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className='bg-bgBlue text-white h-9 px-4 rounded-lg'>Search</button>
                            </div>
                        </div>
                        <div className='grid grid-flow-row grid-cols-5 gap-4'>
                            {
                                list.map(({ attributes: { constThumnailURL, constName }, id }) => {
                                    return (
                                        <Link to={`/constitution/${id}`} title={constName}>
                                            <div className=''>
                                                <img src={constThumnailURL} alt={"Publication"} className={'h-60'} />
                                                <div className='flex justify-between items-center mt-2 pl-1'>
                                                    <span className='text-sm w-36 text-center' title={constName}>{constName}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        {
                            !loading && list.length === 0 &&
                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                No Constitutions Found
                            </div>
                        }
                        {
                            loading &&
                            <div className="text-neutral-800 text-xl font-light mt-2.5 max-md:max-w-full text-center">
                                Loading Constitutions...
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {openPlans && <Pricing closeModal={() => setOpenPlans(false)} />}
        </div>
    )
}

export default Constitution
