import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { toast } from 'react-toastify'
import Select from 'react-select'
import cloudinary from 'cloudinary-core';
import AWS from 'aws-sdk';
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from "@cloudinary/react";
import { useAuth } from '../../../Auth'
import { API_URL } from '../../../envs'
import AddPubForm from './AddPubForm'
import { getPublicationTypes } from '../../constants/forms'
import { Button, Modal, Spin } from 'antd'
import { s3Config } from '../../constants/configs'

const cld = new Cloudinary({
    cloud: {
        cloudName: 'dtk0zusjw'
    }
})




const AddPublicationModal = ({ closeModal, fetchAllData, open }) => {

    const [file, setFile] = useState(null)
    const [cover, setCover] = useState(null)
    const [cost, setCost] = useState('')
    const { userData } = useAuth()
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false)
    const [publication, setPublication] = useState(null)
    const [publicationType, setPublicationType] = useState('');
    const [publicationTypes, setPublicationTypes] = useState([]);

    useEffect(() => {
        if (!!publicationType) {
            setFormData({})
        }
    }, [publicationType])

    useEffect(() => {
        (async () => {
            const obj = await getPublicationTypes()
            setPublicationTypes(obj)
        })()
    }, [])

    useEffect(() => {
        setPublicationType(null)
        setFormData({})
    }, [open])
    

    const handleFileUpload = (event) => { setFile(event.target.files[0]) }
    const handleCoverUpload = (event) => { setCover(event.target.files[0]) }
    const handleCostChange = (event) => { setCost(event.target.value) }

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        setLoader(true);

        try {
            let coverUploadResponse = null;
            let fileUploadResponse = null;

            if (cover) {
                const formData = new FormData();
                formData.append('file', cover);
                formData.append('upload_preset', 'g8fw4v8y');

                coverUploadResponse = await axios.post(
                    `https://api.cloudinary.com/v1_1/dtk0zusjw/image/upload`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )

                console.log("coverUploadResponse INSIDE:", coverUploadResponse);
            };

            if (file) {
                fileUploadResponse = await s3Config.upload({
                    Bucket: 'ulrc-publications',
                    Key: file.name,
                    Body: file,
                    ContentType: file.type,
                }).promise();

                console.log("fileUploadResponse INSIDE:", fileUploadResponse)
            }

            let data
            const pubType = publicationTypes[publicationType]

            if (publicationType === 'lawsOfUganda') {
                data = {
                    ...formData,
                    [pubType?.fileName]: fileUploadResponse?.Location
                };
            } else {
                data = {
                    ...formData,
                    [pubType?.coverName]: coverUploadResponse?.data?.secure_url,
                    [pubType?.fileName]: fileUploadResponse?.Location
                };
            }

            console.log("DATA:", data)

            const response = await axios.post(`${API_URL}/${pubType.endpoint}`, { data }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });

            if (response) {
                closeModal();
                setFile(null);
                setCover(null);
                setCost('');
                setFormData({});
                toast.success('Publication has been successfully added', {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                fetchAllData()
            }

        } catch (error) {
            toast.error('There was a problem uploading this publication, please try again', {
                position: toast.POSITION.BOTTOM_CENTER
            })
        } finally {
            setLoader(false);
        }
    }

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }


    return (        
        <Modal
            open={open}
            onCancel={closeModal}
            title="Add Publication"
            footer={[]}
        >
            <Spin spinning={loader} tip="Adding Publication...">
                <>                   
                    <AddPubForm
                        onSubmit={handleSubmit2}
                        closeModal={closeModal}
                        loader={loader}
                        formData={formData}
                        handleFileUpload={handleFileUpload}
                        handleCoverUpload={handleCoverUpload}
                        publicationType={publicationType}
                        setPublicationType={setPublicationType}
                        handleFormChange={handleFormChange}
                        handleSelectChange={handleSelectChange}
                    />
                </>
            </Spin>
        </Modal>
    )
}

export default AddPublicationModal