import React, { useState, useRef, useEffect } from 'react';
import { Document, Outline, Page, pdfjs } from 'react-pdf';
import { Progress } from 'antd';
import Highlighter from 'react-highlight-words';
import { docPass } from '../envs';

const Loading = ({ loadPercent }) => {
    return (
        <div className='flex items-center justify-center h-full'>
            <div className='flex flex-col items-center'>
                <div className='text-lg font-bold text-slate-400 mb-3'>Please Wait ...</div>
                <Progress type="circle" percent={loadPercent} />
            </div>
        </div>
    )
}


const ErrorMessage = () => (
    <div className='flex items-center justify-center h-[30vh]'>
        <div className='flex flex-col items-center'>
            <div className='text-lg font-bold text-red-500 mb-3'>Error Loading Document</div>
            <div className='text-sm text-red-500 text-center'>
                <span className="text-center">Please check your internet connection and try again.</span>
                <br />
                <span className="text-center">If you have a a downloader extension enabled, please disable it</span>
                <br />
                <span className="text-center">If this issue persist please contact support.</span>
            </div>
        </div>
    </div>
)


const MyDocument = ({ file, setDocReady, scale, searchString, searchResults }) => {
    const [numPages, setNumPages] = useState(null);
    const [outlines, setOutlines] = useState([])
    const [loadPercent, setLoadPercent] = useState(0)
    const [selectedPage, setSelectedPage] = useState(1);
    const pageRefs = useRef([]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setDocReady(true)
        pageRefs.current = new Array(numPages).fill().map((_, i) => React.createRef());
    };

    useEffect(() => {
        if (pageRefs.current[selectedPage - 1]) {
            pageRefs.current[selectedPage - 1].current.scrollIntoView();
        }
    }, [selectedPage]);

    return (
        <Document
            file={file}
            onPassword={callback => callback(docPass)}
            onLoadSuccess={onDocumentLoadSuccess}
            error={<ErrorMessage />}
            onLoadProgress={({ loaded, total }) => {
                setLoadPercent(Math.round((loaded / total) * 100))
            }}
            loading={<Loading loadPercent={loadPercent} />}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'auto'
            }}>
                <div style={{
                    width: '25%',
                    borderRight: '1px solid #eee',
                }}>
                    {
                        searchString.length === 0 ? <>
                            <Outline onLoadSuccess={(data) => setOutlines(data)} />
                            {!outlines && <div className='pl-3 text-slate-600'>No outline available</div>}
                        </> :
                            <div className='w-full h-screen bg-white overflow-auto pb-10'>
                                {
                                    searchResults.length === 0 &&
                                    <div className='pl-3 text-slate-600'>No results found</div>
                                }
                                {
                                    searchResults.map((result, index) => (
                                        <div
                                            key={index}
                                            className='px-2 py-2 border-b border-gray-100 text-sm cursor-pointer'
                                            onClick={() => {
                                                setSelectedPage(result.pageNumber);
                                                // pageRefs.current[result.pageNumber]?.scrollIntoView()
                                            }}
                                        >
                                            <div className='mb-1 flex justify-between items-center'>
                                                <span
                                                    className='text-slate-400 font-bold text-xs'
                                                >Page {result.pageNumber}</span>
                                                <span className='text-slate-400 text-xs'>{result.matches} matches</span>
                                            </div>
                                            {/* <div>{result.text}</div> */}
                                            <Highlighter
                                                highlightClassName="highlightText"
                                                searchWords={[searchString]}
                                                autoEscape={true}
                                                textToHighlight={result.text}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                    }
                </div>

                <div style={{ height: '100vh', overflow: 'auto', width: "75%", background: 'lightgrey' }}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <div ref={pageRefs.current[index]} style={{ minHeight: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={scale}
                                className='my-2 shadow-lg'
                            >
                            </Page>
                        </div>
                    ))}
                </div>
            </div>
        </Document>
    );
};

export default MyDocument;
