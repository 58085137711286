import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import logo2 from '../ulrc_logo.png';

const choices = [
    'I am a Customer',
    'I am an Editor',
    'I am a Content Creator',
    'I am a Reviewer',
    'I am a Marketing Manager',
    'I am a Secretary',
    'I am a Chairman',
    'I am Attorney General'
]

function ChooseUserType() {
    const [selected, setSelected] = useState(0)
    const { userData } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        /// Temp work around for route protection
        if (userData) navigate("/dashboard")
    }, [userData])

    useEffect(() => {
        document.body.style = 'background: rgb(245 246 250);';
    }, [])

    const renderChoices = () => choices.map((choice, index) => {
        const isSelected = index === selected
        const selectedStyle = isSelected ? 'border-brightRed' : 'border-slate-200'
        return (
            <div
                onClick={() => setSelected(index)}
                className={`relative h-40 w-40 bg-white border  rounded-xl mb-4 mr-4 flex items-center justify-center cursor-pointer ${selectedStyle}`}
            >
                <div className={`w-5 h-5 rounded-full border  flex items-center justify-center absolute top-2 right-2 ${selectedStyle}`}>
                    {isSelected && <div className='w-3 h-3 rounded-full bg-brightRed' />}
                </div>
                <div className='w-28 text-center text-xl'>{choice}</div>
            </div>
        )
    })

    return (
        <div className='h-full bg-bgGrey pt-7'>
            <div className='bg-white py-5 pl-20'>
                <Link to={'/'}><img src={logo2} alt="logo" className='w-32' /></Link>
            </div>
            <div className='flex justify-center items-center flex-col mt-28'>
                <div className='text-5xl text-center font-serif'>Choose Your Role</div>
                <div className='mt-10 flex flex-wrap justify-center'>
                    {renderChoices()}
                </div>
                <form className='mt-10'>
                    <a href='/signin'>
                        <button type='button' className='h-10 w-96 rounded-full bg-brightRed text-white'>Continue to login</button>
                    </a>
                </form>               
            </div>
        </div>
    )
}

export default ChooseUserType;
