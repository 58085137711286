import React, { useEffect } from 'react'
import logo2 from '../ulrc_logo.png';
import court from '../image_ug.png';
import { useNavigate, useParams } from 'react-router-dom';
import { _index } from './searchClient';
import { toast } from 'react-toastify';
import { act } from 'react-dom/test-utils';

function TreatyDesc() {

    const [treaty, setTreaty] = React.useState({});
    const [activeTab, setActiveTab] = React.useState(0);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        _index
            .search('')
            .then((res) => {
                console.log("ID:", id);
                const treaty = res.hits?.find((treaty) => treaty.treatyNo === Number(id));
                console.log("Treaty:", treaty);
                setTreaty(treaty);
            })
            .catch((err) => console.log(err));

    }, [])

    const formattedDate = new Date(treaty?.updatedAt)?.toLocaleString('en-US', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });

    const printDoc = () => {
        window.open(treaty?.docURL, '_blank');
        // printWindow.print();
    }

    const downloadDoc = () => {
        // const docUrl = treaty?.documentFile;
        // const fileName = treaty?.treatyTitle;
        // const link = document.createElement('a');

        // link.href = docUrl;
        // link.download = fileName;
        // link.click();

        window.open(treaty?.docURL, '_blank');
    }

    const shareDoc = () => {
        const link = window.location.href;
        navigator.clipboard.writeText(link);
        toast.info("Treaty link copied to clipboard", {
            position: "bottom-center",
            hideProgressBar: true,
            theme: "dark",
        });
    }

    console.log(treaty)

    return (
        <div>
            <div className='pt-7 container mx-auto'>
                <div className='py-5 flex items-center justify-between border-b-2'>
                    <div className='flex space-x-7 items-center'>
                        <img src={logo2} alt="logo" className='w-40' />
                        <img src={court} alt="logo" className='w-16' />
                    </div>
                    <div>
                        <button
                            className="text-white text-base leading-[150%] self-stretch justify-end items-center w-[97px] max-w-full px-5 py-2 rounded-md"
                            onClick={() => navigate(-1)}
                            style={{
                                background: '#272969',
                            }}
                        >Back</button>
                    </div>
                </div>
            </div>

            <section className="px-5 container mx-auto">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[23%] max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9cd7cd5c-eabd-4979-9e8d-d642e17b662c?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-[0.72] object-cover object-center w-[244px] overflow-hidden max-w-full mt-4 max-md:mt-10"
                            alt="Treaty Image"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[77%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col max-md:max-w-full max-md:mt-7">
                            <h1 className="text-neutral-800 text-ellipsis  text-4xl  mt-7 max-md:max-w-full max-md:text-4xl">
                                {treaty?.treatyTitle || "N/A"}
                            </h1>

                                <div className="flex items-center gap-10 mt-9 relative">
                                    <div
                                        className="border-b-2 absolute bottom-0 border-slate-100 w-full"
                                    />
                                    <button
                                        className={`z-10 px-8 p-1 text-slate-400 ${activeTab === 0 ? 'border-b-2 border-blue-800 text-blue-800' : ''}`}
                                        onClick={() => setActiveTab(0)}
                                    >
                                        Treaty
                                    </button>                                    
                                    <button
                                        className={`z-10 px-8 p-1 text-slate-400 ${activeTab === 1 ? 'border-b-2 border-blue-800 text-blue-800' : ''}`}                                       
                                        onClick={() => setActiveTab(1)}
                                    >
                                        Domestic Legislation
                                    </button>
                                    <button
                                        className={`z-10 px-8 p-1 text-slate-400 ${activeTab === 2 ? 'border-b-2 border-blue-800 text-blue-800' : ''}`}                                        
                                        onClick={() => setActiveTab(2)}
                                    >
                                        Institutional Reforms
                                    </button>
                                </div>

                                {activeTab === 0 &&
                                    <>
                                        <table className="table-auto mt-7 ml-3.5 max-md:max-w-full">
                                            <tbody>
                                                <tr className="bg-gray-100">
                                                    <td className="p-2 font-bold text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        Treaty Category
                                                    </td>
                                                    <td className="p-2 text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        {treaty?.docType || "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2 font-bold text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        Treaty Number
                                                    </td>
                                                    <td className="p-2 text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        {treaty?.treatyNo || "N/A"}
                                                    </td>
                                                </tr>
                                                <tr className="bg-gray-100">
                                                    <td className="p-2 font-bold text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        Treaty Subject
                                                    </td>
                                                    <td className="p-2 text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        {treaty?.treatySubject || "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2 font-bold text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        Parties Involved
                                                    </td>
                                                    <td className="p-2 text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        {treaty?.partiesInvoved || "N/A"}
                                                    </td>
                                                </tr>
                                                <tr className="bg-gray-100">
                                                    <td className="p-2 font-bold text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        Ractification Date
                                                    </td>
                                                    <td className="p-2 text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        {treaty?.ratificationDate || "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2 font-bold text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        Place of Adoption
                                                    </td>
                                                    <td className="p-2 text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        {treaty?.placeOfAdoption || "N/A"}
                                                    </td>
                                                </tr>
                                                <tr className="bg-gray-100">
                                                    <td className="p-2 font-bold text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        Administrative Body
                                                    </td>
                                                    <td className="p-2 text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5">
                                                        {treaty?.region || "N/A"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p className="text-blue-800 font-semibold text-xl leading-[150%] mt-5 ml-4">
                                            Disclaimer: This information has been compiled by ULRC as of {formattedDate}
                                        </p>

                                        <p className="text-neutral-800 text-base leading-[175%] w-[782px] max-w-full ml-3.5 mt-5">
                                            On the 8th of October 1995, Uganda adopted the current Constitution as the supreme law of the land. <br /> Accordingly, article 1(1) and the National Objectives and Directive Principles of State Policy provide that it is the duty of every citizen to acquaint himself or herself with the provisions of the Constitution in order to uphold and defend it. <br /> Don’t take photos of military, official or diplomatic sites, including Owen Falls Dam at the source of the Nile near Jinja. If you are taking photographs of people, ask their permission first. <br /> <br /> It is an offence for anyone, including children, to dress in military style clothing. This includes clothing with marks, insignia and accessories associated with the Ugandan military, camouflage clothing and red berets. The offence carries a maximum sentence of life imprisonment when convicted. <br />
                                        </p>

                                        <a
                                            href="https://docstore.ohchr.org/SelfServices/FilesHandler.ashx?enc=6QkG1d%2FPPRiCAqhKb7yhssZJArfELMVv25mPPQ8yyVTOEIJQSbV7GRBoDiSg1RAKuKJncabN8aBczJDxxuSr2uqy4yNNcqccbxRs7EUX6zTixt0ZjLrXu0YyI9XCW%2FUU"
                                            target="_blank"
                                            className="text-blue-800 text-base mt-5 ml-4 underline"
                                        >
                                            Uganda/CSO status reports
                                        </a>

                                        <div className="flex items-center justify-end mt-7">
                                            <button
                                                className="text-white text-base leading-[150%] self-stretch justify-end items-center bg-blue-800 w-[120px] max-w-full px-5 py-2 rounded-2xl ml-2"
                                                onClick={shareDoc}
                                            >
                                                Share
                                            </button>
                                            <button
                                                className="text-white text-base leading-[150%] self-stretch justify-end items-center bg-blue-800 w-[120px] max-w-full px-5 py-2 rounded-2xl ml-2"
                                                onClick={printDoc}
                                            >
                                                Print
                                            </button>
                                            <button
                                                className="text-white text-base leading-[150%] self-stretch justify-end items-center bg-blue-800 w-[120px] max-w-full px-5 py-2 rounded-2xl ml-2"
                                                onClick={downloadDoc}
                                            >
                                                Download
                                            </button>
                                        </div>
                                    </>
                                }                                

                                {
                                    activeTab === 1 &&
                                        <ul className="list-disc list-inside mt-7 ml-3.5 max-md:max-w-full">
                                            {treaty?.domestic_legislations?.map((legislation, index) => (
                                                <li key={index} className="text-blue-800 text-base mt-2">
                                                    <a href={legislation.Links || "#"} className="underline" rel="noreferrer" target='_blank'>
                                                        {legislation.legislation}
                                                    </a>
                                                </li>
                                            ))}   
                                            {
                                                treaty?.domestic_legislations.length === 0 && (
                                                    <li className="text-base mt-2">
                                                        No domestic legislation found
                                                    </li>
                                                )
                                            } 
                                        </ul>
                                }

                                {                                    
                                    activeTab === 2 &&
                                        <ul className="list-disc list-inside mt-7 ml-3.5 max-md:max-w-full">
                                            {treaty?.institutional_reforms?.map((reform, index) => (
                                                <li key={index} className="text-blue-800 text-base mt-2">
                                                    <a href={reform.Links || "#"} className="underline" rel="noreferrer" target='_blank'>
                                                        {reform.reforms}
                                                    </a>
                                                </li>
                                            ))}    
                                            {
                                                treaty?.institutional_reforms.length === 0 && (
                                                    <li className="text-base mt-2">
                                                        No institutional reforms found
                                                    </li>
                                                )
                                            }
                                        </ul>
                                }


                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <footer className=" bg-bgBlue self-stretch flex flex-col mt-40 pt-14 pb-20 px-5 max-md:max-w-full max-md:mt-10">
                <hr className="bg-zinc-400 bg-opacity-60 self-center w-[1236px] h-[1px] max-md:max-w-full" />
                <div className="self-center flex w-full max-w-[1243px] items-start justify-between gap-5 mt-7 max-md:max-w-full max-md:flex-wrap">
                    <div className="text-white text-base leading-[150%] opacity-80 max-w-[346px] self-stretch flex-grow shrink-0 basis-auto">
                        Copyright, Align 2023. All rights reserved.
                    </div>
                    <div className="self-stretch flex w-[537px] max-w-full items-start justify-between gap-5 max-md:flex-wrap max-md:justify-center">
                        <a href="#" className="text-slate-100 text-base leading-[150%]">
                            Partners
                        </a>
                        <a href="#" className="text-slate-100 text-base leading-[150%]">
                            About Treaty DB
                        </a>
                        <a href="#" className="text-slate-100 text-base leading-[150%]">
                            Glossary
                        </a>
                        <a href="#" className="text-slate-100 text-base leading-[150%]">
                            Blog
                        </a>
                        <a href="#" className="text-slate-100 text-base leading-[150%]">
                            Terms of Use
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default TreatyDesc