import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { toast } from 'react-toastify';
import { useAuth } from '../Auth';
import logo2 from '../ulrc_logo.png';
import { AuthHeader2 } from './UnAuthHeader';
import Footer from './Footer';
import axios from 'axios';
import { API_URL } from '../envs';


function ForgotPassword() {
    const [email, setEmail] = useState(null)
    const [loading, setLoading] = useState(false)
    const { userData } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        /// Temp work around for route protection
        if (userData) navigate("/")
    }, [userData])


    const findAccount = async (e) => {
        e.preventDefault()
        if (!email) {
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error("Invalid email address", {
            position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        try {
            setLoading(true)
            const query = qs.stringify({
                filters: {
                    email: {
                        '$eq': email
                    }
                }
            }, {
                encodeValuesOnly: true,
            });

            const response = await axios.get(`${API_URL}/users?${query}`);
            const emailData = response.data
            if (emailData.length === 0) {
                toast.error("No account found with this email", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            } else {
                const user = emailData[0]
                const { email, id } = user
                await axios.post(`${API_URL}/auth/forgot-password`, {
                    email
                })
                toast.success("A password reset email has been sent to your account", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            }      
        } catch (error) {
            console.log("ERROR:", error)
            toast.error("There was a problem...", {
                position: toast.POSITION.BOTTOM_CENTER
            })
        } finally {
            setLoading(false)
        }
    }

    console.log("EMAIL:", email)

    return (
        <div className='h-screen bg-bgGrey pt-2 flex flex-col justify-between'>
            <div className='container mx-auto'>
                <AuthHeader2 />
            </div>
            <div className='flex justify-center items-center flex-col mt-10 mb-28'>
                <div className='bg-white pt-20 pb-16 px-12 rounded-3xl'>
                    <div className='text-5xl font-serif'>Find Your Account</div>
                    <div className='text-md w-96 text-slate-600 mt-8'>Please enter your email address or mobile number to search for your account.</div>
                    <form className='mt-10'>
                        <div className='mb-8'>
                            <input
                                onChange={e => setEmail(e.target.value)}
                                className='h-10 pl-2 w-96 rounded-lg outline-none border border-brightRedlite' placeholder='Email Address or Phone Number' />
                        </div>
                        <button
                            onClick={findAccount}
                            disabled={loading}
                            className='h-10 w-96 rounded-lg bg-brightRed text-white'>
                            {!loading ? "Search" : "Please wait..."}
                        </button>
                    </form>
                    <div className='mt-4 text-center'>
                        Go back to <Link to='/signin' className='text-brightRed'>login</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword
