import React, { useState, useEffect } from 'react'
import { useAuth } from '../Auth';
import axios from 'axios';
import { API_URL } from '../envs';
import { Spin, Table } from 'antd'
import { toast } from 'react-toastify';
import qs from 'qs';
import { getSubscription } from './Signup';

const inviteUrl = process.env.NODE_ENV === 'production' ? 'https://govnet-api-proxy.vercel.app/invite' : 'http://localhost:3003/invite';

const InviteUserDialog = ({ closeModal, fetchUsers, fetchInvitees }) => {
    const [loader, setLoader] = useState(false)

    const { userData } = useAuth()

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const obj = Object.fromEntries(data)

        try {
            setLoader(true)
            const query = qs.stringify({
                filters: {
                    email: {
                        '$eq': obj?.email
                    }
                }
            }, {
                encodeValuesOnly: true,
            });

            const resp = await axios.get(`${API_URL}/users?${query}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });

            const user = resp.data[0];
            let redirectUrl = 'https://publications.ulrc.go.ug/signin'
            if (user) {
                await axios.put(`${API_URL}/users/${user.id}`, {
                    subscribedTo: `${userData?.user?.id}`,
                }, {
                    headers: {
                        Authorization: `Bearer ${userData?.jwt}`,
                    },
                });
                getSubscription(
                    { email: userData?.user?.email },
                    obj?.email
                )
            } else {
                redirectUrl = 'https://publications.ulrc.go.ug/signup'

                await axios.post(`${API_URL}/invitations`, {
                    data: {
                        InviterID: `${userData?.user?.id}`,
                        InviteeEmail: obj?.email
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${userData?.jwt}`,
                    },
                });
            }

            await axios.post(inviteUrl, {
                userData: userData?.user,
                email: obj?.email,
                redirectUrl
            });

        } catch (error) {
            console.error(error)
        } finally {
            setLoader(false)
            await fetchUsers()
            await fetchInvitees()
            closeModal()
            toast.success('User Invitation sent Successfully')
        }

    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-slate-900/75 flex justify-center items-center">
            <form
                className="bg-white rounded-lg w-[500px] max-w-full max-h-full overflow-y-auto"
                onSubmit={handleSubmit}
            >
                <div className="flex justify-between items-center px-5 py-4 border-b border-[color:var(--lighter-grey-strokes,#ECEDED)]">
                    <div className="text-neutral-800 text-lg font-semibold">
                        Invite New User
                    </div>
                    <div
                        className="text-neutral-800 text-lg font-semibold cursor-pointer"
                        onClick={closeModal}
                    >
                        X
                    </div>

                </div>
                <div className="px-5 py-4">
                    <div className="text-neutral-800 text-sm leading-5 mb-2">
                        Email
                    </div>
                    <input
                        type="email"
                        name='email'
                        placeholder='Enter User Email'
                        title="Please enter a valid email address"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        className="border border-[color:var(--lighter-grey-strokes,#ECEDED)] rounded-lg w-full px-3 py-2 text-sm leading-5"
                    />
                </div>
                <div className="px-5 py-4 flex justify-end">
                    <button
                        disabled={loader}
                        className="bg-bgBlue text-white py-2 px-5 rounded-lg"
                    >
                        {loader ? "Wait..." : "Invite"}
                    </button>
                </div>
            </form>
        </div>
    )
}

// id, name, email, confirmed
const columns = [
    {
        title: 'ID',
        dataIndex: 'id'
    },
    {
        title: 'Name',
        dataIndex: 'name'
    },
    {
        title: 'Email',
        dataIndex: 'email'
    },
    {
        title: 'Confirmed',
        dataIndex: 'confirmed',
    }
]

function UserManagement() {

    const [showInviteUserDialog, setShowInviteUserDialog] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [disableAdd, setDisableAdd] = React.useState(false)
    const [invitees, setInvitees] = React.useState([])
    const { userData, subscriptionData } = useAuth()

    React.useEffect(() => {
        fetchUsers();
        fetchInvitees();
    }, [])

    const fetchInvitees = async () => {
        try {
            setLoading(true)
            const query = qs.stringify({
                filters: {
                    InviterID: {
                        '$eq': userData?.user?.id
                    }
                }
            }, {
                encodeValuesOnly: true,
            });
            const response = await axios.get(`${API_URL}/invitations?${query}&pagination[start]=0&pagination[limit]=60`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            const data = response?.data?.data || []
            const formattedData = data?.map(item => ({ ...item?.attributes, id: item?.id })).filter((obj, index, self) =>
                self.findIndex((o) => o.InviteeEmail === obj.InviteeEmail) === index
            )
            setInvitees(formattedData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const fetchUsers = async () => {
        try {
            setLoading(true)
            const query = qs.stringify({
                filters: {
                    subscribedTo: {
                        '$eq': userData?.user?.id
                    }
                }
            }, {
                encodeValuesOnly: true,
            });
            const response = await axios.get(`${API_URL}/users?${query}&pagination[start]=0&pagination[limit]=60`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            const _users = await response.data;
            const filteredUsers = _users?.map(user => {
                return {
                    ...user,
                    name: user.username,
                    confirmed: `${user.confirmed}`,
                }
            });
            setUsers(filteredUsers);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (invitees.length > 0) {
            const newUsers = invitees.filter(invitee => !users.some(user => user.email === invitee.InviteeEmail));
            const _newUsers = newUsers.map(item => ({
                name: "N/A",
                email: item?.InviteeEmail,
                confirmed: false,
                id: item?.id
            }))
            setUsers(prevUsers => [...prevUsers, ..._newUsers]);
        }
    }, [invitees])

    useEffect(() => {
        const str = subscriptionData?.numberOfUsers || "0"
        const num = str.match(/\d+/)[0];
        const numericValue = parseInt(num, 10);

        if (users.length >= (numericValue - 1)) {
            setDisableAdd(true);
        } else {
            setDisableAdd(false);
        }

    }, [subscriptionData, users])



    return (
        <div className="mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <div className="flex justify-between mb-3">
                <h1 className="text-neutral-800 text-lg font-semibold -mr-5 max-md:max-w-full mb-5">
                    Subscription Management
                </h1>
                <div>
                    <span className='font-bold'>{users.length + 1}</span> / <span>{subscriptionData?.numberOfUsers || 0}</span>
                </div>
            </div>

            <Spin spinning={loading} tip="Loading Users...">
                <Table
                    // rowSelection={{
                    //     type: 'checkbox',
                    // }}
                    columns={columns}
                    dataSource={users}
                />
            </Spin>

            {!disableAdd && <div className="flex justify-end">
                <button
                    className="bg-bgBlue text-white py-2 px-5 mt-6 rounded"
                    onClick={() => setShowInviteUserDialog(true)}
                >Add New User</button>
            </div>}
            {showInviteUserDialog && <InviteUserDialog closeModal={() => setShowInviteUserDialog(false)} fetchUsers={fetchUsers} fetchInvitees={fetchInvitees} />}
        </div >
    )
}

export default UserManagement;
