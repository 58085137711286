import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import axios from 'axios';
import { FiRefreshCcw } from "react-icons/fi";
import { useAuth } from '../Auth';
import { API_URL, BASE_URL } from '../envs';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id'
    },
    {
        title: 'PRN',
        dataIndex: 'prn'
    },
    {
        title: 'Amount',
        dataIndex: 'prnAmount'
    },
    {
        title: 'Status Code',
        dataIndex: 'StatusCode'
    },
    {
        title: 'Status Description',
        dataIndex: 'StatusDesc'
    },
    {
        title: 'Expiry',
        dataIndex: 'PaymentExpiryDate'
    },
    {
        title: 'User Name',
        dataIndex: 'userName',
    },
    {
        title: 'Phone',
        dataIndex: 'phoneNumber',
    }
]

const Prns = () => {

    const [prns, setPrns] = useState([])
    const [loading, setLoading] = useState(false)
    const { userData } = useAuth()

    useEffect(() => {
        fetchPrns();
    }, [userData])

    const fetchPrns = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${API_URL}/prns`, {
                params: {
                    sort: 'createdAt:desc',
                    pagination: {
                        page: 0,
                        pageSize: 25
                    }
                },
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            const _prns = await response.data?.data;
            const formattedPrns = _prns.map(prn => {
                return {
                    id: prn.id,
                    ...prn.attributes
                }
            })
            // setPrns(formattedPrns)

            try {
                const prnStatuses = await Promise.allSettled(formattedPrns.map(prn => {
                    return axios.post(`${BASE_URL}/prn_status`, {
                        "prn": prn.prn
                    })
                }));
                const formattedPrnStatuses = prnStatuses.map(prnStatus => {
                    return prnStatus?.value?.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult
                });

                const mergedArray = formattedPrns.map((prn) => {
                    const index = formattedPrnStatuses.findIndex(prnStatus => `${prnStatus.PRN}` === prn.prn);
                    const prnStatus = formattedPrnStatuses[index];

                    return {
                        ...prn,
                        ...prnStatus
                    }
                });

                setPrns(mergedArray)
            } catch (error) {
                console.log("ERROR FETCHING PRN STATUSES:", error)
                setPrns(formattedPrns)
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <div className="flex justify-between mb-3">
                <h1 className="text-neutral-800 text-lg font-semibold -mr-5 max-md:max-w-full">
                    PRNs
                </h1>
                <button
                    disabled={loading}
                    className="py-1 px-2 rounded flex items-center gap-2 border border-slate-300"
                    onClick={() => fetchPrns()}
                >
                    <FiRefreshCcw />
                    <span className='text-base'>Refresh</span>
                </button>
            </div>

            <Spin spinning={loading} tip="Loading PRNs...">
                <Table
                    columns={columns}
                    dataSource={prns}
                />
            </Spin>
        </div>
    );

};

export default Prns;
