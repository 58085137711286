import React from 'react'
import DashboardStats from './DashboardStats'

function Analytics() {
    return (
        <div className="flex flex-col items-stretch mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <h1 className="text-neutral-800 text-lg font-semibold leading-8 -mr-5 max-md:max-w-full">
                Analytics
            </h1>
            <DashboardStats />
        </div>
    )
}

export default Analytics
