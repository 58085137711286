import React from 'react';
import { Layout, Typography, Button, Alert } from 'antd';

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

function MaintenancePage() {
  return (
    <div className="h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="max-w-md p-4 bg-white rounded shadow-md">
        <Header className="border-b border-gray-200">
          {/* <Title level={2} color='#fff' className="font-bold text-white">System Maintenance</Title> */}
          <h2 className="font-bold text-2xl text-white">System Maintenance</h2>
        </Header>
        <Content className="p-4">
          <Text className="text-gray-600">
            Our system is currently undergoing maintenance. We apologize for any inconvenience this may cause.
          </Text>
          <Alert
            message="Maintenance Notice"
            description="Our system will be back online soon. Thank you for your patience."
            type="info"
            className="mt-4"
          />
          <Button type="primary" size="large" className="mt-4">
            Check Status
          </Button>
        </Content>
        <Footer className="border-t border-gray-200 p-4 text-gray-600 text-sm">
          <Text>
            Copyright 2024. All rights reserved.
          </Text>
        </Footer>
      </div>
    </div>
  );
}

export default MaintenancePage;