import React, { useState } from 'react'
import { BiCart } from "react-icons/bi";
import { AiOutlineHeart, AiOutlineWallet } from "react-icons/ai"
// import { PublicationsNav } from '.'
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import Footer from '../Footer';
import Pricing from '../Pricing';
import { PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import { _index } from '../searchClient';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PdfPreview from '../Pdf';
import axios from 'axios';
import { ACCESS_TYPES, API_URL, imagePlaceholder } from '../../envs';
import { IoArrowBack } from 'react-icons/io5';
import { useAuth } from '../../Auth';

const ReviewPublication = () => {

    const [pricingModal, setPricingModal] = useState(false)
    const [publication, setPublication] = useState(null)
    const [suggestions, setSuggestions] = React.useState([])
    const [preview, setPreview] = React.useState(false);
    const { userData, subscriptionData } = useAuth()
    // const [openPlans, setOpenPlans] = React.useState(false)
    const navigate = useNavigate()
    const { id } = useParams();

    // React.useEffect(() => {
    //     _index
    //         .search('')
    //         .then((res) => {
    //             const info = res.hits?.find((_publication) => _publication.id === Number(id));
    //             console.log("res.hits:", res.hits)
    //             setPublication(info);
    //         })
    //         .catch((err) => console.log(err));
    // }, [])

    React.useEffect(() => {
        (async () => {
            const response = await axios.get(`${API_URL}/publications/${id}`);
            const data = response.data.data;
            console.log("data:", data)
            setPublication({ id: data.id, ...data.attributes });
        })()
    }, [id])

    React.useEffect(() => {

        if (publication?.publicationType) {
            _index
                .search(publication?.publicationType || '', {
                    limit: 6,
                    // filter: `treatySubject = "${treaty?.treatySubject}" OR docType = "${treaty?.docType}" OR region = "${treaty?.region}" OR partiesInvoved = "${treaty?.partiesInvoved}" OR placeOfAdoption = "${treaty?.placeOfAdoption}" OR placeOfAdoption = "${treaty?.placeOfAdoption}"`
                })
                .then(({ hits }) => {
                    const newHits = hits.filter((hit) => (hit.id !== publication?.id) && (!!hit?.publicationName));
                    setSuggestions(newHits);
                })
                .catch((err) => {
                    console.error(err)
                })
        }
    }, [publication])

    const continueReading = () => {
        // const paymentsuccess = localStorage.getItem("paymentsuccess")
        // const userData = JSON.parse(localStorage.getItem("user"))
        // console.log("paymentsuccess, userData:", paymentsuccess, userData)
        if (publication?.publlicationAccess === ACCESS_TYPES.FREE || publication?.publlicationAccess === ACCESS_TYPES.FREE_VIEW || (subscriptionData && userData)) {
            setPreview(true)
        } else {
            setPricingModal(true)
            localStorage.setItem("publicationLink", `/all-publications/${id}`)
        }
    }

    const openPlansModal = () => {
        setPricingModal(true)
    }

    return (
        <div className='bg-bgGrey relative'>
            <div className='pt-7 pb-20 container mx-auto'>
                {/* <PublicationsNav /> */}
                <UnAuthHeader3 openPlansModal={openPlansModal} />
                <div className='flex mt-9 space-x-10 justify-center'>
                    <div>
                        <img src={publication?.pubThumbnailURL || imagePlaceholder} className='w-56' alt={'Publication'} />
                    </div>
                    <div className='w-3/4'>
                        <div className='w-3/4'>
                            <div className='mb-2 flex justify-between items-center'>
                                <div className='text-4xl flex'>
                                    <IoArrowBack
                                        className='inline-block mr-2 cursor-pointer text-2xl mt-2'
                                        onClick={() => navigate('/all-publications')}
                                    />
                                    <div className='w-[99%]'>{publication?.publicationName}</div>
                                </div>
                            </div>
                            <div className="items-stretch border border-[color:var(--lighter-grey-strokes,#ECEDED)] flex flex-col rounded-xl border-solid mt-5">
                                <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                            Access Type
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.publlicationAccess || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-center items-stretch bg-slate-100 flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                            Commencement
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.entryDate || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                            Publication Type
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.publicationType || 'N/A'}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='mt-4 mb-8'>
                                {publication?.publicationDescription || 'N/A'}
                            </div>
                            <div
                                className="text-white bg-bgBlue text-center text-base leading-6 whitespace-nowrap justify-center items-center border border-[color:var(--Grey-Mid,#999)] w-[707px] max-w-full mr-3 mt-8 px-5 py-4 rounded-2xl border-solid self-end max-md:mr-2.5 cursor-pointer"
                                onClick={continueReading}
                            >
                                Access
                            </div>
                            {/* <div className="flex w-[707px] max-w-full items-stretch justify-between gap-5 mr-3 mt-4 self-end max-md:flex-wrap max-md:mr-2.5">
                                <div className="text-white text-center text-base leading-6 whitespace-nowrap justify-center items-center bg-bgBlue grow px-5 py-4 rounded-2xl">
                                    Buy a copy
                                </div>
                                <div className="text-violet-950 text-center text-base leading-6 whitespace-nowrap justify-center items-center border border-[color:var(--Secondary-Colour,#272969)] grow px-5 py-4 rounded-2xl border-solid">
                                    Read online
                                </div>
                            </div> */}
                            {/* <h1>PREVIEW DOC</h1>
                            <PdfBookmarks /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white self-stretch flex w-full flex-col items-center mt-14 px-5 py-12 max-md:max-w-full max-md:mt-10">
                <div className="flex w-full max-w-[1128px] flex-col items-center mt-16 mb-8 max-md:max-w-full max-md:mt-10">
                    <div className="overflow-hidden text-neutral-800 text-ellipsis text-5xl leading-[54px] w-[508px] max-w-full ml-12 max-md:text-4xl max-md:leading-[50px]">
                        Similar Publications
                    </div>
                    <div className="self-stretch flex items-stretch justify-between gap-5 mt-14 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mt-10">
                        {/* <div className="items-center flex grow basis-[0%] flex-col">
                            <img
                                loading="lazy"
                                // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b6732d8-98af-441c-9004-c5aeb154ab31?apiKey=345ac80390ed4496b041ffff961f4164&"
                                src={}
                                className="aspect-[0.65] object-contain object-center w-[180px] overflow-hidden"
                            />
                            <div className="text-neutral-800 text-ellipsis truncate text-base text-center mt-3 w-44">
                                Criminal Law in Uganda
                            </div>
                        </div>
                        <div className="items-center flex grow basis-[0%] flex-col">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe2c742c-f0ee-4a31-8e5f-f7bc0826cf7c?apiKey=345ac80390ed4496b041ffff961f4164&"
                                className="aspect-[0.65] object-contain object-center w-[180px] overflow-hidden"
                            />
                            <div className="text-neutral-800 text-ellipsis truncate text-center text-base mt-3 w-44">
                                Revenue Law in Uganda
                            </div>
                        </div>
                        <div className="items-center flex grow basis-[0%] flex-col">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6c48299-459e-40a4-b5a7-c8dbf8b76313?apiKey=345ac80390ed4496b041ffff961f4164&"
                                className="aspect-[0.65] object-contain object-center w-[180px] overflow-hidden"
                            />
                            <div className="text-neutral-800 text-ellipsis truncate text-center text-base mt-3 w-44">
                                Law of Contract, Uganda
                            </div>
                        </div>
                        <div className="items-center flex grow basis-[0%] flex-col">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1ab97ac-867f-431f-964f-08a51e476585?apiKey=345ac80390ed4496b041ffff961f4164&"
                                className="aspect-[0.65] object-contain object-center w-[180px] overflow-hidden"
                            />
                            <div className="text-neutral-800 text-ellipsis truncate text-center text-base mt-3 w-44">
                                Revenue Law in Uganda
                            </div>
                        </div>
                        <div className="items-center flex grow basis-[0%] flex-col">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8fe5d2d-3207-43fc-b542-d887a08b1889?apiKey=345ac80390ed4496b041ffff961f4164&"
                                className="aspect-[0.65] object-contain object-center w-[180px] overflow-hidden"
                            />
                                <div className="text-neutral-800 text-ellipsis truncate text-center text-base mt-3 w-44">
                                    Revenue Law in Uganda
                                </div>
                        </div> */}
                        {
                            suggestions.map((suggestion) => {
                                const link = suggestion.publicationType === "Laws of Uganda" ? `/laws-of-uganda/${suggestion?.id}` : `/all-publications/${suggestion?.id}`
                                return (
                                    <a href={link} className="items-center flex grow basis-[0%] flex-col">
                                        <img
                                            loading="lazy"
                                            src={suggestion?.pubThumbnailURL || Publish2}
                                            className="aspect-[0.65] object-contain w-[180px] border border-blue-900"
                                        />
                                        <div className="text-neutral-800 text-ellipsis text-center text-base mt-1 w-44">
                                            {suggestion?.publicationName}
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {pricingModal && <Pricing closeModal={() => setPricingModal(false)} />}
            {preview && publication?.publicationFileURL && <PdfPreview closePreview={() => setPreview(false)} preview={preview} docUrl={publication?.publicationFileURL} access={publication?.publlicationAccess} filename={publication?.publicationName} />}
        </div>
    )
}

export default ReviewPublication