import { useState, useEffect, useRef } from 'react';
import { BsPlus, BsThreeDotsVertical } from 'react-icons/bs';
import Publish1 from '../../../assets/publish1.png';
import Publish2 from '../../../assets/publish2.png';
import Publish3 from '../../../assets/publish3.png';
import CustomModal from './AddPublicationModal';
import { Button, Popconfirm, Space, Spin, Input, Pagination } from 'antd';
import { DownloadOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Tabs, Table } from 'antd';
import { API_URL } from '../../../envs';
import { FiRefreshCcw } from 'react-icons/fi';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../Auth';
import Highlighter from 'react-highlight-words';
import EditPublicationModal from './EditPublicationModal';

const publications = [
    { image: Publish1, topic: "Criminal Law in Uganda" },
    { image: Publish2, topic: "Revenue Law in Uganda" },
    { image: Publish3, topic: "Law of Contract in Uganda" },
];

const Publication = ({ info }) => {
    const { image, topic } = info
    return (
        <div>
            <img src={image} alt={"Publication"} style={{ height: 230, width: 160 }} />
            <div className='flex justify-between items-center mt-2 pl-1'>
                <span className='text-sm w-32 '>{topic}</span>
                <BsThreeDotsVertical className='cursor-pointer' />
            </div>
        </div>
    )
}

const fetchDataPag = async (page = 1, pageSize = 10) => {
    const response = await fetch(`${API_URL}/laws-of-ugandas?sort=id:desc&pagination[page]=${page}&pagination[pageSize]=${pageSize}`);
    const data = await response.json();
    return data;
};

const RefreshButton = ({ refresh, loadder, text }) => {
    return (
        <button
            disabled={loadder}
            className="py-1 px-2 rounded flex items-center gap-2 border border-slate-300"
            onClick={refresh}
        >
            <FiRefreshCcw />
            <span className='text-sm'>Refresh {text}</span>
        </button>
    )
}


const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => { },
    getCheckboxProps: (record) => ({
        name: record.name,
    }),
}

const { TabPane } = Tabs;

const Publications = () => {
    const [modal, setModal] = useState(false)
    const [volumesData, setVolumesData] = useState([]);
    const [lawsData, setLawsData] = useState([]);
    const [constitutionsData, setConstitutionsData] = useState([]);
    const [simplifiedLawsData, setSimplifiedLawsData] = useState([]);
    const [reportsData, setReportsData] = useState([]);
    const [otherPublicationsData, setOtherPublicationsData] = useState([]);

    const [constLoader, setConstLoader] = useState(false)
    const [volLoader, setVolLoader] = useState(false)
    const [lawsLoader, setLawsLoader] = useState(false)
    const [simpleLoader, setSimpleLoader] = useState(false)
    const [reportLoader, setReportLoader] = useState(false)
    const [otherLoader, setOtherLoader] = useState(false)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [publicationId, setPublicationId] = useState(null)
    const [publicationKey, setPublicationKey] = useState('null')
    const [publicationUrl, setPublicationUrl] = useState('')
    const [editModal, setEditModal] = useState(false)

    const [lawsPagination, setLawsPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const { userData } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchDataPag(lawsPagination.current, lawsPagination.pageSize); 

            setLawsData(response?.data?.map(item => ({
                id: item?.id,
                ...item?.attributes
            })))
            setLawsPagination({
                ...lawsPagination,
                total: response.meta.pagination.total,
            });
        };
        fetchData();
    }, [lawsPagination.current, lawsPagination.pageSize]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters();
        confirm();
        setSearchText('');
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search...`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        className='bg-blue-500'
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const volumesColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Volume Title',
            dataIndex: 'volTitle',
            key: 'volTitle',
            ...getColumnSearchProps('volTitle')
        },
        {
            title: 'Entry Date',
            dataIndex: 'entryDate',
            key: 'entryDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.entryDate) - new Date(b.entryDate),
        },
        {
            title: 'Access Type',
            dataIndex: 'accessType',
            key: 'accessType',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setPublicationUrl(`/volumes`)
                            setPublicationId(record?.id)
                            setPublicationKey('volumes')
                            setEditModal(true)
                        }}
                        className='text-blue-700 hover:text-blue-400'
                    >Edit</a>
                    <Popconfirm
                        title="Delete the publication"
                        description="Are you sure to delete this publication?"
                        onConfirm={() => deleteVol(record?.id)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const lawsColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            // sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Act Title',
            dataIndex: 'actTitle',
            key: 'actTitle',
            // ...getColumnSearchProps('actTitle')
        },
        {
            title: 'Law Volume',
            dataIndex: 'lawVolume',
            key: 'lawVolume',
            // ...getColumnSearchProps('lawVolume')
        },
        {
            title: 'Cap',
            dataIndex: 'cap',
            key: 'cap',
        },
        {
            title: 'Act Title Number',
            dataIndex: 'actTitleNumber',
            key: 'actTitleNumber',
        },
        {
            title: 'Law Type',
            dataIndex: 'lawType',
            key: 'lawType',
            // ...getColumnSearchProps('lawType')
        },
        {
            title: 'Act Name',
            dataIndex: 'actName',
            key: 'actName',
            // ...getColumnSearchProps('actName')
        },
        {
            title: 'Commencement',
            dataIndex: 'Commencement',
            key: 'Commencement',
            defaultSortOrder: 'descend',
            // sorter: (a, b) => new Date(a.Commencement) - new Date(b.Commencement),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setPublicationUrl(`/laws-of-ugandas`)
                            setPublicationId(record?.id)
                            setPublicationKey('lawsOfUganda')
                            setEditModal(true)
                        }}
                        className='text-blue-700 hover:text-blue-400'
                    >Edit</a>
                    <Popconfirm
                        title="Delete the publication"
                        description="Are you sure to delete this publication?"
                        onConfirm={() => deletLaws(record?.id)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const constitutionsColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Constitution Name',
            dataIndex: 'constName',
            key: 'constName',
            ...getColumnSearchProps('constName')
        },
        {
            title: 'Constitution Type',
            dataIndex: 'constitutionType',
            key: 'constitutionType'
        },
        {
            title: 'Access Type',
            dataIndex: 'accessType',
            key: 'accessType',
        },
        {
            title: 'Commencement',
            dataIndex: 'commencement',
            key: 'commencement',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.commencement) - new Date(b.commencement),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setPublicationUrl(`/constitutions`)
                            setPublicationId(record?.id)
                            setPublicationKey('constitutions')
                            setEditModal(true)
                        }}
                        className='text-blue-700 hover:text-blue-400'
                    >Edit</a>
                    <Popconfirm
                        title="Delete the publication"
                        description="Are you sure to delete this publication?"
                        onConfirm={() => deletConstitution(record?.id)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const simplifiedLawsColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Simple Name',
            dataIndex: 'simpleName',
            key: 'simpleName',
            ...getColumnSearchProps('simpleName')
        },
        {
            title: 'Access Type',
            dataIndex: 'accessType',
            key: 'accessType',
            ...getColumnSearchProps('accessType')
        },
        {
            title: 'Commencement Date',
            dataIndex: 'CommencementDate',
            key: 'CommencementDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.CommencementDate) - new Date(b.CommencementDate),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setPublicationUrl(`/simplified-laws`)
                            setPublicationId(record?.id)
                            setPublicationKey('simplifiedLaws')
                            setEditModal(true)
                        }}
                        className='text-blue-700 hover:text-blue-400'
                    >Edit</a>
                    <Popconfirm
                        title="Delete the publication"
                        description="Are you sure to delete this publication?"
                        onConfirm={() => deletSimplifiedLaw(record?.id)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const reportsColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Report Name',
            dataIndex: 'reportName',
            key: 'reportName',
            ...getColumnSearchProps('reportName')
        },
        {
            title: 'Report Type',
            dataIndex: 'reportType',
            key: 'reportType',
            ...getColumnSearchProps('reportType')
        },
        {
            title: 'Access Type',
            dataIndex: 'accessType',
            key: 'accessType',
        },
        {
            title: 'Report Date',
            dataIndex: 'reportDate',
            key: 'reportDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.reportDate) - new Date(b.reportDate),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setPublicationUrl(`/reports`)
                            setPublicationId(record?.id)
                            setPublicationKey('reports')
                            setEditModal(true)
                        }}
                        className='text-blue-700 hover:text-blue-400'
                    >Edit</a>
                    <Popconfirm
                        title="Delete the publication"
                        description="Are you sure to delete this publication?"
                        onConfirm={() => deletReport(record?.id)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const otherPublicationsColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Other Publication Name',
            dataIndex: 'otherPubName',
            key: 'otherPubName',
            ...getColumnSearchProps('otherPubName')
        },
        {
            title: 'Access Type',
            dataIndex: 'accessType',
            key: 'accessType',
        },
        {
            title: 'Other Publication Type',
            dataIndex: 'otherPublicationType',
            key: 'otherPublicationType',
            ...getColumnSearchProps('otherPublicationType')
        },
        {
            title: 'Other Publication Date',
            dataIndex: 'otherPubDate',
            key: 'otherPubDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.otherPubDate) - new Date(b.otherPubDate),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setPublicationUrl(`/other-publications`)
                            setPublicationId(record?.id)
                            setPublicationKey('otherPublications')
                            setEditModal(true)
                        }}
                        className='text-blue-700 hover:text-blue-400'>Edit</a>
                    <Popconfirm
                        title="Delete the publication"
                        description="Are you sure to delete this publication?"
                        onConfirm={() => deletOtherPublication(record?.id)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        fetchAllData();
    }, []);

    const fetchAllData = async () => {
        await fetchVolumesData();
        // await fetchLawsOfUgandaData();
        await fetchConstitutionsData();
        await fetchSimplifiedLawsData();
        await fetchReportsData();
        await fetchOtherPublicationsData();
    };

    const fetchVolumesData = async () => {
        try {
            setVolLoader(true);
            const response = await fetch(`${API_URL}/volumes`);
            const data = await response.json();
            setVolumesData(data?.data?.map(item => ({
                id: item?.id,
                ...item?.attributes
            })));
        } catch (error) {
            console.error(error);
        } finally {
            setVolLoader(false);
        }
    };

    const fetchLawsOfUgandaData = async () => {
        try {
            setLawsLoader(true);
            const response = await fetch(`${API_URL}/laws-of-ugandas?sort=id:desc&pagination[page]=0&pagination[pageSize]=10`);
            const data = await response.json();
            setLawsData(data?.data?.map(item => ({
                id: item?.id,
                ...item?.attributes
            })));
        } catch (error) {
            console.error(error);
        } finally {
            setLawsLoader(false);
        }
    };

    const fetchConstitutionsData = async () => {
        try {
            setConstLoader(true);
            const response = await fetch(`${API_URL}/constitutions?populate=*&pagination[start]=0&pagination[limit]=90`);
            const data = await response.json();
            setConstitutionsData(
                data?.data?.map(item => ({
                    id: item?.id,
                    ...item?.attributes
                }))
                    .filter(item => {
                        return item?.publications?.data?.length === 0
                    })
            );
        } catch (error) {
            console.error(error);
        } finally {
            setConstLoader(false);
        }
    };

    const fetchSimplifiedLawsData = async () => {
        try {
            setSimpleLoader(true);
            const response = await fetch(`${API_URL}/simplified-laws?populate=*`);
            const data = await response.json();
            setSimplifiedLawsData(
                data?.data?.map(item => ({
                    id: item?.id,
                    ...item?.attributes
                }))
            );
        } catch (error) {
            console.error(error);
        } finally {
            setSimpleLoader(false);
        }
    };

    const fetchReportsData = async () => {
        try {
            setReportLoader(true);
            const response = await fetch(`${API_URL}/reports?populate=*&pagination[start]=0&pagination[limit]=90`);
            const data = await response.json();
            setReportsData(
                data?.data?.map(item => ({
                    id: item?.id,
                    ...item?.attributes
                }))
                    .filter(item => {
                        return item?.publications?.data?.length === 0
                    })
            );
        } catch (error) {
            console.error(error);
        } finally {
            setReportLoader(false);
        }
    };

    const fetchOtherPublicationsData = async () => {
        try {
            setOtherLoader(true);
            const response = await fetch(`${API_URL}/other-publications?populate=*&pagination[start]=0&pagination[limit]=90`);
            const data = await response.json();
            setOtherPublicationsData(
                data?.data?.map(item => ({
                    id: item?.id,
                    ...item?.attributes
                }))
                    .filter(item => {
                        return item?.publications?.data?.length === 0
                    })
            );
        } catch (error) {
            console.error(error);
        } finally {
            setOtherLoader(false);
        }
    };

    const deletConstitution = async (id) => {
        try {
            setConstLoader(true);
            await axios.delete(`${API_URL}/constitutions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.jwt}`
                }
            });
            toast.success("Constitution deleted successfully");
            await fetchConstitutionsData()
        } catch (error) {
            console.error(error);
        } finally {
            setConstLoader(false);
        }
    };

    const deletSimplifiedLaw = async (id) => {
        try {
            setSimpleLoader(true);
            await axios.delete(`${API_URL}/simplified-laws/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.jwt}`
                }
            });
            toast.success("Law deleted successfully");
            await fetchSimplifiedLawsData()
        } catch (error) {
            console.error(error);
        } finally {
            setSimpleLoader(false);
        }
    };

    const deletReport = async (id) => {
        try {
            setReportLoader(true);
            await axios.delete(`${API_URL}/reports/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.jwt}`
                }
            });
            toast.success("Report deleted successfully");
            await fetchReportsData()
        } catch (error) {
            console.error(error);
        } finally {
            setReportLoader(false);
        }
    };

    const deletLaws = async (id) => {
        try {
            setLawsLoader(true);
            await axios.delete(`${API_URL}/laws-of-ugandas/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.jwt}`
                }
            });
            toast.success("Law deleted successfully");
            await fetchLawsOfUgandaData()
        } catch (error) {
            console.error(error);
        } finally {
            setLawsLoader(false);
        }
    };

    const deletOtherPublication = async (id) => {
        try {
            setOtherLoader(true);
            await axios.delete(`${API_URL}/other-publications/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.jwt}`
                }
            });
            toast.success("Publication deleted successfully")
            await fetchOtherPublicationsData()
        } catch (err) {
            console.error(err);
        } finally {
            setOtherLoader(false);
        }
    };


    const deleteVol = async (id) => {
        try {
            setVolLoader(true);
            await axios.delete(`${API_URL}/volumes/${id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.jwt}`
                }
            });
            toast.success("Volume deleted successfully");
            await fetchVolumesData()
        } catch (err) {
            console.error(err);
        } finally {
            setVolLoader(false);
        }
    }

    return (
        <>
            <div className="flex flex-col items-stretch mt-14 px-5 max-md:max-w-full max-md:mt-10">
                <h1 className="text-neutral-800 text-lg font-semibold mb-5 max-md:max-w-full">
                    Publications
                </h1>

                <div className="flex justify-end">
                    <Button
                        type="primary"
                        shape="round"
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => setModal(true)}
                        style={{ backgroundColor: "#1d234f" }}
                    >
                        Add New Publication
                    </Button>
                </div>

                <Tabs>
                    <TabPane tab="Volumes" key="1">
                        <RefreshButton loadder={volLoader} refresh={fetchVolumesData} text={"Volumes"} />
                        <Spin spinning={volLoader} tip="Loading Volumes...">
                            <Table dataSource={volumesData} columns={volumesColumns} />
                        </Spin>
                    </TabPane>
                    <TabPane tab="Laws of Uganda" key="2">
                        <RefreshButton loadder={volLoader} refresh={fetchLawsOfUgandaData} text={"Laws"} />
                        <Spin spinning={lawsLoader} tip="Loading Laws...">
                            <Table dataSource={lawsData} columns={lawsColumns} pagination={false} />
                            <Pagination
                                current={lawsPagination.current}
                                pageSize={lawsPagination.pageSize}
                                total={lawsPagination.total}
                                onChange={(page, pageSize) => setLawsPagination({ ...lawsPagination, current: page })}
                                // style={{ textAlign: 'right' }}
                                className='text-right mt-4 mb-8'
                            />
                        </Spin>
                    </TabPane>
                    <TabPane tab="Constitutions" key="3">
                        <RefreshButton loadder={constLoader} refresh={fetchConstitutionsData} text={"Constitutions"} />
                        <Spin spinning={constLoader} tip="Loading Constitutions...">
                            <Table dataSource={constitutionsData} columns={constitutionsColumns} />
                        </Spin>
                    </TabPane>
                    <TabPane tab="Simplified Laws" key="4">
                        <RefreshButton loadder={simpleLoader} refresh={fetchSimplifiedLawsData} text={"Simplified Laws"} />
                        <Spin spinning={simpleLoader} tip="Loading Simplified Laws...">
                            <Table dataSource={simplifiedLawsData} columns={simplifiedLawsColumns} />
                        </Spin>
                    </TabPane>
                    <TabPane tab="Reports" key="5">
                        <RefreshButton loadder={reportLoader} refresh={fetchReportsData} text={"Reports"} />
                        <Spin spinning={reportLoader} tip="Loading Reports...">
                            <Table dataSource={reportsData} columns={reportsColumns} />
                        </Spin>
                    </TabPane>
                    <TabPane tab="Other Publications" key="6">
                        <RefreshButton loadder={otherLoader} refresh={fetchOtherPublicationsData} text={"Other Publications"} />
                        <Spin spinning={otherLoader} tip="Loading Other Publications...">
                            <Table dataSource={otherPublicationsData} columns={otherPublicationsColumns} />
                        </Spin>
                    </TabPane>
                </Tabs>
            </div>
            <CustomModal closeModal={() => setModal(false)} fetchAllData={fetchAllData} open={modal} />
            <EditPublicationModal
                publicationId={publicationId}
                publicationUrl={publicationUrl}
                visible={editModal}
                publicationKey={publicationKey}
                onCancel={() => setEditModal(false)}
                fetchAllData={fetchAllData}
            // fetchAllData={fetchAllData}
            />
        </>
    )
}

export default Publications
