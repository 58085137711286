import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../Auth'
import DashboardStats from './DashboardStats'
import Graph from './Graph'

function DashboardInfo() {
    const { userData, subscriptionData } = useAuth()
    const isEditor = userData?.user?.email === "editor@ulrc.go.ug"

    const formatDate = () => {
        if(!subscriptionData?.subscriptionEnd) return ""
        const d = new Date(subscriptionData?.subscriptionEnd)
        return d.toLocaleDateString()
    }
    const serviceRole = userData?.user?.serviceRole === 'superadmin'
    const expiryText = new Date(subscriptionData?.subscriptionEnd) > new Date() ? "it will expire" : "already expired"
    
    if (!serviceRole) {
        return (
            <div className="flex flex-col items-stretch mt-14 px-5 max-md:max-w-full max-md:mt-10">
                <h1 className="text-neutral-800 text-lg leading-8 -mr-5 max-md:max-w-full">
                    You subscribed for the <b><i>{subscriptionData?.subscriptionName}</i></b> package and {expiryText} on {formatDate()},
                    {/* <br />
                    there are currently 49 slots for new users */}
                </h1>
                <Link to="/dashboard/user-management" className="mt-8 underline text-red-700">
                    Manage Users Here
                </Link>                
            </div>
        )
    } else {
        return (
            <div className="flex flex-col items-stretch mt-14 px-5 max-md:max-w-full max-md:mt-10">
                <h1 className="text-neutral-800 text-lg font-semibold max-md:max-w-full mb-3">Dashboard</h1>

                <DashboardStats />
                {/* <h1>Graph</h1> */}
                <div style={{
                    width: 500,
                    height: 300
                }}>
                    {/* <Graph /> */}
                </div>
            </div>
        )
    }
}

export default DashboardInfo