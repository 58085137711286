import React from 'react';
import { FaSearch, FaUserAlt, FaGlobeAfrica, FaFileArchive, FaFileAlt } from 'react-icons/fa'
import { BiBell, BiCart } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import logo2 from '../../ulrc_logo.png';
import { renderPublications } from '../Home';
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthHeader2, PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import Footer from '../Footer';
import { _index, _report } from '../searchClient';
import { MdBalance, MdGTranslate } from 'react-icons/md';
import { FiChevronDown, FiChevronUp, FiFileText } from 'react-icons/fi';
import { GrLanguage } from "react-icons/gr";
import axios from 'axios';
import { API_URL } from '../../envs';
import { IoArrowBack } from 'react-icons/io5';
import Pricing from '../Pricing';

const pubicationOptions = [
    'Copyright infringement',
    'Contract Law',
    'Revenue Law'
]

const lawsOfUg = [
    {
        label: 'Principal Laws',
        value: 'Principal Laws'
    },
    {
        label: 'Subsidiary Laws',
        value: 'Subsidiary Laws'
    },
    {
        label: 'Consolidated Laws',
        value: 'Consolidated Laws'
    }
]


const translatedVersions = [
    {
        label: 'English',
        value: 'English'
    },
    {
        label: 'Translated Versions',
        value: 'Translated Versions'
    }
]
// [
//     {
//         label: "Acholi",
//         value: "Acholi"
//     },
//     {
//         label: "Alur",
//         value: "Alur"
//     },
//     {
//         label: "Ateso",
//         value: "Ateso"
//     },
//     {
//         label: "Dhopadhola",
//         value: "Dhopadhola"
//     },
//     {
//         label: "English",
//         value: "English"
//     },
//     {
//         label: "Kishwahili",
//         value: "Kishwahili"
//     },
//     {
//         label: "Lango",
//         value: "Lango"
//     },
//     {
//         label: "Leb-lango",
//         value: "Leb-lango"
//     },
//     {
//         label: "Luganda",
//         value: "Luganda"
//     },
//     {
//         label: "Lugbara",
//         value: "Lugbara"
//     },
//     {
//         label: "Lugwere",
//         value: "Lugwere"
//     },
//     {
//         label: "Lukhonzo",
//         value: "Lukhonzo"
//     },
//     {
//         label: "Lumasaba",
//         value: "Lumasaba"
//     },
//     {
//         label: "Lusoga",
//         value: "Lusoga"
//     },
//     {
//         label: "Madi-tii",
//         value: "Madi-tii"
//     },
//     {
//         label: "Nga'karimojong",
//         value: "Nga'karimojong"
//     },
//     {
//         label: "Rufumbira",
//         value: "Rufumbira"
//     },
//     {
//         label: "Runyankole-rukiga",
//         value: "Runyankole-rukiga"
//     },
//     {
//         label: "Runyoro-Rutoro",
//         value: "Runyoro-Rutoro"
//     }
// ]

const otherPublications = [
    {
        label: "Index of the laws of Uganda",
        value: "Index of the laws of Uganda"
    },
    {
        label: "Uganda Living Law Journal",
        value: "Uganda Living Law Journal"
    },
    {
        label: "Translated Laws",
        value: "Translated Laws"
    },
    {
        label: "Cummulative Supplement",
        value: "Cumulative Suppliment"
    }
]

const _reports = [
    {
        label: "Annual reports",
        value: "Annual"
    },
    {
        label: "Study reports",
        value: "Study"
    },
]


const SideMenu = ({ queries, setQueries }) => {
    const [translationCat, setTranslationCat] = React.useState(true)
    const [laws, setLaws] = React.useState(true)
    const [reports, setReports] = React.useState(true)
    const [other, setOther] = React.useState(false)

    const handleChecked = (e) => {
        const { name, checked } = e.target
        if (checked) {
            setQueries([...queries, name])
        } else {
            setQueries(queries.filter(item => item !== name))
        }
    }

    return (
        <div className="items-start self-stretch flex grow flex-col w-full mt-2 max-h-[620px] llg:max-h-full llg:h-auto overflow-auto">
            <div className="content-center flex-wrap self-stretch flex w-full flex-col px-3.5 py-2.5">
                <div
                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                    onClick={() => setReports(!reports)}
                >
                    <div className="flex items-center gap-3.5">
                        {/* <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3b8f9bfc-bcb0-4c8e-b1e8-ba9dd15f52a0?apiKey=345ac80390ed4496b041ffff961f4164&" className="aspect-square object-cover object-center w-6 overflow-hidden self-stretch max-w-full" /> */}
                        <FaFileArchive size={24} className="text-slate-500" />
                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Reports</div>
                    </div>
                    {reports ? <FiChevronUp size={22} /> : <FiChevronDown size={22} />}
                </div>
                {reports &&
                    <div className="flex flex-col gap-2.5 mt-2 ml-10">
                        {
                            _reports.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        <input
                                            type="checkbox"
                                            name={item.value}
                                            id={item.value}
                                            checked={queries.includes(item.value)}
                                            onChange={handleChecked} />
                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const Reports = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryText = queryParams.get('query');
    const navigate = useNavigate()

    const [search, setSearch] = React.useState('')
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [openPlans, setOpenPlans] = React.useState(false)
    const [list, setList] = React.useState([])
    const [fullList, setFullList] = React.useState([])
    const [queries, setQueries] = React.useState([])

    // React.useEffect(() => {
    //     // if (search.length === 0) {
    //     //     setResults([])
    //     //     return
    //     // }

    //     setLoading(true)
    //     _report
    //         .search(search, {
    //             limit: 25,
    //         })
    //         .then(({ hits }) => {
    //             setResults(hits)
    //         })
    //         .catch((err) => {
    //             console.error(err)
    //         })
    //         .finally(() => {
    //             setLoading(false)
    //         })
    // }, [search])

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const resp = await axios.get(`${API_URL}/reports?populate=*&pagination[start]=0&pagination[limit]=90`)
                const data = resp.data?.data || []
                if (data.length > 0) {
                    const items = []
                    data.forEach(item => {
                        const itemlist = item.attributes?.publications?.data
                        if (itemlist.length > 0) return;
                        items.push(item)                    
                    })
                    setFullList(items)
                    setList(items)
                }
            } catch {

            } finally {
                setLoading(false)
            }
        })()
    }, [])

    // React.useEffect(() => {
    //     let _list = list
    //     if (queries.length > 0) {
    //         _list = fullList.filter(item => {
    //             const { attributes: { publicationName } } = item
    //             const _publicationName = publicationName.toLowerCase()
    //             const _queries = queries.map(item => item.toLowerCase())
    //             return _queries.some(item => _publicationName.includes(item))
    //         })
    //     } else {
    //         _list = fullList
    //     }
    //     setList(_list)
    // }, [queries])

    React.useEffect(() => {
        if (queries.length > 0) {
            const _list = fullList.filter(item => {
                return queries.includes(item.attributes.reportType)
            })
            setList(_list)
        } else {
            setList(fullList)
        }

    }, [queries, fullList])

    React.useEffect(() => {
        if (search.length === 0) {
            setList(fullList)
            return
        }

        const _list = fullList.filter(item => item.attributes?.reportName?.toLowerCase().includes(search.toLowerCase()))
        setList(_list)
    }, [search])

    React.useEffect(() => {
        if (queryText) {
            setQueries([...queries, queryText])
        }
    }, [queryText])

    const openPlansModal = () => {
        setOpenPlans(true)
    }

    console.log("LIST:", list)

    return (
        <div className='bg-bgGrey min-h-full flex flex-col justify-between'>
            <div className='pt-7 pb-20 container mx-auto'>
                {/* <PublicationsNav search={search} setSearch={setSearch} /> */}
                <UnAuthHeader3 openPlansModal={openPlansModal} />

                <div className='flex items-start w-full'>
                    <div className='pt-8 pr-2 w-[245px]'>
                        <div className='font-bold mb-3 text-lg'>Filter</div>
                        <SideMenu {...{ queries, setQueries }} />
                    </div>
                    <div className='border-l-4 pt-8 pl-8 w-full'>
                        <div className='mb-6 flex items-center justify-between w-full'>
                            <div>
                                <IoArrowBack
                                    onClick={() => navigate('/')}
                                    className='inline-block mr-2 text-xl cursor-pointer' />
                                <span className="font-bold text-lg">Reports</span>
                            </div>
                            <div className='flex gap-2'>
                                <input
                                    className='h-9 rounded-lg border outline-1 border-gray-200 px-2 text-sm'
                                    placeholder='Search by Act or Cap...'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className='bg-bgBlue text-white h-9 px-4 rounded-lg'>Search</button>
                            </div>
                        </div>
                        <div className='grid grid-flow-row grid-cols-5 gap-4'>
                            {/* {
                                results.map(({ id, reportType }) => {
                                    const imgs = [Publish1, Publish2]
                                    let randomNumber = Math.random() < 0.5 ? 0 : 1;
                                    return (
                                        <Link to={`${id}`}>
                                            <div className='w-40'>
                                                <img src={imgs[randomNumber]} alt={"Publication"} className={'w-40 h-60'} />
                                                <div className='flex justify-between items-center mt-2 pl-1'>
                                                    <span className='text-sm w-36 truncate text-center'>{reportType}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            } */}
                            {
                                list.map(({ attributes: { reportThumnailURL, reportName }, id }) => {
                                    return (
                                        <Link to={`/report/${id}`} title={reportName}>
                                            <div className='w-40'>
                                                <img src={reportThumnailURL} alt={"Publication"} className={'w-40 h-60  rounded-[10px] border-2'} style={{ borderColor: '#eee' }} />
                                                <div className='flex justify-between items-center mt-2 pl-1'>
                                                    <span className='text-sm w-36 text-center' title={reportName}>{reportName}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        {
                            !loading && list.length === 0 &&
                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                No Reports Found
                            </div>
                        }
                        {
                            loading &&
                            <div className="text-neutral-800 text-xl font-light mt-2.5 max-md:max-w-full text-center">
                                Loading Reports...
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {openPlans && <Pricing closeModal={() => setOpenPlans(false)} />}
        </div>
    )
}

export default Reports;
