import React from 'react';
import { Link } from 'react-router-dom';

const PasswordResetSuccess = () => {
    return (
        <div className='min-h-screen bg-bgGrey flex justify-center items-center px-4 sm:px-6 lg:px-8'>
            <div className='w-full max-w-md bg-white p-8 rounded-lg shadow-md text-center'>
                <h2 className='text-xl sm:text-2xl font-semibold mb-5'>Password Reset Successfully!</h2>
                <p>Your password has been updated. You can now use your new password to login.</p>
                <Link to='/signin' className='mt-6 inline-block bg-brightRed text-white py-2 px-4 rounded-lg hover:bg-brightRed-dark focus:outline-none focus:ring-2 focus:ring-brightRed-light focus:ring-opacity-50 transition ease-in-out duration-150'>
                    Go to Login
                </Link>
            </div>
        </div>
    );
};

export default PasswordResetSuccess;