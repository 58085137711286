import React from 'react';
import { UnAuthHeader3 } from './UnAuthHeader';
import Footer from './Footer';
import Pricing from './Pricing';
import axios from 'axios';
import { toast } from 'react-toastify';

const hasEmptyStrings = (formObj) => {
    return Object.values(formObj).some((value) => value === '');
};

export default function ContactUs() {
    const [openPlans, setOpenPlans] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const openPlansModal = () => {
        setOpenPlans(true)
    }

    const handleFormSubmission = async (e) => {
        e.preventDefault()

        const data = new FormData(e.target)
        const emailObj = Object.fromEntries(data)

        if (hasEmptyStrings(emailObj)) {
            toast.error('All fields are required');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post('https://govnet-api-proxy.vercel.app/contact-us', emailObj)
            console.log(response)
            // if (!!response) {
            //     toast.success('Message sent successfully!')
            //     setTimeout(() => {
            //         window.location.reload()
            //     }, 2000)
            // }

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
            toast.success('Message sent successfully!')
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }

    }

    return (
        <div className="justify-end items-stretch bg-white flex flex-col">
            <div className="items-start  flex w-full flex-col max-md:max-w-full">
                <div className="w-full">
                    <div
                        className="flex-col overflow-hidden self-stretch relative flex min-h-[664px] w-full items-stretch">
                        <div className=" -mr-5 px-20 max-md:max-w-full max-md:px-5">
                            <UnAuthHeader3 openPlansModal={openPlansModal} />
                        </div>
                        <div className="flex mt-5 justify-center min-h-screen">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                                <h2 className="text-2xl font-semibold">Get In Touch</h2>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                                    <div className="space-y-6">
                                        {/* <h2 className="text-2xl font-semibold">Contact us</h2> */}
                                        <p>
                                            If you have any questions or need assistance, please reach out to us through our official channels. Our
                                            dedicated team is always ready to help you.
                                        </p>
                                        <div>
                                            <h3 className="text-xl font-semibold">Address</h3>
                                            <p>
                                                Uganda Law Reform Commission, Workers House, 8th Floor, Plot 1 Pilkington Road, P.O. Box 12149, Kampala
                                            </p>
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold">Contacts</h3>
                                            <p>Phone: +256 414 346200 /341138</p>
                                            <p>
                                                Email:{" "}
                                                <a className="text-blue-600" href="mailto:ulrc@ulrc.go.ug">
                                                    ulrc@ulrc.go.ug
                                                </a>
                                            </p>
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold">Social Media</h3>
                                            <p>Twitter:
                                                <a
                                                    className="text-blue-600"
                                                    href="https://twitter.com/ulrcug"
                                                    target='_blank'
                                                >
                                                    @ULRCUG
                                                </a>
                                            </p>
                                            <p>Facebook: <a className="text-blue-600" href="https://www.facebook.com/ulrcug">ULRCUG</a></p>
                                            <p>
                                                Website:{" "}
                                                <a className="text-blue-600" href="https://www.ulrc.go.ug/" target='_blank'>
                                                    www.ulrc.go.ug
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-1">
                                        <form
                                            className="bg-white shadow-lg rounded-lg p-6 space-y-4 border"
                                            onSubmit={handleFormSubmission}
                                        >
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <input
                                                    className='h-10 pl-2 rounded-lg border'
                                                    name="name"
                                                    placeholder="Name" />
                                                <input
                                                    className='h-10 pl-2 rounded-lg border'
                                                    placeholder="Email"
                                                    name='email'
                                                    type="email"
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                    required
                                                    title="Please enter a valid email address"
                                                />
                                            </div>
                                            <input
                                                className='h-10 pl-2 w-full rounded-lg border block'
                                                name='subject'
                                                required
                                                placeholder="Subject"
                                            />
                                            <textarea
                                                className="h-32 pl-2 rounded-lg border block w-full"
                                                name='message'
                                                required
                                                placeholder="Message"
                                            />
                                            <button
                                                className="w-full bg-slate-800 text-white rounded-md h-10"
                                                type="submit"
                                                disbled={loading}
                                            >
                                                {loading ? "Sending..." : "Send Message"}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            {openPlans && <Pricing closeModal={() => setOpenPlans(false)} />}
        </div>
    );
}