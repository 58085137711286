import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from '../envs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';

const GoogleRedirect = () => {
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id_token = queryParams.get('access_token');
    const { userData, saveUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if (id_token) {
                setLoading(true)
                try {
                    const response = await axios.get(`${API_URL}/auth/google/callback?access_token=${id_token}`)
                    saveUser(response.data)
                    const to = localStorage.getItem("publicationLink") || "/"
                    navigate(to, { replace: true })
                } catch (error) {
                    console.log(error)
                } finally {
                    setLoading(false)
                }
            }
        })()
    }, [id_token])

    return (
        <div className="flex justify-center items-center h-screen">
            
            {loading && <p className='text-xl'>Authenticating...</p>}        
            {!loading && <p>Redirect failed. <Link className='text-blue-500 font-bold' to="/">Click here</Link> to go home</p>}
        </div>

    )
}

export default GoogleRedirect