import * as React from "react";
import Footer from "./Footer";
import { UnAuthHeader3 } from "./UnAuthHeader";
import books from '../assets/bluebooks.png';
import ladyLaptop from '../assets/lady_laptop.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { _index, _laws } from "./searchClient";
import Publish2 from '../assets/LivingLawJournal.jpg';
import Pricing from "./Pricing";
import axios from "axios";
import { API_URL } from "../envs";
import { Dropdown } from "antd";


function Home() {

    const [suggestions, setSuggestions] = React.useState([])
    const [openPlans, setOpenPlans] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [results, setResults] = React.useState([])
    const [list, setList] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const navigate = useNavigate()

    const segueTo = () => {
        // navigate('/all-publications')
        navigate('/laws-of-uganda?query=principal')
    }

    const openPlansModal = () => {
        setOpenPlans(true)
    }

    const constitutionItems = [
        {
            key: 'Constitution of the Republic of Uganda - English',
            label: 'Constitution of the Republic of Uganda - English',
            link: '/constitution?query=english',
            onClick: () => navigate('/constitution?query=english'),
            className: 'textwhite-home-child'
        },
        {
            key: 'Translated Versions of the constitution',
            label: 'Translated Versions of the constitution',
            link: '/constitution?query=translated',
            onClick: () => navigate('/constitution?query=translated'),
            className: 'textwhite-home-child'
        }
    ]

    const lawsItems = [
        {
            key: 'Principal Laws',
            label: 'Principal Laws',
            link: '/laws-of-uganda?query=principal',
            className: 'textwhite-home-child',
            onClick: () => navigate('/laws-of-uganda?query=principal')
        },
        {
            key: 'Subsidiary Laws',
            label: 'Subsidiary Laws',
            link: '/laws-of-uganda?query=subsidiary',
            className: 'textwhite-home-child',
            onClick: () => navigate('/laws-of-uganda?query=subsidiary')
        },
        {
            key: 'Consolidated Laws',
            label: 'Consolidated Laws',
            link: '/laws-of-uganda?query=Consolidated',
            className: 'textwhite-home-child',
            onClick: () => navigate('/laws-of-uganda?query=Consolidated')
        }
    ]

    const reports = [
        {
            key: 'Annual Reports',
            label: 'Annual Reports',
            link: '/report',
            className: 'textwhite-home-child',
            onClick: () => navigate('/report?query=Annual')
        },
        {
            key: 'Study Reports',
            label: 'Study Reports',
            link: '/report',
            className: 'textwhite-home-child',
            onClick: () => navigate('/report?query=Study')
        }
    ]

    const otherPublications = [
        {
            key: 'Index Of The Laws Of Uganda',
            label: 'Index Of The Laws Of Uganda',
            link: '/other-publication?query=Index of laws',
            className: 'textwhite-child',
            onClick: () => navigate('/other-publication?query=Index of laws')
        },
        {
            key: 'Uganda Living Law Journal',
            label: 'Uganda Living Law Journal',
            link: '/other-publication?query=Uganda Living Journal',
            className: 'textwhite-child',
            onClick: () => navigate('/other-publication?query=Uganda Living Journal')
        },
        {
            key: 'Translated Local Council Act',
            label: 'Translated Laws',
            link: '/other-publication?query=Translated Laws',
            className: 'textwhite-child',
            onClick: () => navigate('/other-publication?query=Translated Laws')
        },
        {
            key: 'Cumulative Suppliment',
            label: 'Cumulative Supplement',
            link: '/other-publication?query=Cumulative Suppliment',
            className: 'textwhite-child',
            onClick: () => navigate('/other-publication?query=Cumulative Suppliment')
        }
    ]


    React.useEffect(() => {
        _index
            .search('', {
                limit: 6,
                sort: ['id:asc']
            })
            .then(({ hits }) => {
                setSuggestions(hits);
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    React.useEffect(() => {
        if (search.length === 0) {
            setResults([])
            return
        }

        try {
            setLoading(true)
            const searchFetch = async () => {
                const index1 = await _laws.search(search, { limit: 3 })
                setResults([...index1.hits])
            }

            searchFetch()
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
        // _index
        //     .search(search, {
        //         limit: 3,
        //     })
        //     .then(({ hits }) => {
        //         setResults(hits)
        //     })
        //     .catch((err) => {
        //         console.error(err)
        //     })
        //     .finally(() => {
        //         setLoading(false)
        //     })
    }, [search])

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const resp = await axios.get(`${API_URL}/volumes?populate=*&pagination[start]=0&pagination[limit]=6&sort=id`)
                const data = resp.data?.data || []
                if (data.length > 0) {
                    const items = []
                    data.forEach(item => {
                        items.push(item) 
                    })
                    // setFullList(items)
                    setList(items)
                }
            } catch (error) {

            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const goToResults = () => {
        navigate(`/all-publications?query=${search}`)
    }
    return (
        <div className="justify-end items-stretch bg-white flex flex-col">
            <div className="items-start bg-slate-100 flex w-full flex-col max-md:max-w-full">
                <div className="w-full">
                    <div
                        className="flex-col overflow-hidden self-stretch relative flex min-h-[664px] w-full items-stretch">
                        <div className=" -mr-5 px-20 max-md:max-w-full max-md:px-5">
                            <UnAuthHeader3 openPlansModal={openPlansModal} />
                        </div>
                        <div className="border w-full bg-no-repeat bg-bottom bg-cover bg-hero-image2 pt-10 pb-16">
                            <div className='flex items-center justify-center '>
                                <div>
                                    <div className='font-serif text-6xl space-y-3 text-white mb-10'>
                                        <div className="text-center animate-slidein300 opacity-0" style={{
                                            textShadow: "2px 8px 12px #000"
                                        }}>Laws that facilitate</div>
                                        <div className="text-center animate-slidein500 opacity-0" style={{
                                            textShadow: "2px 8px 12px #000"
                                        }}>transformation and</div>
                                        <div className="text-center animate-slidein700 opacity-0" style={{
                                            textShadow: "2px 8px 12px #000"
                                        }}>development of Uganda</div>
                                    </div>
                                    <div className='ml-3'>
                                        {/* <div className='mb-6 text-blue-200 text-center'>Get Access To Over 10k Publications</div> */}
                                        <div className='relative flex justify-center'>
                                            <div className='flex items-center animate-slideup800 opacity-0'>
                                                <input
                                                    placeholder='Search by Act or Cap'
                                                    className='border rounded-md text-sm h-8 pl-2 p-5 w-72 mr-2 outline-none'
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                                <button className='bg-bgBlue text-white  rounded-md px-5 py-3' onClick={goToResults}>Search</button>
                                            </div>
                                            {
                                                search.length > 0 && (
                                                    results.length > 0 ? (
                                                        <div className="absolute top-12 z-50 bg-white w-full rounded-md shadow-md">
                                                            {
                                                                loading ? (
                                                                    <div className="flex items-center justify-between px-5 py-2">
                                                                        <div className="text-sm text-gray-500">Searching...</div>
                                                                    </div>
                                                                ) : (
                                                                    results.map(({ publicationType, id, actName, cap }) => {
                                                                        // const link = publicationType === "Laws of Uganda" ? `/laws-of-uganda/${id}` : `/all-publications/${id}`
                                                                        const link = `/acts/${id}`;
                                                                        return (
                                                                            <Link
                                                                                to={link}
                                                                                className="flex items-center justify-between px-5 py-2 hover:bg-gray-100"
                                                                                key={id}
                                                                            >
                                                                                <div className="text-sm text-gray-500">{actName || ''}, Cap. {cap || ''}</div>
                                                                            </Link>
                                                                        )
                                                                    })
                                                                )
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="absolute top-12 z-50 w-full bg-white rounded-md shadow-md">
                                                            <div className="flex items-center justify-between px-5 py-2">
                                                                {
                                                                    loading ? (
                                                                        <div className="text-sm text-gray-500">Searching...</div>
                                                                    ) : (
                                                                        <div className="text-sm text-gray-500">No results found</div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <img src={books} alt={'Books'} className='rounded-lg max-h-[440px]' /> */}
                                {/* <div className='w-2/5 md:h-[440px] flex items-center justify-center' >
                            <img src={books} alt={'Books'} className='w-[85%] rounded-lg' />
                        </div> */}
                            </div>

                            <div className="flex text-sm justify-between w-full mt-10 px-20 max-w-[1310px] mx-auto animate-slideup900 opacity-0">
                                <Dropdown menu={{ items: constitutionItems }}>
                                    <Link to="/constitution" className="bg-slate-100 border border-slate-200 grow text-center py-4 font-bold hover:bg-brightRed hover:text-white transition-colors duration-200 ease-in-out">Constitution of the Republic of Uganda</Link>
                                </Dropdown>
                                <Dropdown menu={{ items: lawsItems }}>
                                    <Link to="/laws-of-uganda" className="bg-slate-100 border border-slate-200 grow text-center py-4 font-bold hover:bg-brightRed hover:text-white transition-colors duration-200 ease-in-out">Laws of Uganda</Link>
                                </Dropdown>
                                <Link to="/simplified-laws-of-uganda" className="bg-slate-100 border border-slate-200 grow text-center py-4 font-bold hover:bg-brightRed hover:text-white transition-colors duration-200 ease-in-out">Simplified Laws of Uganda</Link>
                                <Dropdown menu={{ items: reports }}>
                                    <Link to="/report" className="bg-slate-100 border border-slate-200 grow text-center py-4 font-bold hover:bg-brightRed hover:text-white transition-colors duration-200 ease-in-out">Reports</Link>
                                </Dropdown>
                                <Dropdown menu={{ items: otherPublications }}>
                                    <Link to="/other-publication" className="bg-slate-100 border border-slate-200 grow text-center py-4 font-bold hover:bg-brightRed hover:text-white transition-colors duration-200 ease-in-out">Other Publications</Link>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white self-stretch flex w-full flex-col items-stretch -mr-5 max-md:max-w-full">
                    <div className="bg-white flex w-full flex-col items-stretch px-20 py-12 max-md:max-w-full max-md:px-5">
                        <div className="font-serif text-neutral-800 text-center text-5xl leading-[54px] self-center whitespace-nowrap mt-3.5 max-md:max-w-full max-md:text-4xl max-md:leading-[50px]">
                            Popular Publications
                        </div>
                        <div className="items-stretch flex justify-between gap-5 mt-12 mx-5 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5 max-md:mt-10">
                            {
                                // suggestions.map((suggestion, index) => (
                                //     <Link to={`/all-publications/${suggestion.id}`} key={index} className="items-center flex grow basis-[0%] flex-col">
                                //         <img
                                //             loading="lazy"
                                //             srcSet={suggestion?.pubThumbnailURL || Publish2}
                                //             // className="aspect-[0.65] object-contain object-center w-[180px] overflow-hidden border"
                                //             // className="border w-[175px] h-60"
                                //             className="border h-60"
                                //         />
                                //         <div className="text-neutral-800 text-ellipsis text-base text-center truncate mt-1 w-44">
                                //             {suggestion?.publicationName}
                                //         </div>
                                //     </Link>
                                // ))
                                list.map(({ id, attributes: { volTitle, thumbNailURL, type } }) => {
                                    const link = `/laws-of-uganda/${id}?title=${volTitle}`
                                    const arr = thumbNailURL.split('/upload/')
                                    const newUrl = arr.length > 1 ? arr[0] + "/upload/q_auto,f_auto/" + arr[1] : null
                                    return (
                                        <Link to={link} key={id} className="items-center flex grow basis-[0%] flex-col">
                                            <img
                                                loading="lazy"
                                                srcSet={newUrl || thumbNailURL || Publish2}
                                                // className="aspect-[0.65] object-contain object-center w-[180px] overflow-hidden border"
                                                // className="border w-[175px] h-60"
                                                className="border h-60"
                                            />
                                            <div className="text-neutral-800 text-ellipsis text-base text-center mt-1 w-44">
                                                {volTitle}
                                            </div>
                                        </Link>
                                    )
                                })
                            }

                        </div>
                        <button
                            className="text-neutral-400 text-xl border-[color:var(--Grey,#999)] w-[382px] max-w-full mt-16 mb-10 px-5 py-2.5 rounded-3xl border-[1.308px] self-center border-solid max-md:mt-10"
                            onClick={segueTo}
                        >
                            See More
                        </button>
                    </div>
                </div>
                <div className="font-serif text-neutral-800 text-center text-4xl leading-[54px] self-center mt-20 max-md:max-w-full max-md:text-4xl max-md:leading-[50px] max-md:mt-10">
                    Our Subscription Plans
                </div>
                <div className="text-center max-w-full w-full  mb-12">
                    {/* <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0"> */}
                    {/* <div className="flex flex-col items-stretch w-[45%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0861c96-e29d-4b6d-a64a-d6775d4195df?apiKey=345ac80390ed4496b041ffff961f4164&"
                                className="aspect-[0.74] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                            />
                        </div> */}
                    {/* <div className="flex flex-col items-center w-[55%] ml-5 max-md:w-full max-md:ml-0 my-auto max-md:mt-10"> */}
                    {/* <div className="flex flex-col items-stretch my-auto max-md:mt-10"> */}
                    <div className="font-serif text-neutral-800 text-2xl self-center leading-[54px] text-center">
                        Unlock endless reformation with Our Subscription
                    </div>
                    <button
                        onClick={openPlansModal}
                        className="self-center bg-bgBlue mt-4 py-2 w-[160px] rounded-md max-md:mt-10 max-md:px-5">
                        <div className="flex justify-center items-center gap-2">
                            <span className="text-white text-center text-sm">
                                Subscribe
                            </span>
                            <FaArrowRightLong color="white" />
                        </div>
                    </button>
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}
                </div>
                <Footer />
            </div>
            {openPlans && <Pricing closeModal={() => setOpenPlans(false)} />}
        </div>
    );
}

export default Home;
