import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Document, Page, Outline, pdfjs } from 'react-pdf';
import { IoSearch } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import fileDownload from 'js-file-download';
import axios from 'axios';
import { Button } from 'antd';
import Highlighter from "react-highlight-words";
import Mark from "mark.js";
import _ from "lodash";
import { OutlineElement } from 'pspdfkit';
import { BiExpand, BiCollapse, BiZoomIn, BiZoomOut } from "react-icons/bi";
import { ACCESS_TYPES, docPass } from '../envs';
import MyDocument from "./DocumentViewer2";

export const usePdfTextSearch = (file, searchString) => {
    const [pages, setPages] = useState([]);
    const [resultsList, setResultsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [docReady, setDocReady] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        pdfjs.getDocument({
            url: file,
            password: docPass
        }).promise.then((docData) => {
            const pageCount = docData._pdfInfo.numPages;

            const pagePromises = Array.from(
                { length: pageCount },
                (_, pageNumber) => {
                    return docData.getPage(pageNumber + 1).then((pageData) => {
                        return pageData.getTextContent().then((textContent) => {
                            return textContent.items.map(({ str }) => str).join(" ");
                        });
                    });
                }
            );

            return Promise.all(pagePromises).then((pages) => {
                setPages(pages);
                setIsLoading(false);
            });
        });
    }, [file]);

    useEffect(() => {
        if (!searchString || !searchString.length) {
            setResultsList([]);
            return;
        }

        const regex = new RegExp(`${searchString}*`, "i");
        const updatedResults = [];

        pages.forEach((text, index) => {
            if (regex.test(text)) {
                updatedResults.push(index + 1);
            }
        });

        setResultsList(updatedResults);
    }, [pages, searchString]);

    // const memoizedResultsList = useMemo(() => resultsList, [resultsList]);

    return [resultsList, pages, isLoading];
};

const Controls = ({
    handleButtonClick,
    handleDownload,
    docReady,
    file,
    access,
    enterFullscreen,
    exitFullscreen,
    scaleUp,
    scaleDown
}) => {
    return (
        <>
            <BiZoomIn size={24} style={{ marginRight: 6 }} color='rgb(37 99 235)' className='cursor-pointer' onClick={() => scaleUp()} />
            <BiZoomOut size={24} style={{ marginRight: 12 }} color='rgb(37 99 235)' className='cursor-pointer' onClick={() => scaleDown()} />
            {/* <button
                className='flex items-center gap-1 px-2 py-[4px] border border-blue-600 rounded-md text-blue-600 text-sm'
                onClick={() => handleButtonClick()}
                disabled={!docReady}
            >
                <IoSearch size={16} />
                Search
            </button> */}
            {
                ACCESS_TYPES.FREE === access && <button
                    onClick={() => handleDownload(file)}
                    className='flex items-center gap-1 px-2 py-[5px] bg-blue-600 text-white rounded-md text-sm'>
                    <GoDownload size={16} />
                    Download
                </button>
            }
            <Button
                title='Toggle Fullscreen'
                onClick={
                    document.fullscreenElement ? exitFullscreen : enterFullscreen
                }>
                {
                    document.fullscreenElement ? <BiCollapse size={20} /> : <BiExpand size={20} />
                }
            </Button>
        </>
    )
}

function DocumentPreview({ file, filename, access }) {

    const [isDivVisible, setIsDivVisible] = useState(false);
    const [docReady, setDocReady] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [scaleFctor, setScaleFactor] = useState(1.4)
    const [searchString, setSearchString] = useState("");
    const [searchResultPages, setSearchResultPages] = useState([]);
    const [isSearchFocussed, setIsSearchFocussed] = useState(false);
    const debouncedSearch = useRef(_.debounce((nextValue) => setSearchString(nextValue), 500)).current;

    const [resultsList, pages, isLoading] = usePdfTextSearch(file, searchString);

    const ref = useRef(null);

    let os;
    if (navigator.platform.indexOf('Mac') !== -1) {
        os = ['⌘', 'F'];
    } else {
        os = ['Ctrl', 'F'];
    }

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (document.fullscreenElement) {
                console.log("Entering fullscreen");
                setFullScreen(true);
            } else {
                console.log("Exiting fullscreen");
                setFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        setSearchResultPages(
            resultsList.map((index) => {
                return pages[index - 1]
            })
        )
    }, [resultsList, pages]);

    const memoizedSearchResults = useMemo(() => {
        return searchResultPages.map((page, index) => {
            let wordPosition = page.toLowerCase().indexOf(searchString.toLowerCase());
            let startIndex = Math.max(0, wordPosition - 10);
            let endIndex = Math.min(page.length, wordPosition + 80);
            let result = page.slice(startIndex, endIndex);
            var regex = new RegExp(searchString, "gi");
            var matches = page.match(regex);
            var totalMatches = matches ? matches.length : 0;

            return { text: result, pageNumber: resultsList[index], matches: totalMatches }
        }).filter((result) => result.matches > 0);
    }, [searchResultPages, searchString, resultsList]);

    const scaleUp = () => {
        if (scaleFctor > 2.5) {
            return;
        }
        setScaleFactor(scaleFctor + 0.1);
    }

    const scaleDown = () => {
        if (scaleFctor < 0.5) {
            return;
        }
        setScaleFactor(scaleFctor - 0.1);
    }

    const enterFullscreen = () => {
        if (ref.current) {
            ref.current.requestFullscreen();
        }
    };

    const exitFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    };

    const handleDownload = (url) => {
        axios.get(url, {
            responseType: 'blob',
        }).then((res) => {
            fileDownload(res.data, `${filename}.pdf`);
        });
    };

    const handleButtonClick = () => {
        setIsDivVisible(true);

        setTimeout(() => {
            setIsDivVisible(false);
        }, 5000);
    };


    return (
        <div className="w-[100%] mx-auto relative bg-white" ref={ref}>
            <div className='flex items-center justify-between h-10 border border-slate-200 pr-2'>
                <div className='py-2 pl-6 text-base font-bold text-slate-400'>
                    {searchString.length > 0 ? 'Search Results' : 'OUTLINE'}
                </div>
                <div className='flex items-center gap-2'>
                    {/* <div className='flex'> */}
                    <input
                        type='text'
                        placeholder="Search..."
                        onBlur={() => setIsSearchFocussed(false)}
                        onFocus={() => setIsSearchFocussed(true)}
                        onChange={(e) => debouncedSearch(e.target.value)}
                        className={`border-2 ${isSearchFocussed ? 'border-blue-500' : 'border-gray-300'} w-[60%] h-8 pl-2 mr-2 rounded-md outline-none`}
                    />

                    {/* </div> */}
                    <Controls
                        handleButtonClick={handleButtonClick}
                        handleDownload={handleDownload}
                        docReady={docReady}
                        file={file}
                        access={access}
                        enterFullscreen={enterFullscreen}
                        exitFullscreen={exitFullscreen}
                        scaleUp={scaleUp}
                        scaleDown={scaleDown}
                    />
                </div>
            </div>
            {/* {searchString.length > 0 && <div className='absolute top-10 right-5 w-96 max-h-[250px] bg-white border border-red z-[100] overflow-auto shadow-md'>
                {
                    memoizedSearchResults.map((result, index) => (
                        <div
                            key={index}
                            className='px-2 py-1 border-b border-gray-100 text-sm cursor-pointer'
                            onClick={() => {
                                // pageRefs.current[result.pageNumber]?.scrollIntoView()
                            }}
                        >
                            <Highlighter
                                highlightClassName="highlightText"
                                searchWords={[searchString]}
                                autoEscape={true}
                                textToHighlight={result.text}
                            />
                        </div>
                    ))
                }
            </div>} */}
            <MyDocument
                file={file}
                setDocReady={setDocReady}
                scale={scaleFctor}
                searchResults={memoizedSearchResults}
                searchString={searchString}
            />
            {isDivVisible && <div className='absolute z-[200] w-52 h-48 bottom-12 rounded-xl flex justify-center items-center fadeInOut' style={{
                backgroundColor: 'rgba(0,0,0,0.7)',
                left: '50%',
                transform: 'translate(-50%, 0)'
            }}>
                <div>
                    <p className='text-white text-center text-lg'>Press the keys</p>
                    <div className='flex items-center justify-center text-white text-sm my-2'>
                        <span className='text-6xl'>{os[0]}</span>
                        <span className='mx-2 text-lg'>+</span>
                        <span className='text-6xl'>{os[1]}</span>
                    </div>
                    <p className='text-white text-center text-lg'>to search Doc </p>
                </div>
            </div>}

        </div>
    );
}

export default DocumentPreview;
