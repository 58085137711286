import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaSearch, FaUserAlt, FaChevronDown } from 'react-icons/fa'
import { BiBell, BiCart } from "react-icons/bi";
import { IoChevronDownOutline } from "react-icons/io5";
import { BsChevronDown } from "react-icons/bs";
import { Dropdown, Space } from 'antd';
import logo2 from '../ulrc_logo.png';
import court from '../image_ug.png';
import logo from '../ulrc_logo1.png';
import { useAuth } from '../Auth';


const userMenu = (gotoDashboard, signOut, subscription, serviceRole) => {
    const str = subscription?.numberOfUsers || "0"
    const num = str.match(/\d+/)[0];
    const maxUsers = parseInt(num, 10);

    if (maxUsers > 1 || (!!serviceRole && serviceRole === "superadmin")) {
        return [
            {
                key: '1',
                label: 'Dashboard',
                onClick: gotoDashboard
            },
            {
                key: '2',
                label: 'Sign Out',
                onClick: signOut
            }
        ]
    } else {
        return [
            {
                key: '2',
                label: 'Sign Out',
                onClick: signOut
            }
        ]
    }
}

const items1 = (segue) => [
    {
        key: 'Constitution of the Republic of Uganda',
        label: 'Constitution of the Republic of Uganda',
        link: '/constitution',
        className: 'textwhite',
        children: [
            {
                key: 'Constitution of the Republic of Uganda - English',
                label: 'Constitution of the Republic of Uganda - English',
                link: '/constitution?query=english',
                onClick: () => segue('/constitution?query=english'),
                className: 'textwhite-child'
            },
            {
                key: 'Translated Versions of the constitution',
                label: 'Translated Versions of the constitution',
                link: '/constitution?query=translated versions',
                className: 'textwhite-child',
                onClick: () => segue('/constitution?query=translated versions')
            }
        ]
    },
    {
        key: 'Laws of Uganda',
        label: 'Laws of Uganda',
        className: 'textwhite',
        children: [
            {
                key: 'Principal Laws',
                label: 'Principal Laws',
                link: '/laws-of-uganda?query=principal',
                className: 'textwhite-child',
                onClick: () => segue('/laws-of-uganda?query=principal')
            },
            {
                key: 'Subsidiary Laws',
                label: 'Subsidiary Laws',
                link: '/laws-of-uganda?query=subsidiary',
                className: 'textwhite-child',
                onClick: () => segue('/laws-of-uganda?query=subsidiary')
            },
            {
                key: 'Consolidated Laws',
                label: 'Consolidated Laws',
                link: '/laws-of-uganda?query=Consolidated',
                className: 'textwhite-child',
                onClick: () => segue('/laws-of-uganda?query=Consolidated')
            }
        ]
    },
    {
        key: 'Simplified Laws of Uganda',
        label: 'Simplified Laws of Uganda',
        link: '/simplified-laws-of-uganda',
        className: 'textwhite',
        onClick: () => segue('/simplified-laws-of-uganda'),
    },
    {
        key: 'Reports',
        label: 'Reports',
        link: '/report',
        className: 'textwhite',
        // onClick: () => segue('/report'),
        children: [
            {
                key: 'Annual Reports',
                label: 'Annual Reports',
                link: '/report',
                className: 'textwhite-child',
                onClick: () => segue('/report?query=Annual')
            },
            {
                key: 'Study Reports',
                label: 'Study Reports',
                link: '/report',
                className: 'textwhite-child',
                onClick: () => segue('/report?query=Study')
            }
        ]
    },
    {
        key: 'Other Publications',
        label: 'Other Publications',
        link: '/other-publication',
        className: 'textwhite',
        // onClick: () => segue('/other-publication'),
        children: [
            {
                key: 'Index Of The Laws Of Uganda',
                label: 'Index Of The Laws Of Uganda',
                link: '/other-publication',
                className: 'textwhite-child',
                onClick: () => segue('/other-publication?query=Index of laws')
            },
            {
                key: 'Uganda Living Law Journal',
                label: 'Uganda Living Law Journal',
                link: '/other-publication',
                className: 'textwhite-child',
                onClick: () => segue('/other-publication?query=Uganda Living Journal')
            },
            {
                key: 'Translated Local Council Act',
                label: 'Translated Laws',
                link: '/other-publication',
                className: 'textwhite-child',
                onClick: () => segue('/other-publication?query=Translated Laws')
            },
            {
                key: 'Cumulative Suppliment',
                label: 'Cummulative Supplement',
                link: '/other-publication?query=Cumulative Suppliment',
                className: 'textwhite-child',
                onClick: () => segue('/other-publication?query=Cumulative Suppliment')
            }
        ]
    }
]


const menu = [
    {
        label: 'Constitution of the Republic of Uganda',
        submenu: [
            {
                label: 'Constitution of the Republic of Uganda - English',
                link: '/constitution?query=english'
            },
            {
                label: 'Translated Versions of the constitution',
                link: '/constitution?query=translated versions'
            }
        ]
    },
    {
        label: 'Laws of Uganda',
        submenu: [
            {
                label: 'Principal Laws',
                link: '/laws-of-uganda?query=principal laws'
            },
            {
                label: 'Subsidiary Laws',
                link: '/laws-of-uganda?query=subsidiary laws'
            },
            {
                label: 'Consolidated Laws',
                link: '/laws-of-uganda?query=consolidated laws'
            }
        ]
    },
    {
        label: 'Simplified Laws of Uganda',
        link: '/simplified-laws-of-uganda',
        submenu: []
    },
    {
        label: 'Reports',
        link: '/report',
        submenu: []
    },
    {
        label: 'Other Publications',
        link: 'other-publication',
        submenu: []
    }
]

export function UnAuthHeader() {
    return (
        <div className='py-5 flex justify-between border-b-2 bg-bgGrey'>
            <div className='flex space-x-7 items-center'>
                <img src={logo2} alt="logo" className='w-40' />
                <img src={court} alt="logo" className='w-16' />
            </div>
            <div className='flex space-x-7 items-center'>
                <Link to={'choose-user-type'}>Login</Link>
                <Link to={'choose-user'} className='bg-brightRed text-white w-24 h-10 rounded-3xl flex items-center justify-center'>Sign Up</Link>
            </div>
        </div>
    )
}

export function UnAuthHeader2() {
    return (
        <div className='py-5 flex justify-between border-b-2'>
            <div className='flex space-x-7 items-center'>
                <img src={logo} alt="logo" className='w-40' />
                <img src={court} alt="logo" className='w-16' />
            </div>
            <div className='flex space-x-7 items-center'>

                <Link to={'/choose-user-type'} className='text-white'>Login</Link>
                <Link to={'/choose-user'} className='bg-brightRed text-white w-24 h-10 rounded-3xl flex items-center justify-center'>Sign Up</Link>
            </div>
        </div>
    )
}

const SubMenuItem = ({ label, link }) => {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => navigate(link)}
            className="text-sm text-neutral-800 py-2 w-[320px] px-2 hover:bg-bgDarkBlue hover:text-white select-none">
            {label}
        </div>
    );
}

const MenuItem = ({ label, submenu, link }) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const subMenuref = React.useRef(null);
    const divRef = React.useRef(null);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (subMenuref.current && !subMenuref.current.contains(event.target) && !divRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [subMenuref]);

    return (
        <div
            className={`relative select-none text-sm text-neutral-800 py-2 pl-4 pr-2 w-[300px] hover:bg-brightRed hover:text-white cursor-pointer ${open && ' text-white bg-brightRed'}`}
            ref={divRef}
        >
            <div
                className="flex items-center justify-between"
                onClick={() => {
                    if (submenu.length === 0) {
                        navigate(link)
                    } else {
                        setOpen(!open)
                    }
                }}
            >
                {label}
                {submenu.length > 0 && <BsChevronDown />}
            </div>
            {open && (
                <div className="absolute top-8 left-[20%] bg-white shadow-md z-20 py-2 rounded-md" ref={subMenuref}>
                    {submenu.map((subitem, index) => (
                        <SubMenuItem key={index} {...subitem} />
                    ))}
                </div>
            )}
        </div>
    );
};


export function UnAuthHeader3({ openPlansModal }) {
    const [openPublications, setOpenPublications] = React.useState(false)
    const { userData, removeUser, subscriptionData: subscription } = useAuth()
    const publicationRef = React.useRef(null)
    const navigate = useNavigate();

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (publicationRef.current && !publicationRef.current.contains(event.target)) {
                setOpenPublications(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [publicationRef]);

    const segue = (link) => {
        navigate(link)
    }

    const gotoDashboard = () => {
        navigate('/dashboard/info')
    }

    const signOut = () => {
        removeUser()
        navigate("/", { replace: true })
    }

    return (
        <div className='py-5 flex justify-between border-b-2'>
            <div className='flex space-x-7 items-center'>
                <Link to='/'><img src={logo2} alt="logo" className='w-40' /></Link>
                <img src={court} alt="logo" className='w-16' />
            </div>
            <div className='flex space-x-7 items-center'>
                <Dropdown menu={{ items: items1(segue) }} arrow={true}>
                    <Space>
                        <nav
                            className="text-neutral-800 text-base leading-6 my-auto cursor-pointer"
                        >
                            Publications
                        </nav>
                        <IoChevronDownOutline />
                    </Space>
                </Dropdown>
                <nav
                    className="text-neutral-800 text-base leading-6 my-auto cursor-pointer"
                    onClick={openPlansModal}
                >
                    Subscription Plans
                </nav>
                {/* Add Contact Us page */}
                <Link to="/contact-us" className="text-neutral-800 text-base leading-6 self-center my-auto">
                    Contact Us
                </Link>
                {/* <Link to="/" className="text-neutral-800 text-base leading-6 self-center my-auto">
                    FAQs
                </Link> */}
                {!userData &&
                    <>
                        <Link to={'/signin'}>Login</Link>
                        <Link to={'/signup'} className='bg-brightRed text-white w-24 h-10 rounded-3xl flex items-center justify-center'>Sign Up</Link>
                    </>}
                {userData &&
                    <div className="relative">
                        <Dropdown menu={{ items: userMenu(gotoDashboard, signOut, subscription, userData?.user?.serviceRole) }} arrow={true}>
                            <div className="bg-neutral-600 w-7 h-7 rounded-full flex items-center justify-center ml-2 cursor-pointer" ref={publicationRef} onClick={() => setOpenPublications(!openPublications)} >
                                <FaUserAlt className="text-neutral-300" />
                            </div>
                        </Dropdown>
                    </div>
                }
            </div>
        </div>
    )
}


export function AuthHeader2() {
    return (
        <div className='py-5 flex border-b-2'>
            <div className='flex gap-7 items-center'>
                <Link to='/'><img src={logo2} alt="logo" className='w-40' /></Link>
                <img src={court} alt="logo" className='w-16' />
            </div>
            {/* <div className='flex space-x-7 items-center'> */}
            {/* <Link to="about" className="text-neutral-800 text-base leading-6 self-center my-auto">
                    About Us{" "}
                </Link>
                <Link to="subscription" className="text-neutral-800 text-base leading-6 my-auto">
                    Subscription Plans
                </Link>
                <Link to="contact-us" className="text-neutral-800 text-base leading-6 self-center my-auto">
                    Contact Us
                </Link>
                <Link to="faqs" className="text-neutral-800 text-base leading-6 self-center my-auto">
                    FAQs
                </Link>
                <Link to={'signin'}>Login</Link>
                <Link to={'choose-user'} className='bg-brightRed text-white w-24 h-10 rounded-3xl flex items-center justify-center'>Sign Up</Link> */}
            {/* </div> */}
        </div>
    )
}

export const PublicationsNav = ({ search, setSearch, searchTitle = 'Search' }) => {
    const { userData, removeUser, subscriptionData: subscription } = useAuth()
    const [profileDropdown, setProfileDropdown] = React.useState(false)
    const navigate = useNavigate()

    const gotoDashboard = () => {
        navigate('/dashboard/info')
    }

    const signOut = () => {
        removeUser()
        navigate("/", { replace: true })
    }

    return (
        <div className='py-5 flex justify-between items-center border-b-2 '>
            <div className='flex space-x-7 items-center'>
                <Link to='/'><img src={logo2} alt="logo" className='w-40' /></Link>
                <img src={court} alt="logo" className='w-16' />
            </div>
            <div className='bg-white px-3 py-2 flex justify-between h-12 rounded-xl w-1/2'>
                <input className='outline-0 pl-1 w-11/12' placeholder={searchTitle} value={search} onChange={(e) => setSearch(e.target.value)} />
                <button className='bg-brightRed w-8 h-8 flex items-center justify-center rounded-md'>
                    <FaSearch className='text-white' />
                </button>
            </div>
            {userData ? <div className='flex items-center space-x-5'>
                <div className='relative'>
                    <BiBell className='text-2xl' />
                    <div className="absolute top-0 right-0 w-3 h-3 rounded-full bg-brightRed" />
                </div>
                <div className="relative">
                    <Dropdown menu={{ items: userMenu(gotoDashboard, signOut, subscription) }} arrow={true}>
                        <div className="bg-neutral-600 w-7 h-7 rounded-full flex items-center justify-center ml-2 cursor-pointer" onClick={() => setProfileDropdown(!profileDropdown)} >
                            <FaUserAlt className="text-neutral-300" />
                        </div>
                    </Dropdown>
                    {/* {
                        profileDropdown && <div className="absolute top-8 right-0 bg-white shadow-md py-2 rounded-md">
                            <div
                                className="text-neutral-800 text-base my-auto px-5 cursor-pointer hover:bg-slate-100"
                                onClick={gotoDashboard}
                            >
                                Dashboard
                            </div>
                            <div
                                className="text-neutral-800 text-base my-auto px-5 cursor-pointer hover:bg-slate-100"
                                onClick={signOut}
                            >
                                Sign Out
                            </div>
                        </div>
                    } */}
                </div>
            </div> : <div className='flex items-center space-x-8'>
                <Link to={'/signin'}>Login</Link>
                <Link to={'/signup'} className='bg-brightRed text-white w-24 h-10 rounded-3xl flex items-center justify-center'>Sign Up</Link>
            </div>}
        </div>
    )
}