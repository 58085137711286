import React, { useState } from 'react'
import { BiCart } from "react-icons/bi";
import { AiOutlineHeart, AiOutlineWallet } from "react-icons/ai"
import { toast } from 'react-toastify';
// import { PublicationsNav } from '.'
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import Footer from '../Footer';
import Pricing from '../Pricing';
import { PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import { _index } from '../searchClient';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PdfPreview from '../Pdf';
import axios from 'axios';
import { ACCESS_TYPES, API_URL } from '../../envs';
import { IoArrowBack } from 'react-icons/io5';
import { useAuth } from '../../Auth';
import { Spin } from 'antd';

const PreviewAct = () => {

    const [pricingModal, setPricingModal] = useState(false)
    const [publication, setPublication] = useState(null)
    const [suggestions, setSuggestions] = React.useState([])
    const [preview, setPreview] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const { userData, subscriptionData } = useAuth()
    const navigate = useNavigate()
    const { id, lawsId } = useParams();

    // React.useEffect(() => {
    //     _index
    //         .search('')
    //         .then((res) => {
    //             const info = res.hits?.find((_publication) => _publication.id === Number(id));
    //             console.log("res.hits:", res.hits)
    //             setPublication(info);
    //         })
    //         .catch((err) => console.log(err));
    // }, [])

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const response = await axios.get(`${API_URL}/laws-of-ugandas/${id}`);
                const data = response.data.data;
                setPublication({ ...data.id, ...data.attributes });
            } catch (err) {

            } finally {
                setLoading(false)
            }
        })()
    }, [id])

    // React.useEffect(() => {
    //     // const query = treaty?.treatySubject || treaty?.docType || treaty?.region || treaty?.partiesInvoved || treaty?.placeOfAdoption || treaty?.placeOfAdoption;
    //     // if (query) {
    //     //     console.log("Query:", query)
    //     _index
    //         .search('', {
    //             limit: 6,
    //             // filter: `treatySubject = "${treaty?.treatySubject}" OR docType = "${treaty?.docType}" OR region = "${treaty?.region}" OR partiesInvoved = "${treaty?.partiesInvoved}" OR placeOfAdoption = "${treaty?.placeOfAdoption}" OR placeOfAdoption = "${treaty?.placeOfAdoption}"`
    //         })
    //         .then(({ hits }) => {
    //             const newHits = hits.filter((hit) => (hit.id !== publication?.id) && (!!hit?.publicationName));
    //             setSuggestions(newHits);
    //         })
    //         .catch((err) => {
    //             console.error(err)
    //         })
    //     // }
    // }, [publication])

    const continueReading = () => {

        if (publication?.publlicationAccess === ACCESS_TYPES.FREE || (subscriptionData && userData)) {

            // toast.error("This Act is temporarily unavailable. Please use the mobile app to access the Acts. Thank you for your patience!", { autoClose: 8000 } )
            // return;

            setPreview(true)
        } else {
            setPricingModal(true)
            localStorage.setItem("publicationLink", `/all-publications/${id}`)
        }
    }

    function formatDate(dateString) {
        const dateObject = new Date(dateString);
        return dateObject.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
    }

    const openPlansModal = () => {
        setPricingModal(true)
    }

    return (
        <div className='bg-bgGrey relative min-h-full flex flex-col justify-between'>
            <div className='pt-7 pb-20 container mx-auto'>
                {/* <PublicationsNav /> */}
                <UnAuthHeader3 openPlansModal={openPlansModal} />
                <Spin spinning={loading}>
                    <div className='flex justify-center mx-auto mt-9'>
                        {/* <div>
                        <img src={publication?.pubThumbnailURL || Publish1} className='w-56' alt={'Publication'} />
                    </div> */}
                        <div className='w-[60%]'>
                            {/* <div className='w-3/4'> */}
                            <div className='mb-2 flex justify-between items-center'>
                                {/* <div className='text-4xl'>{publication?.actName}</div> */}
                                <div className='text-3xl flex'>
                                    <IoArrowBack
                                        className='inline-block mr-2 cursor-pointer text-2xl mt-2'
                                        onClick={() => { navigate(-1) }}
                                    />
                                    <div className='w-[99%]'>{publication?.actName}</div>
                                </div>
                            </div>
                            <div className="items-stretch border border-[color:var(--lighter-grey-strokes,#ECEDED)] flex flex-col rounded-xl border-solid mt-5">
                                <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        {/* <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                                            Access Type
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.publlicationAccess || 'N/A'}
                                        </div> */}
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full font-bold">
                                            Act Title
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.actTitle || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-center items-stretch bg-slate-100 flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full font-bold">
                                            Commencement
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {formatDate(publication?.Commencement) || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full font-bold">
                                            Law Type
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.lawType || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-center items-stretch bg-slate-100 flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full font-bold">
                                            Title Number
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.actTitleNumber || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-center items-stretch bg-white flex w-full flex-col px-4 py-4 max-md:max-w-full">
                                    <div className="items-stretch flex justify-between gap-4 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                        <div className="text-zinc-900 text-base leading-6 grow shrink basis-auto max-md:max-w-full font-bold">
                                            CAP
                                        </div>
                                        <div className="text-zinc-700 text-right text-base leading-6 whitespace-nowrap">
                                            {publication?.cap || 'N/A'}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='mt-4 mb-8'>
                                {publication?.actDescription || 'N/A'}
                            </div>
                            <button
                                className="text-white bg-bgBlue text-base w-[60%] px-5 py-4 rounded-2xl max-w-full translate-x-[-50%] absolute left-1/2"
                                onClick={continueReading}
                            >
                                Access
                            </button>
                            {/* <div className="flex w-[707px] max-w-full items-stretch justify-between gap-5 mr-3 mt-4 self-end max-md:flex-wrap max-md:mr-2.5">
                                <div className="text-white text-center text-base leading-6 whitespace-nowrap justify-center items-center bg-bgBlue grow px-5 py-4 rounded-2xl">
                                    Buy a copy
                                </div>
                                <div className="text-violet-950 text-center text-base leading-6 whitespace-nowrap justify-center items-center border border-[color:var(--Secondary-Colour,#272969)] grow px-5 py-4 rounded-2xl border-solid">
                                    Read online
                                </div>
                            </div> */}
                            {/* <h1>PREVIEW DOC</h1>
                            <PdfBookmarks /> */}
                            {/* </div> */}
                        </div>
                    </div>
                </Spin>
            </div>
            {/* <div className="bg-white self-stretch flex w-full flex-col items-center mt-14 px-5 py-12 max-md:max-w-full max-md:mt-10">
                <div className="flex w-full max-w-[1128px] flex-col items-center mt-16 mb-8 max-md:max-w-full max-md:mt-10">
                    <div className="overflow-hidden text-neutral-800 text-ellipsis text-5xl leading-[54px] w-[508px] max-w-full ml-12 max-md:text-4xl max-md:leading-[50px]">
                        Similar Publications
                    </div>
                    <div className="self-stretch flex items-stretch justify-between gap-5 mt-14 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mt-10">
           
                        {
                            suggestions.map((suggestion) => (
                                <a href={`/all-publications/${suggestion?.id}`} className="items-center flex grow basis-[0%] flex-col">
                                    <img
                                        loading="lazy"
                                        src={suggestion?.publicationImage || Publish2}
                                        className="aspect-[0.65] object-contain w-[180px] overflow-hidden"
                                    />
                                    <div className="text-neutral-800 text-ellipsis text-center text-base mt-1 w-44">
                                        {suggestion?.publicationName}
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                </div>
            </div> */}
            <Footer />
            {pricingModal && <Pricing closeModal={() => setPricingModal(false)} />}
            {publication?.actURL && <PdfPreview closePreview={() => setPreview(false)} preview={preview} docUrl={publication?.actURL} access={ACCESS_TYPES.SUBSCRIBED} filename={publication?.actName} />}
        </div>
    )
}

export default PreviewAct