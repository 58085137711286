import React from 'react'

function DashboardStats() {
    
    return (
        <div className="items-stretch flex gap-4 pr-1.5 max-md:flex-wrap max-md:justify-center">
            <div className="items-stretch bg-slate-100 flex justify-between gap-3.5 px-5 py-6 rounded-xl">
                <div className="items-center flex aspect-[1.0555555555555556] flex-col justify-center">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8fa01f82-de94-4b15-8918-a9849d2503a5?apiKey=345ac80390ed4496b041ffff961f4164&"
                        className="aspect-[1.06] object-contain object-center w-[57px] overflow-hidden rounded-xl"
                    />
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col self-start">
                    <div className="text-black text-base leading-6 whitespace-nowrap">
                        86.7K
                    </div>
                    <div className="text-black text-xs leading-5 whitespace-nowrap">
                        Registered Account
                    </div>
                </div>
            </div>
            <div className="items-stretch bg-slate-100 flex justify-between gap-3.5 pl-5 pr-16 py-6 rounded-xl max-md:pr-5">
                <div className="items-center flex aspect-[1.0555555555555556] flex-col justify-center">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5e0f3b21-01a8-420a-9299-e37b4a98b9c9?apiKey=345ac80390ed4496b041ffff961f4164&"
                        className="aspect-[1.06] object-contain object-center w-[57px] overflow-hidden rounded-xl"
                    />
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col self-start">
                    <div className="text-black text-base leading-6 whitespace-nowrap">
                        10K
                    </div>
                    <div className="text-black text-xs leading-5 whitespace-nowrap">
                        Subscribers
                    </div>
                </div>
            </div>
            <div className="items-stretch bg-slate-100 flex justify-between gap-3.5 pl-5 pr-8 py-6 rounded-xl max-md:pr-5">
                <div className="items-center flex aspect-[1.0555555555555556] flex-col justify-center">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b0e211a-0bf5-45e5-8e2a-bd7897aff480?apiKey=345ac80390ed4496b041ffff961f4164&"
                        className="aspect-[1.06] object-contain object-center w-[57px] overflow-hidden rounded-xl"
                    />
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col self-start">
                    <div className="text-green-500 text-base leading-6 whitespace-nowrap">
                        17.8%+
                    </div>
                    <div className="text-black text-xs leading-5 whitespace-nowrap">
                        Publication Views
                    </div>
                </div>
            </div>
            <div className="items-stretch bg-slate-100 flex justify-between gap-3.5 pl-5 pr-20 py-6 rounded-xl max-md:pr-5">
                <div className="items-center flex aspect-[1.0555555555555556] flex-col justify-center">
                    <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b53396-c499-404d-a0a2-b6bf3793880f?apiKey=345ac80390ed4496b041ffff961f4164&"
                        className="aspect-[1.06] object-contain object-center w-[57px] overflow-hidden rounded-xl"
                    />
                </div>
                <div className="items-stretch flex grow basis-[0%] flex-col self-start">
                    <div className="text-black text-base leading-6 whitespace-nowrap">
                        83457
                    </div>
                    <div className="text-black text-xs leading-5 whitespace-nowrap">
                        Sessions
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardStats