import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAuth } from '../Auth';
import logo2 from '../ulrc_logo.png';
import { AuthHeader2 } from './UnAuthHeader';
import Footer from './Footer';
import axios from 'axios';
import { API_URL } from '../envs';

function PasswordReset() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const { userData } = useAuth()
    const navigate = useNavigate()
    useEffect(() => {
        /// Temp work around for route protection
        if (userData) navigate("/")
    }, [userData])
    const resetPassword = async (e) => {
        e.preventDefault()
        if (!password || !confirmPassword) {
            return;
        }
        if (password !== confirmPassword) {
            toast.error("Passwords do not match", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        if (!code) {
            toast.error("Invalid code", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return;
        }

        try {
            setLoading(true)
            axios.post(`${API_URL}/auth/reset-password`, {
                password,
                passwordConfirmation: confirmPassword,
                code
            })
            toast.success("Password reset successfully", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setTimeout(() => {
                navigate("/reset-password-success")
            }, 2000)
        } catch (error) {
            console.log("ERROR:", error)
        } finally {
            setLoading(false)
        }
    }
    console.log("CODE:", code)

    return (
        <div className='h-screen bg-bgGrey pt-2 flex flex-col justify-between'>
            <div className='container mx-auto'>
                <AuthHeader2 />
            </div>
            <div className='flex justify-center items-center flex-col mt-10 mb-28'>
                <div className='bg-white pt-20 pb-16 px-12 rounded-3xl flex flex-col items-center'>
                    <div className='text-5xl font-serif max-md:text-3xl'>Reset Your Password</div>
                    <div className='text-md w-96 text-slate-600 mt-8 text-center'>Please enter your new password and confirm it.</div>
                    <form className='mt-6 max-md:mb-2'>
                        <div className='mb-6 max-md:mb-3'>
                            <div className='max-w-96 max-md:w-[95%] flex items-center border border-brightRedlite rounded-lg  rounded-r-lg outline-none'>
                                <input
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    onChange={e => setPassword(e.target.value)}
                                    className='h-10 pl-2 w-full max-w-96 outline-none rounded-lg rounded-r-lg' placeholder='New Password'
                                />
                                <div
                                    className='flex items-center justify-center w-16 h-10 cursor-pointer bg-white rounded-lg  rounded-r-lg'
                                    onClick={() => setPasswordVisible(!isPasswordVisible)}
                                >
                                    {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                        </div>
                        <div className='mb-6 max-md:mb-4'>
                            <input
                                type='password'
                                onChange={e => setConfirmPassword(e.target.value)}
                                className='h-10 pl-2 w-96 max-md:w-[95%] rounded-lg outline-none border border-brightRedlite' placeholder='Confirm Password' />
                        </div>
                        <button
                            onClick={resetPassword}
                            disabled={loading}
                            className='h-10 w-96 max-md:w-[95%] rounded-lg bg-brightRed text-white'>
                            {!loading ? "Reset Password" : "Please wait..."}
                        </button>
                    </form>
                    <div className='mt-4 text-center'>
                        Go back to <Link to='/signin' className='text-brightRed'>login</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PasswordReset;
