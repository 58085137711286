import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center ">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
        <h1 className="text-2xl font-bold mb-4">Terms and Conditions for the use of the Laws of Uganda Portal </h1>
        <ol className="list-decimal list-inside">
          <li className="font-bold mb-2">Acceptance of Terms</li>
          <p>By accessing and using the Uganda Law Reform Commission Web PublishingPortal (the "Portal"), you agree to comply with and be bound by these Terms and Conditions.
          </p>

          <li className="font-bold mb-1 mt-4">Use of the Portal </li>
          <div className="mb-2">2.1. <b>Free Subscription and Access:</b>: Users may access certain features of the Portal with a free subscription. By using the free subscription, you agree to provide accurate and current information about yourself. The Uganda Law Reform Commission reserves the right to modify or restrict access to free features at any time.</div>
          <div className="mb-2">2.2. <b>Paid Subscription and Access:</b>: Access to all publications on the Portal requires a paid subscription. By subscribing, you agree to provide accurate and current information about yourself and to maintain the confidentiality of your login credentials. You are responsible for all activities that occur under your paid subscription account.</div>
          <div className="mb-2">2.3. <b>Lawful Use:</b>: You agree to use the Portal for lawful purposes only. You may not use the Portal to violate any applicable laws, regulations, or third-party rights.</div>

          <li className="font-bold mb-1 mt-4">Intellectual Property </li>
          <div className="mb-2">3.1. <b>Content Ownership</b>: The content available on the Portal, including but not limited to laws, legal publications, text, graphics, logos, images, and software, is the property of the Uganda Law Reform Commission or its licensors and is protected by copyright and other intellectual property laws.</div>
          <div className="mb-2">3.2. <b>License</b>: Users with a paid subscription are granted a limited, revocable, non-exclusive license to access and use all content on the Portal for personal or commercial purposes. Users with a free subscription have a limited license for the specific features available under the free subscription. You may not reproduce, modify, distribute, or otherwise exploit the content without the express written consent of the Uganda Law Reform Commission.</div>

          <li className="font-bold mb-1 mt-4">Privacy </li>
          <div className="mb-2">4.1. <b>Data Collection</b>: By using the Portal, you consent to the collection, use, and storage of your personal information as outlined in the Privacy Policy.</div>
          <div className="mb-2">4.2. <b>Security</b>: You are responsible for maintaining the confidentiality of your account information and for any activities that occur under your account. Notify us immediately of any unauthorized use or security breaches</div>

          <li className="font-bold mb-1 mt-4">Subscription Fees and Payments </li>
          <div className="mb-2">5.1. <b>Free Subscription</b>: Users with a free subscription will not be charged any fees.</div>
          <div className="mb-2">5.2. <b>Paid Subscription</b>: Users with a paid subscription agree to pay the subscription fees as outlined on the Portal. All payment information provided by you is accurate, and you authorize the Uganda Law Reform Commission to charge your chosen payment method for the subscription fees.</div>

          <li className="font-bold mb-2 mt-4">Modification and Termination</li>
          <p>The Uganda Law Reform Commission reserves the right to modify, suspend, or discontinue the Portal or any part thereof at any time. We may also impose limits on certain features or restrict access without notice or liability.</p>

          <li className="font-bold mb-2 mt-4">Disclaimer of Warranties</li>
          <p>The Portal is provided on an "as-is" and "as-available" basis. The Uganda Law Reform Commission makes no representations or warranties of any kind, express or implied, regarding the accuracy, completeness, or availability of the content.</p>

          <li className="font-bold mb-2 mt-4">Limitation of Liability</li>
          <p>To the fullest extent permitted by law, the Uganda Law Reform Commission shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>

          <li className="font-bold mb-2 mt-4">Governing Law</li>
          <p>These Terms and Conditions are governed by and construed in accordance with the Laws of Uganda. Any disputes arising out of or relating to these terms shall be resolved in the courts of Uganda</p>

          <li className="font-bold mb-2 mt-4">Contact Information
          </li>
          <p>If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:ulrc@ulrc.go.ug" className='text-blue-700'>ulrc@ulrc.go.ug</a>
          </p>

          <p className='mt-4'>
          By using the Uganda Law Reform Commission web Portal, you agree to abide by these Terms and Conditions.
          </p>

        </ol>
      </div>
    </div>
  )
}

export default TermsAndConditions;
