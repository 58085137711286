import React from 'react';
import { FaSearch, FaUserAlt, FaGlobeAfrica, FaFileArchive, FaFileAlt } from 'react-icons/fa'
import { BiBell, BiCart } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import logo2 from '../../ulrc_logo.png';
import { renderPublications } from '../Home';
import Publish1 from '../../assets/LivingLawJournal.jpg';
import Publish2 from '../../assets/LocalGovernment.jpg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PublicationsNav, UnAuthHeader3 } from '../UnAuthHeader';
import Footer from '../Footer';
import { _index, _laws, _volumes } from '../searchClient';
import { MdBalance, MdGTranslate } from 'react-icons/md';
import { FiChevronDown, FiChevronUp, FiFileText } from 'react-icons/fi';
import { GrLanguage } from "react-icons/gr";
import { IoArrowBack } from "react-icons/io5";
import axios from 'axios';
import { API_URL } from '../../envs';
import { set } from 'lodash';
import Pricing from '../Pricing';

const pubicationOptions = [
    'Copyright infringement',
    'Contract Law',
    'Revenue Law'
]

const lawsOfUg = [
    {
        label: 'Principal Laws',
        value: 'principal',
    },
    {
        label: 'Subsidiary Laws',
        value: 'subsidiary'
    },
    {
        label: 'Consolidated Laws',
        value: 'Consolidated'
    }
]


const _principal = [
    {
        label: 'Revised Edition, 2000',
        value: '_principal Revised Edition, 2000'
    },
    {
        label: 'Edition, XXXX',
        value: '_principal Edition, XXXX'
    }
]

const _subsidiary = [
    {
        label: 'Revised Edition, 2000',
        value: '_subsidiary Revised Edition, 2000'
    },
    {
        label: 'Edition, XXXX',
        value: '_subsidiary Edition, XXXX'
    }
]

const _consolidated = [
    {
        label: 'Compendium of Electoral Laws',
        value: '_consolidated Compendium of Electoral Laws'
    }
]


const SideMenu = ({
    queries, setQueries,
}) => {
    const [laws, setLaws] = React.useState(true)

    return (
        <div className="items-start self-stretch flex grow flex-col w-full mt-2 max-h-[620px] llg:max-h-full llg:h-auto overflow-auto">

            <div className="content-center flex-wrap self-stretch flex w-full flex-col px-3.5 py-2.5">
                <div
                    className="flex w-full items-start justify-between max-md:justify-center cursor-pointer"
                    onClick={() => setLaws(!laws)}
                >
                    <div className="flex items-center gap-3.5">
                        <MdBalance size={24} className="text-slate-500" />
                        <div className="text-neutral-800 text-sm font-medium leading-[142.857%] my-auto">Laws of Uganda</div>
                    </div>
                </div>

                {laws &&
                    <div className='mt-2 ml-5 flex flex-col gap-3'>
                        {
                            lawsOfUg.map((item, index) => {
                                return (
                                    <div className="flex items-center gap-1.5">
                                        <input type="checkbox" name={item.value} id={item.value}
                                            checked={queries.includes(item.value)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setQueries([...queries, item.value])
                                                } else {
                                                    setQueries(queries.filter(query => query !== item.value))
                                                }
                                            }}
                                        />
                                        <label htmlFor={item.value} className="text-slate-500 text-sm font-medium leading-[142.857%]">{item.label}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>

    )
}

const LawsOfUganda = ({ }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryText = queryParams.get('query');
    const navigate = useNavigate()

    const [search, setSearch] = React.useState('')
    const [openPlans, setOpenPlans] = React.useState(false)
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [list, setList] = React.useState([])
    const [fullList, setFullList] = React.useState([])
    const [queries, setQueries] = React.useState([])


    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const resp = await axios.get(`${API_URL}/volumes?populate=*&sort=id:asc`)
                const data = resp.data?.data || []
                if (data.length > 0) {
                    const items = []
                    data.forEach(item => {
                        items.push(item)
                    })
                    setFullList(items)
                    setList(items)
                }
            } catch (error) {

            } finally {
                setLoading(false)
            }
        })()
    }, [])

    React.useEffect(() => {
        // if (queries.length > 0) {
        //     const _list = fullList.filter(item => {
        //         return queries.includes(item.attributes.type)
        //     })
        //     setList(_list)
        // } else {
        //     setList(fullList)
        // }

        if (queries.includes('principal') || queries.length === 0) {
            setList(fullList);
        } else {
            setList([])
        }

    }, [queries, fullList])

    React.useEffect(() => {
        if (queryText) {
            setQueries([...queries, queryText])
        }
    }, [queryText])

    React.useEffect(() => {
        if (search.length === 0) {
            setList(fullList)
        } else {
            const _list = fullList.filter(item => {
                return item.attributes.volTitle.toLowerCase().includes(search.toLowerCase())
            })
            setList(_list)
        }
    }, [search])

    const openPlansModal = () => {
        setOpenPlans(true)
    }

    return (
        <div className='bg-bgGrey min-h-full flex flex-col justify-between'>
            <div className='pt-7 pb-20 md:container max-md:px-10 mx-auto'>
                {/* <PublicationsNav search={search} setSearch={setSearch} searchTitle="Search by Act or Cap..." /> */}
                <UnAuthHeader3 openPlansModal={openPlansModal} />

                <div className='flex items-start w-full'>
                    <div className='pt-8 pr-2 w-[245px]'>
                        <div className='font-bold mb-3 text-lg'>Filter</div>
                        <SideMenu {...{
                            queries, setQueries
                        }} />
                    </div>
                    <div className='border-l-4 pt-8 pl-8 w-full'>
                        <div className='mb-6 flex items-center justify-between w-full'>
                            <div>
                                <IoArrowBack
                                    onClick={() => navigate('/')}
                                    className='inline-block mr-2 text-xl cursor-pointer' />
                                <span className="font-bold text-lg">Laws of Uganda</span>
                            </div>
                            <div className='flex gap-2'>
                                <input
                                    className='h-9 rounded-lg border outline-1 border-gray-200 px-2 text-sm'
                                    placeholder='Search by Act or Cap...'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className='bg-bgBlue text-white h-9 px-4 rounded-lg'>Search</button>
                            </div>
                        </div>
                        <div className='grid grid-flow-row grid-cols-5 gap-4 max-llg:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1'>
                            {
                                list.map(({ id, attributes: { volTitle, thumbNailURL, type } }) => {
                                    const arr = thumbNailURL.split('/upload/')
                                    const newUrl = arr.length > 1 ? arr[0] + "/upload/q_auto,f_auto/" + arr[1] : null
                                    return (
                                        <Link to={`/laws-of-uganda/${id}?title=${volTitle}`} title={volTitle}>
                                            <img src={newUrl || thumbNailURL} alt={"Publication"} className={'h-60'} />
                                            <div className='flex justify-between items-center mt-2 pl-1'>
                                                <span className='text-sm w-36 text-center' title={volTitle}>{volTitle}</span>
                                            </div>
                                        </Link>
                                    )
                                }) || results.length !== 0 && <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                    No Laws Found
                                </div>
                            }
                        </div>
                        {
                            !loading && list.length === 0 &&
                            <div className="text-neutral-800 text-xl font-light leading-[150%] mt-2.5 max-md:max-w-full">
                                No Laws Found
                            </div>
                        }
                        {
                            loading &&
                            <div className="text-neutral-800 text-xl font-light mt-2.5 max-md:max-w-full text-center">
                                Loading Laws Of Uganda...
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {openPlans && <Pricing closeModal={() => setOpenPlans(false)} />}
        </div>
    )
}

export default LawsOfUganda
